import { format } from 'date-fns';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Column, DataTable, DataTableExport, DataTableRef } from 'src/components/DataTable';
import { ControlledInput, ControlledSwitch } from 'src/components/Form';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { TOKENS } from 'src/design';
import { useDataTable, useRole } from 'src/hooks';
import { growthApi } from 'src/services';
import { calcWithMarkup, formatAmount, formatPercentage } from 'src/utils';
import styled from 'styled-components';
import { ReportingFormValues } from './Reporting';
import { ReportingInfo } from 'src/components/ReportingInfo';

const { useGeoQuery } = growthApi;

type ReportingGeoProps = Pick<
  ReportingFormValues,
  'timeRange' | 'dateFrom' | 'dateTo' | 'agencyId' | 'advertiserId' | 'campaignId'
> & {
  markup?: number;
};

type ReportingGeoFormValues = {
  countrySearch?: string;
  regionSearch?: string;
  withMarkup?: boolean;
};

export const ReportingGeo = (props: ReportingGeoProps) => {
  const { timeRange, dateFrom, dateTo, agencyId, advertiserId, campaignId, markup } = props;

  const { canWithMarkup } = useRole();
  const { watch, control } = useForm<ReportingGeoFormValues>({
    defaultValues: {
      withMarkup: true,
    },
  });
  const values = watch();
  const finalMarkup = values.withMarkup ? markup : 0;
  const isNotSelectAgencyAdvertiser = !agencyId || !advertiserId;

  const countryDataTableRef = useRef<DataTableRef>();
  const regionDataTableRef = useRef<DataTableRef>();

  const commonFilter = {
    time_range: timeRange,
    date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
    date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
    agency_id: agencyId,
    advertiser_id: advertiserId,
    campaign_id: campaignId,
  };

  const {
    data: countryData,
    isFetching: isCountryFetching,
    error: countryError,
  } = useGeoQuery(
    {
      ...commonFilter,
      breakout: 'country',
    },
    { skip: isNotSelectAgencyAdvertiser },
  );

  const {
    data: regionData,
    isFetching: isRegionFetching,
    error: regionError,
  } = useGeoQuery(
    {
      ...commonFilter,
      breakout: 'region',
    },
    { skip: isNotSelectAgencyAdvertiser },
  );

  const { dataTableProps: countryDataTableProps } = useDataTable({
    idKey: 'country',
    data: countryData?.data,
    isLoading: isCountryFetching,
    error: countryError,
    search: values.countrySearch,
    searchKeys: ['country'],
    defaultSort: {
      key: 'impressions',
      direction: 'desc',
    },
    sortNumberKeys: ['impressions', 'clicks', 'ctr', 'cpm', 'cpv', 'total_conversions', 'cpa', 'total_spend'],
  });

  const { dataTableProps: regionDataTableProps } = useDataTable({
    idKey: 'region',
    data: regionData?.data,
    isLoading: isRegionFetching,
    error: regionError,
    search: values.regionSearch,
    searchKeys: ['country', 'region'],
    defaultSort: {
      key: 'impressions',
      direction: 'desc',
    },
    sortNumberKeys: ['impressions', 'clicks', 'ctr', 'cpm', 'cpv', 'total_conversions', 'cpa', 'total_spend'],
  });

  const regionColumns: Column[] = [
    {
      header: 'Country',
      accessor: 'country',
      sortable: true,
    },
    {
      header: 'Region',
      accessor: 'region',
      sortable: true,
    },
    {
      header: 'Impressions',
      accessor: 'impressions',
      sortable: true,
    },
    {
      header: 'Clicks',
      accessor: 'clicks',
      sortable: true,
    },
    {
      header: 'CTR',
      accessor: 'ctr',
      render: formatPercentage,
      sortable: true,
    },
    {
      header: 'CPM',
      accessor: 'cpm',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPC',
      accessor: 'cpc',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPV',
      accessor: 'cpv',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'Conversions',
      accessor: 'total_conversions',
      sortable: true,
    },
    {
      header: 'CPA',
      accessor: 'cpa',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'Spend',
      accessor: 'total_spend',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
  ];

  const countryColumns: Column[] = [
    {
      header: 'Country',
      accessor: 'country',
      sortable: true,
    },
    {
      header: 'Impressions',
      accessor: 'impressions',
      sortable: true,
    },
    {
      header: 'Clicks',
      accessor: 'clicks',
      sortable: true,
    },
    {
      header: 'CTR',
      accessor: 'ctr',
      render: formatPercentage,
      sortable: true,
    },
    {
      header: 'CPM',
      accessor: 'cpm',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPC',
      accessor: 'cpc',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPV',
      accessor: 'cpv',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'Conversions',
      accessor: 'total_conversions',
      sortable: true,
    },
    {
      header: 'CPA',
      accessor: 'cpa',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'Spend',
      accessor: 'total_spend',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
  ];

  if (isNotSelectAgencyAdvertiser) {
    return <ReportingInfo message="Please select agency and advertiser to see the report" />;
  }

  return (
    <>
      <TableContainer>
        <Flex gap="lg" align="center">
          <ControlledInput name="countrySearch" control={control} prefix="Search:" placeholder="Keyword" />
          {canWithMarkup && (
            <FlexItem shrink={0}>
              <Flex gap="md" align="center">
                <Text size="xs">With markup</Text>
                <ControlledSwitch name="withMarkup" control={control} />
              </Flex>
            </FlexItem>
          )}
          <DataTableExport
            onExport={(type) => {
              countryDataTableRef?.current?.export(type, 'reporting-geo-country');
            }}
          />
        </Flex>
        <Spacing size="lg" />
        <DataTable columns={countryColumns} {...countryDataTableProps} ref={countryDataTableRef} />
      </TableContainer>
      <Spacing size="lg" />
      <TableContainer>
        <Flex gap="lg" align="center">
          <ControlledInput name="regionSearch" control={control} prefix="Search:" placeholder="Keyword" />
          {canWithMarkup && (
            <FlexItem shrink={0}>
              <Flex gap="md" align="center">
                <Text size="xs">With markup</Text>
                <ControlledSwitch name="withMarkup" control={control} />
              </Flex>
            </FlexItem>
          )}
          <DataTableExport
            onExport={(type) => {
              regionDataTableRef?.current?.export(type, 'reporting-geo-region');
            }}
          />
        </Flex>
        <Spacing size="lg" />
        <DataTable columns={regionColumns} {...regionDataTableProps} ref={regionDataTableRef} />
      </TableContainer>
    </>
  );
};

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;

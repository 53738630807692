import { useNavigate } from 'react-router-dom';
import { Button } from '../Form';
import { Flex, Text } from '../Layout';
import { useWorkspace } from 'src/hooks';
import { capitalize } from 'lodash';
import { formatCount } from 'src/utils';

export const DataCreditsBanner = () => {
  const workspace = useWorkspace();
  const nav = useNavigate();

  return (
    <>
      <Flex gap="sm">
        <Text size="xs">Current Plan: </Text>
        <Text size="xs" weight={600}>
          {capitalize(workspace?.plan || 'free')}
        </Text>
      </Flex>
      <Flex gap="sm">
        <Text size="xs">Data Credits: </Text>
        <Text size="xs" weight={600}>
          {formatCount(workspace?.data_credits || 0)}
        </Text>
      </Flex>
      <Button size="sm" various="highlight" width="auto" onClick={() => nav('/planning/pricing')}>
        SEE PRICING
      </Button>
    </>
  );
};

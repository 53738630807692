import { difference, union, xor } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { AgencySelect } from 'src/components/AgencySelect';
import { Column, DataTable, MAX_PAGE_SIZE } from 'src/components/DataTable';
import { Checkbox, ControlledInput } from 'src/components/Form';
import { Flex, FlexItem, Spacing } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { useDataTable, useRole, useToast, useUserAgencyAdvertiser } from 'src/hooks';
import { growthApi } from 'src/services';
import { AudienceRule } from 'src/types';
import { getApiErrorMessage } from 'src/utils';
import styled from 'styled-components';

const { useAudiencesQuery, useLazyAudiencesQuery, useCreateOrUpdateAudienceMutation } = growthApi;

type ApplyToActiveAudiencesModalProps = {
  isOpen: boolean;
  exclusionRules?: AudienceRule[];
  onClose: () => void;
};

type ApplyToActiveFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  search?: string;
};

export const ApplyToActiveAudiencesModal = (props: ApplyToActiveAudiencesModalProps) => {
  const { isOpen, exclusionRules, onClose } = props;

  const { canAccessAgency } = useRole();
  const { agencyId, advertiserId, changeAgencyAdvertiser } = useUserAgencyAdvertiser();

  const { control, watch } = useForm<ApplyToActiveFormValues>({
    defaultValues: {
      agency_id: agencyId,
      advertiser_id: advertiserId,
    },
  });
  const values = watch();

  useEffect(() => {
    changeAgencyAdvertiser(values.agency_id, values.advertiser_id);
  }, [changeAgencyAdvertiser, values.agency_id, values.advertiser_id]);

  const { data, isFetching, error } = useAudiencesQuery({
    agency_id: values.agency_id,
    advertiser_id: values.advertiser_id,
  });
  const { dataTableProps } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['id', 'name', 'created_on'],
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    sortNumberKeys: ['id'],
    defaultPageSize: MAX_PAGE_SIZE,
  });
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const allIds = useMemo(() => {
    return dataTableProps.data?.map((row) => row.id) || [];
  }, [dataTableProps]);

  const [lazyAudiences] = useLazyAudiencesQuery();
  const [createOrUpdateAudience] = useCreateOrUpdateAudienceMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSuccessToast, showErrorToast } = useToast();

  const columns: Column[] = [
    {
      header: () => {
        const value = allIds.length > 0 && difference(allIds, selectedIds).length === 0;
        return (
          <Checkbox
            value={value}
            onChange={(value: boolean) => {
              setSelectedIds((selectedIds) => {
                return value ? union(selectedIds, allIds) : xor(selectedIds, allIds);
              });
            }}
          />
        );
      },
      accessor: '_selector',
      render: (_, row) => {
        const value = selectedIds.includes(row.id);
        return (
          <Checkbox
            value={value}
            onChange={(value: boolean) => {
              setSelectedIds((selectedIds) => {
                return value ? union(selectedIds, [row.id]) : xor(selectedIds, [row.id]);
              });
            }}
          />
        );
      },
    },
    { header: 'ID', accessor: 'id' },
    { header: 'Name', accessor: 'name' },
    { header: 'Created At', accessor: 'created_on' },
  ];

  const onConfirm = async () => {
    setIsLoading(true);
    try {
      for (const selectedId of selectedIds) {
        const audience = await lazyAudiences({ id: selectedId }).unwrap();
        const audienceRules = await lazyAudiences({ id: selectedId, type: 'rules' }).unwrap();
        // don't remove rules right now
        // remove exists exclusion rules
        // const groups = audienceRules.data.groups.filter(
        //   (group: AudienceGroup) => group.group_id || group.group_inclusion_type !== 'exclude',
        // );
        // const rules = audienceRules.data.rules.filter(
        //   (rule: AudienceRule) => rule.audience_group_id || rule.rule_inclusion_type !== 'exclude',
        // );
        // append new exclusion rules
        await createOrUpdateAudience({
          id: selectedId,
          ...audience.data,
          global_grouping_settings: {
            include_rules: audience.data.global_grouping_include || 'or',
            exclude_rules: audience.data.global_grouping_exclude || 'or',
          },
          no_group_rulegrouping: {
            include_grouping: audience.data.rule_grouping_include || 'or',
            exclude_grouping: audience.data.rule_grouping_exclude || 'or',
          },
          groups: audienceRules.data.groups || [],
          rules: [
            ...(audienceRules.data.rules || []),
            ...(exclusionRules.map((rule) => ({ ...rule, rule_inclusion_type: 'exclude' })) || []),
          ],
        }).unwrap();
      }
      showSuccessToast('Apply exclusion rules to active audiences successfully');
      onClose();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Apply Exclusion Rules To Active Audiences"
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
      width="70vw"
    >
      <Flex gap="lg">
        {canAccessAgency && (
          <>
            <AgencySelect prefix="Agency:" name="agency_id" withAll width="22rem" control={control} />
            <AdvertiserSelect
              prefix="Advertiser:"
              name="advertiser_id"
              withAll
              agencyId={values.agency_id}
              width="22rem"
              control={control}
            />
          </>
        )}
        <FlexItem grow={1}>
          <ControlledInput prefix="Search:" name="search" control={control} placeholder="Keyword" />
        </FlexItem>
      </Flex>
      <Spacing />
      <DataTableContainer>
        <DataTable columns={columns} {...dataTableProps} />
      </DataTableContainer>
    </Modal>
  );
};

const DataTableContainer = styled.div`
  height: 55rem;
  overflow-y: auto;
`;

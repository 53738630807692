export const PIXEL_TYPE_OPTIONS = [
  { label: 'Retargeting', value: 'retargeting' },
  // { label: 'Lookalike', value: 'lookalike' },
  { label: 'Conversion', value: 'conversion' },
];

export const PIXEL_FRAMEWORK_OPTIONS = [
  {
    label: 'Website (default)',
    value: 'website',
  },
  {
    label: 'Shopify',
    value: 'shopify',
  },
  {
    label: 'Google Tag Manager',
    value: 'gtm',
  },
];

export const PIXEL_ACTION_CATEGORY_OPTIONS = [
  {
    label: 'Page Load (default)',
    value: 'page_load',
  },
  {
    label: 'Button Click',
    value: 'button_click',
  },
];

export const PIXEL_CONVERSION_CATEGORY_OPTIONS = [
  {
    label: 'Purchase Funnel: Final Purchase or Sale',
    value: 'purchase',
  },
  {
    label: 'Purchase Funnel: Add Payment Information',
    value: 'add_payment',
  },
  {
    label: 'Purchase Funnel: Checkout Initiation',
    value: 'checkout',
  },
  {
    label: 'Purchase Funnel: Add to Cart',
    value: 'add_to_cart',
  },
  {
    label: 'Product Listing View',
    value: 'list_view',
  },
  {
    label: 'Page View',
    value: 'load',
  },
  {
    label: 'Click to Call',
    value: 'click_to_call',
  },
  {
    label: 'Lead Form Fill',
    value: 'form_fill',
  },
  {
    label: 'Content Download',
    value: 'download',
  },
  {
    label: 'Email Sign-Up',
    value: 'signup',
  },
  {
    label: 'Donate',
    value: 'donate',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

import { Icon } from '../Icon';
import { Flex } from '../Layout';

type CheckboxProps = {
  value: boolean;
  label?: string;
  onChange: (value: boolean) => void;
};

export const Checkbox = (props: CheckboxProps) => {
  const { value, label, onChange } = props;

  return (
    <Flex clickable onClick={() => onChange(!value)}>
      <Icon type="checkbox" size="md" color={value ? 'primary' : 'grayLighter'} />
      {label && <div>{label}</div>}
    </Flex>
  );
};

import { ReactNode } from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';
import { Flex, FlexItem, Text } from '../Layout';

type TagProps = {
  onClose: () => void;
  children?: ReactNode;
};

export const Tag = (props: TagProps) => {
  const { onClose, children } = props;

  return (
    <Container>
      <Flex gap="xs" align="center">
        <Text size="xs" weight={500}>
          {children}
        </Text>
        <FlexItem shrink={0}>
          <Icon size="sm" color="error" type="close" clickable onClick={onClose} />
        </FlexItem>
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  background: #e8e8e8;
  padding: 0.4rem 0.8rem;
  border: solid 0.1rem #ccc;
  border-radius: 0.4rem;
`;

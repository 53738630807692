import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { Column, DataTable, DataTableExport } from 'src/components/DataTable';
import { ControlledInput, ControlledSwitch } from 'src/components/Form';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { TOKENS } from 'src/design';
import { growthApi } from 'src/services';
import { calcWithMarkup, formatAmount, formatPercentage } from 'src/utils';
import styled from 'styled-components';
import { ReportingFormValues } from './Reporting';
import { useDataTable, useRole } from 'src/hooks';
import { ReportingInfo } from 'src/components/ReportingInfo';

const { useAppTransparencyQuery } = growthApi;

type ReportingGeoProps = Pick<
  ReportingFormValues,
  'timeRange' | 'dateFrom' | 'dateTo' | 'agencyId' | 'advertiserId' | 'campaignId'
> & {
  markup?: number;
};

type ReportingAppsFormValues = {
  search?: string;
  withMarkup?: boolean;
};

export const ReportingApps = (props: ReportingGeoProps) => {
  const { timeRange, dateFrom, dateTo, agencyId, advertiserId, campaignId, markup } = props;

  const { canWithMarkup } = useRole();
  const { watch, control } = useForm<ReportingAppsFormValues>({
    defaultValues: {
      withMarkup: true,
    },
  });
  const values = watch();
  const finalMarkup = values.withMarkup ? markup : 0;
  const isNotSelectAgencyAdvertiser = !agencyId || !advertiserId;

  const { data, isFetching, error } = useAppTransparencyQuery(
    {
      time_range: timeRange,
      date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
      date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
      agency_id: agencyId,
      advertiser_id: advertiserId,
      campaign_id: campaignId,
    },
    { skip: isNotSelectAgencyAdvertiser },
  );

  const columns: Column[] = [
    {
      header: 'App ID',
      accessor: 'app_id',
      sortable: true,
      render: (value: any) => <Text breakAll>{value}</Text>,
    },
    {
      header: 'App',
      accessor: 'app_name',
      sortable: true,
      render: (value: any) => <Text breakAll>{value}</Text>,
    },
    {
      header: 'Impressions',
      accessor: 'impressions',
      sortable: true,
    },
    {
      header: 'Clicks',
      accessor: 'clicks',
      sortable: true,
    },
    {
      header: 'CTR',
      accessor: 'ctr',
      render: formatPercentage,
      sortable: true,
    },
    {
      header: 'CPM',
      accessor: 'cpm',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPC',
      accessor: 'cpc',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPV',
      accessor: 'cpv',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPCV',
      accessor: 'cpcv',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPL',
      accessor: 'cpl',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPCL',
      accessor: 'cpcl',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPA',
      accessor: 'cpa',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'Spend',
      accessor: 'total_spend',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
  ];

  const { dataTableProps, dataTableRef } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['app_id', 'app_name'],
    defaultSort: {
      key: 'impressions',
      direction: 'desc',
    },
    sortNumberKeys: ['impressions', 'clicks', 'ctr', 'cpm', 'cpc', 'cpv', 'total_conversions', 'cpa', 'total_spend'],
  });

  if (isNotSelectAgencyAdvertiser) {
    return <ReportingInfo message="Please select agency and advertiser to see the report" />;
  }

  return (
    <TableContainer>
      <Flex gap="lg" align="center">
        <ControlledInput name="search" control={control} prefix="Search:" placeholder="Keyword" />
        {canWithMarkup && (
          <FlexItem shrink={0}>
            <Flex gap="md" align="center">
              <Text size="xs">With markup</Text>
              <ControlledSwitch name="withMarkup" control={control} />
            </Flex>
          </FlexItem>
        )}
        <DataTableExport
          onExport={(type) => {
            dataTableRef?.current?.export(type, 'reporting-apps');
          }}
        />
      </Flex>
      <Spacing size="lg" />
      <DataTable columns={columns} {...dataTableProps} />
    </TableContainer>
  );
};

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;

import { format } from 'date-fns';
import { round } from 'lodash';

export const formatBoolean = (value: any) => {
  return value ? 'Yes' : 'No';
};

export const formatDate = (value: any) => {
  if (!value || value === '-' || value === '0000-00-00 00:00:00') {
    return '-';
  }
  return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
};

export const formatOnlyDate = (value: any) => {
  if (!value || value === '-' || value === '0000-00-00 00:00:00') {
    return '-';
  }
  return format(new Date(value), 'yyyy-MM-dd');
};

export const formatCount = (value?: any) => {
  if (value === undefined || value === null || value === '-') {
    return '-';
  } else {
    return Number(value).toLocaleString('en-US');
  }
};

export const formatAmount = (value?: any) => {
  if (value === undefined || value === null || value === '-') {
    return '-';
  } else {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
  }
};

export const formatAmountWithPrecision = (value?: any, precision?: number) => {
  if (value === undefined || value === null || value === '-') {
    return '-';
  } else {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: precision ?? 2,
    });
  }
};

export const formatPercentage = (value?: any) => {
  if (value === undefined || value === null || value === '-') {
    return '-';
  } else {
    return Number(Number(value).toFixed(2)) + '%';
  }
};

export const formatPercentageWithPrecision = (value?: any, precision?: number) => {
  if (value === undefined || value === null || value === '-') {
    return '-';
  } else {
    return Number(Number(value).toFixed(precision ?? 2)) + '%';
  }
};

export const formatText = (value?: any) => {
  if (!value) {
    return '';
  }
  return String(value)
    .replace(/<\/?[^>]+(>|$)/g, '')
    .trim();
};

export const formatArray = (value?: any) => {
  if (!value) {
    return [];
  }
  if (Array.isArray(value)) {
    return value;
  } else if (typeof value === 'string') {
    // support string value, exp 1,2,3, and also check if item is number
    return value.split(',').map((item) => (/\d+/.test(item) ? Number(item) : item));
  } else if (typeof value === 'number') {
    // support number value, exp 1
    return [value];
  } else {
    return [];
  }
};

export const formatSize = (value?: any) => {
  if (!value) {
    return '-';
  }
  if (value > 1000 * 1000) {
    return `${round(value / 1000 / 1000)}M`;
  } else if (value > 1000) {
    return `${round(value / 1000)}K`;
  } else {
    return value;
  }
};

export const formatReportingId = (value?: any) => {
  const ids = [];
  for (const platform in value) {
    if (Array.isArray(value[platform])) {
      value[platform].forEach((id: any) => {
        ids.push(id);
      });
    } else if (['string', 'number'].includes(typeof value[platform])) {
      ids.push(value[platform]);
    }
  }
  return ids.join(', ');
};

import { Button, ControlledSelect } from 'src/components/Form';
import { Icon } from 'src/components/Icon';
import { Flex, FlexItem, Grid, Spacing, Text } from 'src/components/Layout';
import { Loading } from 'src/components/Loading';
import { PageTemplate } from 'src/components/Template';
import { UserBanner } from 'src/components/UserBanner';
import { subDays } from 'date-fns';
import { TOKENS } from 'src/design';
import { getUserName } from 'src/helpers';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { growthApi } from 'src/services';
import { useAppSelector } from 'src/store';
import styled from 'styled-components';
import { DateRange, DATE_RANGE_OPTIONS, getApiErrorMessage, imageUrl, parseDateRange } from 'src/utils';
import { useForm } from 'react-hook-form';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { ChartSummary } from './ChartSummary';
import { ChartSites } from './ChartSites';
import { ChartCreatives } from './ChartCreatives';
import { useMarkup, useRole, useWhiteLabelTheme } from 'src/hooks';
import { Error } from 'src/components/Error';
import { AgencySelect } from 'src/components/AgencySelect';
import { useUserAgencyAdvertiser } from 'src/hooks';

enum OverviewAccountSetup {
  SetupPixels,
  CreateAudiences,
  UploadCreatives,
  CreateYourFirstCampaign,
  ConnectBilling,
  TrackPerformance,
}

const OVERVIEW_ACCOUNT_SETUPS = [
  {
    label: 'Set up Pixels',
    value: OverviewAccountSetup.SetupPixels,
    url: '/activate/pixels/new',
  },
  {
    label: 'Create Audiences',
    value: OverviewAccountSetup.CreateAudiences,
    url: '/activate/audiences/new',
  },
  {
    label: 'Upload Creatives',
    value: OverviewAccountSetup.UploadCreatives,
    url: '/activate/creatives/new',
  },
  {
    label: 'Create your first Campaign',
    value: OverviewAccountSetup.CreateYourFirstCampaign,
    url: '/activate/campaigns/new',
  },
  {
    label: 'Connect billing',
    value: OverviewAccountSetup.ConnectBilling,
    url: '/settings/ad-wallet',
  },
  {
    label: 'Track performance',
    value: OverviewAccountSetup.TrackPerformance,
    url: '/activate/reporting',
  },
];

const { useCampaignsQuery } = growthApi;

export type OverviewFormValues = {
  agencyId?: number;
  advertiserId?: number;
  timeRange?: DateRange;
  dateFrom?: Date;
  dateTo?: Date;
};

export const Overview = () => {
  const { canAccessAgency } = useRole();
  const user = useAppSelector((state) => state.user.user);
  const theme = useWhiteLabelTheme();
  const { agencyId, advertiserId, changeAgencyAdvertiser } = useUserAgencyAdvertiser();
  const { watch, control, setValue } = useForm<OverviewFormValues>({
    defaultValues: {
      agencyId,
      advertiserId,
      timeRange: DateRange.Last30Days,
      dateFrom: subDays(new Date(), 30),
      dateTo: new Date(),
    },
  });
  const values = watch();

  useEffect(() => {
    changeAgencyAdvertiser(values.agencyId, values.advertiserId);
  }, [changeAgencyAdvertiser, values.advertiserId, values.agencyId]);

  const markup = useMarkup(values.agencyId, values.advertiserId);

  const { data, isFetching, error } = useCampaignsQuery({
    agency_id: values.agencyId,
    advertiser_id: values.advertiserId,
  });

  useEffect(() => {
    const { dateTo, dateFrom } = parseDateRange(values.timeRange!) || {};
    setValue('dateTo', dateTo);
    setValue('dateFrom', dateFrom);
  }, [setValue, values.timeRange]);

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Overview
          </Text>
          {canAccessAgency && (
            <>
              <AgencySelect
                prefix="Agency:"
                name="agencyId"
                control={control}
                withAll
                width="22rem"
                onValueChange={() => setValue('advertiserId', undefined)}
              />
              <AdvertiserSelect
                agencyId={values.agencyId}
                prefix="Advertiser:"
                name="advertiserId"
                control={control}
                withAll
                width="22rem"
              />
            </>
          )}
          <ControlledSelect
            prefix="Date Range:"
            name="timeRange"
            control={control}
            options={DATE_RANGE_OPTIONS}
            width="22rem"
          />
        </Flex>
        <UserBanner />
      </Flex>
      <Spacing size="xl" />
      {error ? (
        <Flex justify="center" align="center">
          <FlexItem width="60rem">
            <Flex direction="column" align="center">
              <Spacing size="8rem" />
              {user?.dsp_username ? (
                <Error error={getApiErrorMessage(error)} height="9rem" />
              ) : (
                <>
                  <Text size="xl" weight={600}>
                    Access pending
                  </Text>
                  <Spacing size="xxl" />
                  {theme.pendingAccessMessage ?? (
                    <>
                      <Text size="sm">
                        To get started with Growth Channel Activate DSP for programmatic advertising, fill in{' '}
                        <a
                          target="_blank"
                          href="https://www.growthchannel.com/get-started/request-dsp-access"
                          rel="noreferrer"
                        >
                          <Text as="span" color="primary">
                            this form
                          </Text>
                        </a>
                        .
                      </Text>
                      <Spacing />
                      <Text size="sm">
                        If you already requested access, check your email for further instructions or refresh the page
                        later. It may take up to 48 hours to process a new access request.
                      </Text>
                    </>
                  )}
                </>
              )}
            </Flex>
          </FlexItem>
        </Flex>
      ) : isFetching ? (
        <Loading height="60rem" />
      ) : data?.data?.length ? (
        <>
          <ChartSummary {...values} markup={markup} />
          <Spacing size="lg" />
          <Grid gap="md" align="start" columns={2}>
            <ChartSites {...values} />
            <ChartCreatives {...values} markup={markup} />
          </Grid>
        </>
      ) : (
        <>
          <Grid gap="md" columns={2}>
            <AccountSetup>
              <Text size="xl" weight={700}>
                Account Setup
              </Text>
              <Spacing size="lg" />
              <Grid flow="column" rows={3} gap="sm">
                {OVERVIEW_ACCOUNT_SETUPS.map((setup) => (
                  <Link key={setup.value} to={setup.url}>
                    <Flex align="center" gap="sm">
                      <Icon type="check" color="primaryDeep" />
                      <Text size="sm">{setup.label}</Text>
                    </Flex>
                  </Link>
                ))}
              </Grid>
            </AccountSetup>
            <Welcome>
              <Flex gap="xs">
                <Text size="xl" weight={700}>
                  Welcome,
                </Text>
                <Text size="xl" weight={700} color="primary">
                  {getUserName(user)}!
                </Text>
              </Flex>
              <Spacing size="lg" />
              <Text size="sm">{theme.description}</Text>
              <img src={imageUrl('welcome-bg.png')} alt="background" />
            </Welcome>
          </Grid>
          <Spacing size="md" />
          <Flex direction="column" justify="center" align="center" gap="md" height="40rem">
            <CreateCampaignImage src={imageUrl('notice.png')} alt="notice" />
            <Text size="lg" weight={600}>
              Start by creating an advertising campaign
            </Text>
            <Text size="sm">You don't have any campaigns running yet.</Text>
            <Link to="/activate/campaigns/new">
              <Button various="highlight" width="25rem" shadow>
                CREATE A CAMPAIGN
              </Button>
            </Link>
          </Flex>
        </>
      )}
    </PageTemplate>
  );
};

const AccountSetup = styled.div`
  color: white;
  background: ${(props) => props.theme.color.primary};
  padding: 1.6rem 2.4rem;
  border-radius: 1rem;
  box-shadow: ${TOKENS.shadow.default};

  a {
    color: white;
  }
`;

const Welcome = styled.div`
  position: relative;
  background: white;
  padding: 1.6rem 2.4rem;
  border-radius: 1rem;
  box-shadow: ${TOKENS.shadow.default};
  padding-right: 18rem;

  img {
    position: absolute;
    width: 20rem;
    bottom: 0;
    right: 0;
  }
`;

const CreateCampaignImage = styled.img`
  width: 9rem;
  height: 9rem;
`;

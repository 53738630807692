import styled from 'styled-components';
import { Flex, Text } from 'src/components/Layout';
import { Table, TABLE_MAX_COL_SPAN, TABLE_MAX_ROW_SPAN } from 'src/components/Table';
import { Fragment } from 'react';
import { ControlledRadioGroup, ControlledRadioGroupOption } from 'src/components/Form';
import { useFormContext } from 'react-hook-form';
import { AudienceFormValues } from './AudienceEdit';
import { keys, pickBy, upperFirst } from 'lodash';
import { renderRuleValue } from 'src/helpers';

const LOGIC_OPTIONS: ControlledRadioGroupOption[] = [
  {
    label: 'And',
    value: 'and',
  },
  {
    label: 'Or',
    value: 'or',
  },
];

const AUDIENCE_GROUP_TYPES = [
  {
    title: 'Included',
    logicName: 'global_grouping_settings.include_rules',
    noGroupLogicName: 'no_group_rulegrouping.include_grouping',
    groupInclusionType: 'include',
  },
  {
    title: 'Excluded',
    logicName: 'global_grouping_settings.exclude_rules',
    noGroupLogicName: 'no_group_rulegrouping.exclude_grouping',
    groupInclusionType: 'exclude',
  },
];

type AudienceGroupsProps = {
  onRuleRemove: (index: number) => void;
  onRuleEdit: (index: number) => void;
};

export const AudienceGroups = (props: AudienceGroupsProps) => {
  const { onRuleRemove, onRuleEdit } = props;

  const { watch, control } = useFormContext<AudienceFormValues>();

  const groups = watch('groups') || [];
  const rules = watch('rules') || [];

  return (
    <>
      {AUDIENCE_GROUP_TYPES.map((type) => {
        const groupIds = groups
          .filter((group) => group.group_inclusion_type === type.groupInclusionType)
          .map((group) => group.group_id || 0);
        // check if exist no group rules
        if (
          rules.filter((rule) => rule.rule_inclusion_type === type.groupInclusionType && rule.audience_group_id === 0)
            .length > 0
        ) {
          groupIds.unshift(0);
        }
        return (
          <GroupsContainer key={type.groupInclusionType}>
            <Text weight={500}>{type.title}</Text>
            {groupIds.length > 0 ? (
              <Table various="border" border={1} cellSpacing={0} cellPadding={0}>
                <tbody>
                  <tr>
                    {groupIds.length > 1 && (
                      <LogicTd rowSpan={TABLE_MAX_ROW_SPAN}>
                        <ControlledRadioGroup
                          name={type.logicName as any}
                          control={control}
                          options={LOGIC_OPTIONS}
                          size="sm"
                          vertical
                        />
                      </LogicTd>
                    )}
                  </tr>
                  {groupIds.map((groupId) => {
                    const ruleIndexes = keys(
                      pickBy(rules, { audience_group_id: groupId, rule_inclusion_type: type.groupInclusionType }),
                    ).map(Number);
                    const groupIndex = groups.findIndex((group) => group.group_id === groupId);
                    return (
                      <Fragment key={groupId}>
                        <tr>
                          <th colSpan={TABLE_MAX_COL_SPAN}>{groupId === 0 ? 'Group1' : groups[groupIndex].name}</th>
                        </tr>
                        {ruleIndexes.length > 0 ? (
                          ruleIndexes.map((ruleIndex) => {
                            const rule = rules[ruleIndex];
                            return (
                              <tr key={ruleIndex}>
                                {ruleIndexes.findIndex((index) => index === ruleIndex) === 0 && (
                                  <td rowSpan={ruleIndexes.length}>
                                    {ruleIndexes.length > 1 && (
                                      <>
                                        <ControlledRadioGroup
                                          name={
                                            groupId === 0
                                              ? type.noGroupLogicName
                                              : (`groups.${groupIndex}.rule_grouping_type` as any)
                                          }
                                          control={control}
                                          options={LOGIC_OPTIONS}
                                          size="sm"
                                          vertical
                                        />
                                      </>
                                    )}
                                  </td>
                                )}
                                <td>{`${rule.rule_type_id_name}${
                                  rule.rule_type_name ? ` > ${rule.rule_type_name}` : ''
                                }`}</td>
                                <td>{renderRuleValue(rule)}</td>
                                <td>{upperFirst(rule.multi_value_grouping_type)}</td>
                                <td>
                                  <Flex gap="md">
                                    <Text color="primary" weight={600} clickable onClick={() => onRuleEdit(ruleIndex)}>
                                      Edit
                                    </Text>
                                    <Text color="error" weight={600} clickable onClick={() => onRuleRemove(ruleIndex)}>
                                      Remove
                                    </Text>
                                  </Flex>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={TABLE_MAX_COL_SPAN}>
                              <Text color="gray">No rules in group</Text>
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <Text size="xs" color="gray">
                No rules in place
              </Text>
            )}
          </GroupsContainer>
        );
      })}
    </>
  );
};

const GroupsContainer = styled.div`
  padding: 1.6rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;

const LogicTd = styled.td`
  width: 4rem;
`;

import { Column, DataTable } from 'src/components/DataTable';
import { Action, Button, ControlledInput } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { EditAdvertiserModal } from 'src/components/EditAdvertiserModal';
import { useState } from 'react';
import { growthApi } from 'src/services';
import { PageTemplate } from 'src/components/Template';
import { Archive } from 'src/components/Archive';
import { useForm } from 'react-hook-form';
import { useDataTable, useReloadUser } from 'src/hooks';
import { formatPercentage } from 'src/utils';
import { Role, User } from 'src/types';
import { EditMarkupModal } from 'src/components/EditMarkupModal';
import { AgencySelect } from 'src/components/AgencySelect';
import { capitalize } from 'lodash';
import { EditPlatformModal } from './EditPlatformModal';

const { useAdvertisersQuery } = growthApi;

type AdvertisersFormValues = {
  agency_id?: string;
  search?: string;
};

export const Advertisers = () => {
  const { watch, control } = useForm<AdvertisersFormValues>();
  const values = watch();
  const { data, error, isFetching, refetch } = useAdvertisersQuery({ agency_id: values.agency_id });
  const { dataTableProps, selectedRow, setSelectedRow } = useDataTable({
    data: data?.data,
    search: values.search,
    isLoading: isFetching,
    error,
    searchKeys: ['id', 'name', 'agency_id', 'agency_name'],
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    sortNumberKeys: ['id'],
  });
  const [editAdvertiserIsOpen, setEditAdvertiserIsOpen] = useState<boolean>(false);
  const [editMarkupIsOpen, setEditMarkupIsOpen] = useState<boolean>(false);
  const [editPlatformIsOpen, setEditPlatformIsOpen] = useState<boolean>(false);
  const reloadUser = useReloadUser();

  const columns: Column[] = [
    { header: 'ID', accessor: 'id', sortable: true },
    {
      header: 'Agency',
      accessor: '_agency',
      sortable: true,
      render: (_, row) => `${row.agency_name} - ${row.agency_id}`,
    },
    { header: 'Advertiser Name', accessor: 'name', sortable: true },
    {
      header: 'Platform',
      accessor: 'third_party_type',
      render: (value: any, row: any) => (
        <Flex gap="sm">
          <Text>{value ? capitalize(value) : 'Adlib'}</Text>
          <Action
            icon="edit"
            onClick={() => {
              setSelectedRow(row);
              setEditPlatformIsOpen(true);
            }}
          />
        </Flex>
      ),
      sortable: true,
      when: (user?: User) => [Role.Admin].includes(user?.role),
    },
    {
      header: 'Markup',
      accessor: 'markup',
      render: (value: any, row: any) => (
        <Flex gap="sm">
          <Text>{value ? formatPercentage(value) : '-'}</Text>
          <Action
            icon="edit"
            onClick={() => {
              setSelectedRow(row);
              setEditMarkupIsOpen(true);
            }}
          />
        </Flex>
      ),
      sortable: true,
      when: (user?: User) => [Role.Admin, Role.WhiteLabel].includes(user?.role),
    },
    {
      header: '',
      accessor: 'action',
      width: '3rem',
      render: (_, row) => (
        <Flex gap="md">
          <Action
            onClick={() => {
              setSelectedRow(row);
              setEditAdvertiserIsOpen(true);
            }}
          >
            Edit
          </Action>
          <Archive type="advertiser" typeId={row.id} onSuccess={() => refetch()} />
        </Flex>
      ),
    },
  ];

  return (
    <PageTemplate>
      <Flex align="center" justify="space-between">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Advertisers
          </Text>
          <AgencySelect name="agency_id" prefix="Agency: " control={control} withAll />
          <ControlledInput width="30rem" prefix="Search:" name="search" control={control} placeholder="Keyword" />
        </Flex>
        <Button
          width="auto"
          shadow
          onClick={() => {
            setSelectedRow(undefined);
            setEditAdvertiserIsOpen(true);
          }}
        >
          NEW ADVERTISER
        </Button>
      </Flex>
      <Spacing size="xl" />
      <DataTable columns={columns} {...dataTableProps} />
      <EditAdvertiserModal
        isOpen={editAdvertiserIsOpen}
        advertiser={selectedRow}
        onClose={() => setEditAdvertiserIsOpen(false)}
        onSuccess={() => {
          setEditAdvertiserIsOpen(false);
          refetch();
        }}
      />
      <EditMarkupModal
        isOpen={editMarkupIsOpen}
        onClose={() => setEditMarkupIsOpen(false)}
        onSuccess={() => refetch()}
        type="advertiser"
        id={selectedRow?.id}
      />
      <EditPlatformModal
        isOpen={editPlatformIsOpen}
        advertiser={selectedRow}
        onClose={() => setEditPlatformIsOpen(false)}
        onSuccess={() => {
          setEditPlatformIsOpen(false);
          refetch();
          reloadUser();
        }}
      />
    </PageTemplate>
  );
};

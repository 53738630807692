import { TOKENS } from 'src/design';
import styled, { css, keyframes } from 'styled-components';
import { SVGS } from './svgs';

type IconSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';

type IconColor = keyof typeof TOKENS.color;

export type IconProps = {
  type: keyof typeof SVGS;
  size?: IconSize | (string & {});
  color?: IconColor;
  secondaryColor?: IconColor;
  clickable?: boolean;
  onClick?: () => void;
};

const SIZE_MAPPING: Record<IconSize, number> = {
  sm: 16,
  md: 24,
  lg: 32,
  xl: 48,
  xxl: 64,
  xxxl: 72,
};

export const Icon = (props: IconProps) => {
  const { type, size = 'md', color, secondaryColor, clickable, onClick } = props;

  const finalSize = size in SIZE_MAPPING ? SIZE_MAPPING[size as IconSize] : size;

  return (
    <StyledSvg
      width={finalSize}
      height={finalSize}
      color={color}
      secondaryColor={secondaryColor}
      $clickable={clickable}
      onClick={onClick}
      $loading={type === 'loading'}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {SVGS[type]}
    </StyledSvg>
  );
};

const rotate = keyframes`
  0% { 
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSvg = styled.svg<
  Pick<IconProps, 'color' | 'secondaryColor'> & { $loading?: boolean; $clickable?: boolean }
>`
  fill: ${(props) => (props.color ? props.theme.color[props.color] : 'currentColor')};

  .secondary {
    fill: ${(props) => (props.secondaryColor ? TOKENS.color[props.secondaryColor] : 'transparent')};
  }

  ${(props) =>
    props.$loading
      ? css`
          animation: infinite 1s ${rotate} linear;
        `
      : ''};

  ${(props) =>
    props.$clickable
      ? css`
          cursor: pointer;
        `
      : ''};
`;

import { Flex } from 'src/components/Layout';
import styled, { css } from 'styled-components';

type DataTablePaginateProps = {
  page: number;
  pageCount: number;
  onPageChange: (page: number) => void;
};

export const DataTablePaginate = (props: DataTablePaginateProps) => {
  const { page, pageCount, onPageChange } = props;

  const prevAble = page !== 1;
  const nextAble = page !== pageCount;

  return (
    <Flex gap="sm">
      <PageAction $active={prevAble} onClick={prevAble ? () => onPageChange(1) : undefined}>
        First
      </PageAction>
      <PageAction $active={prevAble} onClick={prevAble ? () => onPageChange(page - 1) : undefined}>
        {'<'}
      </PageAction>
      {Array.from({ length: pageCount }).map((_, index) => {
        const currentPage = index + 1;
        return (
          <PageNumber
            key={currentPage}
            $active={currentPage === page}
            $hidden={currentPage < page - 3 || currentPage > page + 3}
            onClick={() => onPageChange(currentPage)}
          >
            {currentPage}
          </PageNumber>
        );
      })}
      <PageAction $active={nextAble} onClick={nextAble ? () => onPageChange(page + 1) : undefined}>
        {'>'}
      </PageAction>
      <PageAction $active={nextAble} onClick={nextAble ? () => onPageChange(pageCount) : undefined}>
        Last
      </PageAction>
    </Flex>
  );
};

const PageNumber = styled.div<{ $active?: boolean; $hidden: boolean }>`
  cursor: pointer;
  padding: 0.6rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 0.2rem;
  width: 1rem;
  text-align: center;

  ${(props) =>
    props.$active
      ? css`
          color: white;
          background: ${(props) => props.theme.color.primary};
        `
      : css`
          color: ${(props) => props.theme.color.primary};
          border: solid 0.1em ${(props) => props.theme.color.primary};
          background: white;
        `}

  ${(props) => (props.$hidden ? 'display: none;' : '')}
`;

const PageAction = styled.div<{ $active?: boolean }>`
  color: ${(props) => props.theme.color.primary};
  border: solid 0.1em ${(props) => props.theme.color.primary};
  background: white;
  padding: 0.6rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 0.2rem;
  text-align: center;

  ${(props) =>
    props.$active
      ? css`
          cursor: pointer;
          color: ${(props) => props.theme.color.primary};
          border: solid 0.1em ${(props) => props.theme.color.primary};
          background: white;
        `
      : css`
          color: ${(props) => props.theme.color.gray};
          border: solid 0.1em ${(props) => props.theme.color.gray};
          background: white;
        `}
`;

import { PageTemplate } from 'src/components/Template';
import { Column, DataTable, DataTableExport } from 'src/components/DataTable';
import { growthApi } from 'src/services';
import { formatAmount, formatDate, getApiErrorMessage } from 'src/utils';
import { Button, ControlledInput } from 'src/components/Form';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { useDataTable, useRole, useToast } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { format } from 'date-fns-tz';

const { useTransactionsQuery, useManualDspBillingMutation } = growthApi;

type TransactionsFormValues = {
  search?: string;
  billing_date?: Date;
};

export const Transactions = () => {
  const { isAdmin } = useRole();
  const { data, isFetching, error, refetch } = useTransactionsQuery({});
  const { showSuccessToast, showErrorToast } = useToast();
  const { control, watch, handleSubmit } = useForm<TransactionsFormValues>();
  const values = watch();
  const [triggerDspBilling, { isLoading }] = useManualDspBillingMutation();
  const { dataTableProps, dataTableRef } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['id', 'agency_name', 'advertiser_name', 'type', 'time', 'description', 'created_at'],
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    sortNumberKeys: ['id', 'amount', 'markup'],
  });

  const onSubmit = async (values: TransactionsFormValues) => {
    try {
      await triggerDspBilling({
        date: values.billing_date ? format(values.billing_date, 'yyyy-MM-dd 00:00:00xxx') : undefined,
      }).unwrap();
      showSuccessToast('Manual billing successfully');
      refetch();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const columns: Column[] = [
    { header: 'ID', accessor: 'id', sortable: true },
    { header: 'Agency', accessor: 'agency_name', render: (value) => value ?? '-', sortable: true },
    { header: 'Advertiser', accessor: 'advertiser_name', render: (value) => value ?? '-', sortable: true },
    { header: 'Type', accessor: 'type', sortable: true },
    { header: 'Time', accessor: 'transaction_time', render: formatDate, sortable: true },
    {
      header: 'Amount',
      accessor: 'amount',
      render: (value: any, row: any) => `${row.type === 'spend' ? '-' : '+'} ${formatAmount(value)}`,
      sortable: true,
    },
    {
      header: 'Markup',
      accessor: 'markup',
      render: (value: any, row: any) => (row.type === 'spend' ? formatAmount(value) : '-'),
      sortable: true,
    },
    { header: 'Description', accessor: 'description', sortable: true },
    { header: 'Created At', accessor: 'created_at', render: formatDate, sortable: true },
  ];

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="xxl">
          <Text size="xxl" weight={700}>
            Transactions
          </Text>
          <ControlledInput width="30rem" prefix="Search:" name="search" control={control} placeholder="Keyword" />
        </Flex>
        <Flex gap="md" align="center">
          {isAdmin && (
            <>
              <FlexItem width="20rem">
                <ControlledDatePicker name="billing_date" control={control} placeholder="Back charge date" />
              </FlexItem>
              <Button width="18rem" shadow onClick={handleSubmit(onSubmit)} isLoading={isLoading}>
                BACK CHARGE
              </Button>
            </>
          )}
          <DataTableExport
            onExport={(type) => {
              dataTableRef?.current?.export(type, 'transactions');
            }}
          />
        </Flex>
      </Flex>
      <Spacing size="xl" />
      <DataTable columns={columns} {...dataTableProps} />
    </PageTemplate>
  );
};

import { ControlledRadioGroup, ControlledSelect, Form, FormItem } from 'src/components/Form';
import { ControlledInput } from 'src/components/Form/ControlledInput';
import { FormAction } from 'src/components/Form/FormAction';
import { Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { useRole, useToast, useUserAgencyAdvertiser } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { growthApi } from 'src/services';
import { getApiErrorMessage, transformObject, TransformType } from 'src/utils';
import { useEffect, useMemo, useState } from 'react';
import { PIXEL_CONVERSION_CATEGORY_OPTIONS, PIXEL_TYPE_OPTIONS } from './constants';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { PixelCodes } from './PixelCodes';
import { GetPixelCodeModal } from './GetPixelCodeModal';

const { usePixelsQuery, useCreateOrUpdatePixelMutation } = growthApi;

export type PixelFormValues = {
  agency_id: number;
  advertiser_id: number;
  pixel_name: string;
  pixel_type: string;
  framework: string;
  action_category: string;
  conversion_category: string;
  codes: {
    adlib: string;
    stackadapt: string;
    beeswax: string;
    iqm: string;
  };
};

export const PixelEdit = () => {
  const { canAccessAgency } = useRole();
  const { agencyId, advertiserId } = useUserAgencyAdvertiser();
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const isNew = !uuid;
  const { data, isLoading: isDetailLoading } = usePixelsQuery({ uuid }, { skip: isNew });
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const [createOrUpdatePixel, { isLoading }] = useCreateOrUpdatePixelMutation();
  const defaultValues: PixelFormValues = useMemo(
    () => ({
      agency_id: agencyId,
      advertiser_id: advertiserId,
      pixel_name: '',
      pixel_type: 'retargeting',
      framework: 'website',
      action_category: 'page_load',
      conversion_category: 'purchase',
      codes: {
        adlib: '',
        stackadapt: '',
        beeswax: '',
        iqm: '',
      },
    }),
    [agencyId, advertiserId],
  );
  const { watch, handleSubmit, control, reset, setValue } = useForm<PixelFormValues>({
    defaultValues,
  });
  const values = watch();
  const [newPixel, setNewPixel] = useState<any>();
  const [isGetPixelCodeOpen, setIsGetPixelCodeOpen] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      const transformedData = transformObject(data?.data, {
        uuid: TransformType.None,
        agency_id: TransformType.Number,
        advertiser_id: TransformType.Number,
        pixel_name: TransformType.None,
        pixel_type: TransformType.None,
        framework: TransformType.None,
        action_category: TransformType.None,
        conversion_category: TransformType.None,
        codes: TransformType.None,
      });
      reset(transformedData);
    }
  }, [data, reset]);

  const backToList = () => {
    navigate('/activate/pixels');
  };

  const onSubmit = async (values: PixelFormValues) => {
    try {
      const result = await createOrUpdatePixel(values).unwrap();
      if (isNew) {
        setNewPixel(result.data);
        setIsGetPixelCodeOpen(true);
        showSuccessToast('Create pixel successfully');
      } else {
        showSuccessToast('Save pixel successfully');
        backToList();
      }
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate isLoading={isDetailLoading}>
      <Text size="xxl" weight={700}>
        {isNew ? 'Create Pixel' : 'Edit Pixel'}
      </Text>
      <Spacing size="xl" />
      <Form width="70%">
        {canAccessAgency && (
          <>
            <FormItem label="Agency" required>
              <AgencySelect
                name="agency_id"
                control={control}
                onValueChange={() => {
                  setValue('advertiser_id', undefined);
                }}
              />
            </FormItem>
            <FormItem label="Advertiser" required>
              <AdvertiserSelect agencyId={values.agency_id} name="advertiser_id" control={control} />
            </FormItem>
          </>
        )}
        <FormItem label="Pixel Name" required>
          <ControlledInput name="pixel_name" control={control} placeholder="Enter pixel name" />
        </FormItem>
        <FormItem label="Type" required>
          <ControlledRadioGroup name="pixel_type" control={control} options={PIXEL_TYPE_OPTIONS} />
        </FormItem>
        {values.pixel_type === 'conversion' && (
          <FormItem label="Conversion Category" required>
            <ControlledSelect
              name="conversion_category"
              control={control}
              options={PIXEL_CONVERSION_CATEGORY_OPTIONS}
            />
          </FormItem>
        )}
        {/* <FormItem label="Framework" required>
          <ControlledSelect name="framework" control={control} options={PIXEL_FRAMEWORK_OPTIONS} />
        </FormItem>
        <FormItem label="Action Category" required>
          <ControlledSelect name="action_category" control={control} options={PIXEL_ACTION_CATEGORY_OPTIONS} />
        </FormItem> */}
        <PixelCodes control={control} />
        <FormAction onSubmit={handleSubmit(onSubmit)} isSubmitting={isLoading} disabled={isDetailLoading} />
      </Form>
      {/* show get pixel code modal when create new pixel */}
      <GetPixelCodeModal
        isOpen={isGetPixelCodeOpen}
        pixel={newPixel}
        onClose={() => {
          setIsGetPixelCodeOpen(false);
          backToList();
        }}
      />
    </PageTemplate>
  );
};

import {
  ControlledCheckboxGroupOption,
  ControlledInput,
  Form,
  FormItem,
  ControlledCard,
  ControlledCheckBoxGroup,
} from 'src/components/Form';
import { Text } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { useRole, useToast, useWhiteLabelTheme } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { formatAmount, getApiErrorMessage } from 'src/utils';
import { useMemo } from 'react';

const { useUpdateAdWalletPaymentMethodMutation } = growthApi;

type SetBillingInformationModalProps = {
  isOpen: boolean;
  agencyId?: number;
  advertiserId?: number;
  onClose: () => void;
  onSuccess: () => void;
};

enum PaymentTerm {
  TermsAndConditions,
  FirstCharge,
}

type SetBillingInformationFormValues = {
  customer?: {
    name?: string;
    email?: string;
    address?: {
      line1?: string;
    };
  };
  card?: any;
  paymentTerms: PaymentTerm[];
};

export const SetBillingInformationModal = (props: SetBillingInformationModalProps) => {
  const { isOpen, agencyId, advertiserId, onClose, onSuccess } = props;

  const { agencyId: userAgencyId, isWhiteLabel, whiteLabelId } = useRole();
  const { watch, control, handleSubmit } = useForm<SetBillingInformationFormValues>();
  const [updateAdWalletPaymentMethod, { isLoading }] = useUpdateAdWalletPaymentMethodMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const theme = useWhiteLabelTheme();

  const finalWhiteLabelId = useMemo(() => {
    // if is white label and they set their agency payment, will binding credit card to us
    return isWhiteLabel && agencyId === userAgencyId && !advertiserId ? undefined : whiteLabelId;
  }, [advertiserId, agencyId, isWhiteLabel, userAgencyId, whiteLabelId]);

  const onSubmit = async (values: SetBillingInformationFormValues) => {
    try {
      await updateAdWalletPaymentMethod({
        ...values,
        agency_id: agencyId,
        advertiser_id: advertiserId,
        white_label_id: finalWhiteLabelId,
      }).unwrap();
      showSuccessToast('Update ad wallet payment method successfully');
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const PAYMENT_TERM_OPTIONS: ControlledCheckboxGroupOption[] = [
    {
      label: (
        <Text size="xs">
          I understand & accept the{' '}
          <a target="_blank" href={theme.termsLink} rel="noreferrer">
            <Text as="span" color="primary">
              Terms and Conditions
            </Text>
          </a>
          .
        </Text>
      ),
      value: PaymentTerm.TermsAndConditions,
    },
    {
      label: (
        <Text size="xs">
          I understand & accept that upon successfully adding a payment method, I will be charged a deposit of{' '}
          <Text as="span" weight={600}>
            {formatAmount(theme.initialCharge)}
          </Text>
        </Text>
      ),
      value: PaymentTerm.FirstCharge,
    },
  ];

  const { paymentTerms } = watch();

  const disabled = !paymentTerms || paymentTerms.length < 2;

  return (
    <Modal
      isOpen={isOpen}
      title="Set Payment Method"
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      disabled={disabled}
      isLoading={isLoading}
    >
      <Form>
        <FormItem label="Name">
          <ControlledInput name="customer.name" control={control} placeholder="Enter name" />
        </FormItem>
        <FormItem label="Email">
          <ControlledInput name="customer.email" control={control} placeholder="Enter email" />
        </FormItem>
        <FormItem label="Address">
          <ControlledInput name="customer.address.line1" control={control} placeholder="Enter address" />
        </FormItem>
        <FormItem label="Card">
          <ControlledCard name="card" control={control} />
        </FormItem>
        <FormItem label="Payment Terms">
          <ControlledCheckBoxGroup name="paymentTerms" control={control} options={PAYMENT_TERM_OPTIONS} vertical />
        </FormItem>
      </Form>
    </Modal>
  );
};

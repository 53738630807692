import { TOKENS } from 'src/design';
import { HTMLAttributes } from 'react';
import styled, { CSSProperties } from 'styled-components';

export type FlexGap = keyof typeof TOKENS['spacing'];

export type FlexProps = {
  direction?: CSSProperties['flexDirection'];
  wrap?: CSSProperties['flexWrap'];
  justify?: CSSProperties['justifyContent'];
  align?: CSSProperties['alignItems'];
  gap?: FlexGap | (string & {});
  width?: string;
  height?: string;
  minHeight?: string;
  clickable?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${(props) => (props.direction ? `flex-direction: ${props.direction};` : '')}
  ${(props) => (props.wrap ? `flex-wrap: ${props.wrap};` : '')}
  ${(props) => (props.justify ? ` justify-content: ${props.justify};` : '')}
  ${(props) => (props.align ? `align-items: ${props.align}; ` : '')}
  ${(props) => (props.gap ? `gap: ${TOKENS.spacing[props.gap] ?? props.gap}; ` : '')}
  ${(props) => (props.width ? `width: ${props.width};` : '')}
  ${(props) => (props.height ? `height: ${props.height};` : '')}
  ${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : '')}
  ${(props) => (props.clickable ? 'cursor: pointer;' : '')}
`;

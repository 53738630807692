import { format } from 'date-fns';
import { orderBy, take } from 'lodash';
import { useMemo } from 'react';
import { TOKENS } from 'src/design';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { OverviewFormValues } from './Overview';
import { Flex, Grid, Spacing, Text } from 'src/components/Layout';
import { Loading } from 'src/components/Loading';
import { calcWithMarkup, formatAmount, formatPercentage } from 'src/utils';
import { CreativePreview } from 'src/components/CreativePreview';
import { Empty } from 'src/components/Empty';
import { Link } from 'react-router-dom';

const { useCreativeReportsQuery } = growthApi;

const MAX_COUNT = 3;

type ChartCreativesProps = OverviewFormValues & {
  markup?: number;
};

export const ChartCreatives = (props: ChartCreativesProps) => {
  const { timeRange, dateFrom, dateTo, agencyId, advertiserId, markup } = props;

  const { data, isFetching } = useCreativeReportsQuery({
    time_range: timeRange,
    date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
    date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
    agency_id: agencyId,
    advertiser_id: advertiserId,
  });

  const finalData = useMemo(() => {
    return take(
      orderBy(data?.data || [], (row) => Number(row['ctr'] === '-' ? 0 : row['ctr'] ?? 0), 'desc'),
      MAX_COUNT,
    );
  }, [data]);

  return (
    <Container>
      <Flex justify="space-between">
        <Text size="md" weight={700}>
          Top Ads
        </Text>
        <Link to="/activate/reporting?type=creatives">
          <Text size="sm" weight={600} color="primary">
            {'Creatives report >>'}
          </Text>
        </Link>
      </Flex>
      <Spacing size="xl" />
      {isFetching ? (
        <Loading height="25rem" />
      ) : finalData.length > 1 ? (
        <Grid columns={3} gap="xl" width="100%">
          {finalData.map((row) => (
            <Flex key={row.adlib_id} direction="column" gap="sm">
              <Text size="xs" weight={600} ellipsis>
                {row.creative_name || '(No name)'}
              </Text>
              <Flex justify="space-between">
                <Text size="xs">Spent:</Text>
                <Text size="xs">{formatAmount(calcWithMarkup(row.total_spend, markup))}</Text>
              </Flex>
              <Flex justify="space-between">
                <Text size="xs">CTR:</Text>
                <Text size="xs">{formatPercentage(row.ctr)}</Text>
              </Flex>
              <CreativePreview creativeName={row.creative_name} advertiserId={advertiserId} />
            </Flex>
          ))}
        </Grid>
      ) : (
        <Empty height="25rem" />
      )}
    </Container>
  );
};

const Container = styled.div`
  background: white;
  padding: 1.6rem 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
  height: 32rem;
`;

import DatePicker from 'react-datepicker';
import { useController, UseControllerProps } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { TOKENS } from 'src/design';

type ControlledDatePickerProps<T> = UseControllerProps<T> & {
  placeholder?: string;
  showTimeSelect?: boolean;
  disabled?: boolean;
  width?: string;
};

export const ControlledDatePicker = <T,>(props: ControlledDatePickerProps<T>) => {
  const { placeholder, showTimeSelect, disabled, width, ...useControllerProps } = props;

  const {
    field: { value, onChange },
  } = useController(useControllerProps);

  return (
    <Container disabled={disabled} width={width}>
      <DatePicker
        selected={value as Date}
        onChange={(date) => onChange(date)}
        placeholderText={placeholder}
        dateFormat={showTimeSelect ? 'MM/dd/yyyy h:mm aa' : 'MM/dd/yyyy'}
        showTimeSelect={showTimeSelect}
        disabled={disabled}
      />
    </Container>
  );
};

const Container = styled.div<{ disabled?: boolean; width?: string }>`
  width: ${(props) => props.width ?? '100%'};
  box-sizing: border-box;
  background: ${(props) => (props.disabled ? '#ddd' : 'white')};
  display: flex;
  align-items: center;
  padding: 1.2rem;
  border: solid 0.1rem ${TOKENS.color.grayLighter};
  border-radius: 1.2rem;
  box-shadow: ${TOKENS.shadow.default};

  input {
    flex-grow: 1;
    padding: 0 0.6rem;
    border: none;
    outline: none;
    width: 100%;
    background: ${(props) => (props.disabled ? '#ddd' : 'white')};

    ::placeholder {
      color: ${TOKENS.color.gray};
    }
  }
`;

export const TOKENS = {
  responsive: {
    desktop: '62.5%',
    mobile: '50%',
    width: '1024px',
  },
  color: {
    primary: '#00A57C',
    primaryDeep: '#008866',
    highlight: '#F1B532',
    gray: '#9CA3AF',
    grayLight: '#4B5563',
    grayLighter: '#EDF2F6',
    bgGray: '#F6F7FA',
    success: '#00A57C',
    error: '#dc3545',
    warn: '#F1B432',
    info: '#0dcaf0',
    text: '#414349',
    white: '#FFFFFF',
    inputIcon: '#cbd5e0',
  },
  spacing: {
    xxs: '0.2rem',
    xs: '0.4rem',
    sm: '0.6rem',
    md: '1.2rem',
    lg: '1.6rem',
    xl: '2.4rem',
    xxl: '3.6rem',
  },
  fontSize: {
    xs: '1.2rem',
    sm: '1.4rem',
    md: '1.6rem',
    lg: '1.8rem',
    xl: '2.4rem',
    xxl: '3.6rem',
  },
  fontFamily: {
    montserrat: '"Montserrat",sans-serif',
  },
  shadow: {
    default: '0 1.2rem 3.6rem 0 rgb(0, 0, 0, 0.02)',
  },
};

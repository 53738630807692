import { Control } from 'react-hook-form';
import { ControlledTextArea, FormItem } from 'src/components/Form';
import { Flex, Grid, Spacing, Text } from 'src/components/Layout';
import { useRole } from 'src/hooks';
import { PixelFormValues } from './PixelEdit';

type PixelCodesProps = {
  control: Control<PixelFormValues>;
};

export const PixelCodes = (props: PixelCodesProps) => {
  const { control } = props;

  const { isAdmin } = useRole();

  if (!isAdmin) return null;

  return (
    <FormItem label="Pixel Code (only for admin)" required>
      <Text size="sm" color="error">
        The pixel code format we supported: &#60;script&#62;xxx&#60;/script&#62;, &#60;script
        src="xxx"&#62;&#60;/script&#62;, &#60;img src="xxx"&#62;, others will be ignored.
      </Text>
      <Spacing />
      <Grid columns={4} gap="md">
        <Flex direction="column" gap="sm">
          <Text size="sm" weight={500}>
            Adlib Pixel Code
          </Text>
          <ControlledTextArea
            name="codes.adlib"
            control={control}
            placeholder="Enter Adlib pixel code here."
            rows={8}
          />
        </Flex>
        <Flex direction="column" gap="sm">
          <Text size="sm" weight={500}>
            Stackadapt Pixel Code
          </Text>
          <ControlledTextArea
            name="codes.stackadapt"
            control={control}
            placeholder="Enter Stackadapt pixel code here."
            rows={8}
          />
        </Flex>
        <Flex direction="column" gap="sm">
          <Text size="sm" weight={500}>
            Beeswax Pixel Code
          </Text>
          <ControlledTextArea
            name="codes.beeswax"
            control={control}
            placeholder="Enter Beeswax pixel code here."
            rows={8}
          />
        </Flex>
        <Flex direction="column" gap="sm">
          <Text size="sm" weight={500}>
            IQM Pixel Code
          </Text>
          <ControlledTextArea name="codes.iqm" control={control} placeholder="Enter IQM pixel code here." rows={8} />
        </Flex>
      </Grid>
    </FormItem>
  );
};

import { ControlledSelectOption } from 'src/components/Form';

export const METRIC_OPTIONS: ControlledSelectOption[] = [
  { label: 'Impressions', value: 'impressions' },
  { label: 'Clicks', value: 'clicks' },
  { label: 'Video Views', value: 'video_views' },
  { label: 'Video Completions', value: 'video_complete_views' },
  { label: 'Listens', value: 'audio_listens' },
  { label: 'Completed Listens', value: 'audio_complete_listens' },
  { label: 'CTR', value: 'ctr' },
  { label: 'CPM', value: 'cpm' },
  { label: 'CPC', value: 'cpc' },
  { label: 'CPV', value: 'cpv' },
  { label: 'CPCV', value: 'cpcv' },
  { label: 'CPL', value: 'cpl' },
  { label: 'CPCL', value: 'cpcl' },
  { label: 'Conversions', value: 'total_conversions' },
  { label: 'CPA', value: 'cpa' },
  { label: 'Total Spend', value: 'total_spend' },
  { label: 'None', value: undefined },
];

export const AUCTION_METRIC_OPTIONS: ControlledSelectOption[] = [
  { label: 'Matched Bid Opportunities', value: 'matched_bid_opportunities' },
  { label: 'Bids', value: 'bids' },
  { label: 'Bid Rate', value: 'bid_rate' },
  { label: 'Wins', value: 'wins' },
  { label: 'Win Rate', value: 'win_rate' },
];

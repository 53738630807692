import { TOKENS } from 'src/design';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: ${TOKENS.fontFamily.montserrat};
    font-size: ${TOKENS.responsive.desktop};
  }

  body {
    margin: 0;
    font-size: ${TOKENS.fontSize.md};
    min-width: ${TOKENS.responsive.width};
    color: ${TOKENS.color.text};
  }

  a {
    text-decoration: none;
    color: unset;
  }

  // fix date picker cover issue
  .react-datepicker-popper {
    z-index:  200;
  }

  // fix date picker size issue
  .react-datepicker {
    zoom: 140%;
  }

  // avoid apex charts toolbar covered data picker
  .apexcharts-toolbar {
    z-index: unset;
  }

  // remove -/+ in number input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

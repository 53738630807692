import { differenceInDays, format, min, subDays } from 'date-fns';
import { flatten, keyBy } from 'lodash';
import { useForm } from 'react-hook-form';
import { Column, DataTable, DataTableCellLoading, DataTableExport } from 'src/components/DataTable';
import { ControlledInput } from 'src/components/Form';
import { Flex, Spacing } from 'src/components/Layout';
import { TOKENS } from 'src/design';
import { useDataTable, useDeepEffect } from 'src/hooks';
import { growthApi } from 'src/services';
import {
  calcWithMarkupNumber,
  formatAmount,
  formatOnlyDate,
  formatPercentageWithPrecision,
  formatReportingId,
} from 'src/utils';
import styled from 'styled-components';
import { ReportingFormValues } from './Reporting';
import { useCallback, useState } from 'react';
import { ReportingInfo } from 'src/components/ReportingInfo';

const { useCampaignPerformanceQuery, useLazyCampaignPerformanceBatchQuery } = growthApi;

type ReportingPacingProps = Pick<
  ReportingFormValues,
  'timeRange' | 'dateFrom' | 'dateTo' | 'agencyId' | 'advertiserId' | 'campaignId'
> & {
  markup?: number;
};

type ReportingPacingFormValues = {
  search?: string;
};

export const ReportingPacing = (props: ReportingPacingProps) => {
  const { agencyId, advertiserId, markup } = props;

  const { watch, control } = useForm<ReportingPacingFormValues>();
  const values = watch();
  const isNotSelectAgencyAdvertiser = !agencyId || !advertiserId;

  const { data, isFetching, error } = useCampaignPerformanceQuery(
    {
      agency_id: agencyId,
      advertiser_id: advertiserId,
      time_range: 'all_time',
      breakout: 'campaign',
      with_campaign: true,
    },
    { skip: isNotSelectAgencyAdvertiser },
  );
  const { data: yesterdayData, isFetching: isYesterdayFetching } = useCampaignPerformanceQuery(
    {
      agency_id: agencyId,
      advertiser_id: advertiserId,
      time_range: 'yesterday',
      date_from: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
      date_to: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
      breakout: 'campaign',
    },
    { skip: isNotSelectAgencyAdvertiser },
  );
  const [lazyCampaignPerformanceBatch] = useLazyCampaignPerformanceBatchQuery();
  const [flightData, setFlightData] = useState<any[]>([]);
  const [flightIsFetching, setFlightIsFetching] = useState(false);

  const getYesterdaySpend = useCallback(
    (row: any) => {
      const yesterdayByName = keyBy(yesterdayData?.data, 'campaign_name');
      return calcWithMarkupNumber(yesterdayByName[row.campaign_name]?.total_spend, markup);
    },
    [markup, yesterdayData?.data],
  );

  const getFlightSpend = useCallback(
    (row: any) => {
      const flightById = keyBy(flightData, 'campaign_name');
      return calcWithMarkupNumber(flightById[row.campaign_name]?.total_spend, markup);
    },
    [flightData, markup],
  );

  const isMissingCampaign = useCallback((row: any) => {
    return !row.campaign;
  }, []);

  const isCampaignEnd = useCallback((row: any) => {
    return new Date().valueOf() > new Date(row.campaign.active_flight_end_date).valueOf();
  }, []);

  const getDailyDifference = useCallback(
    (row: any) => {
      return (
        getYesterdaySpend(row) -
        row.campaign.active_flight_budget /
          differenceInDays(
            new Date(row.campaign.active_flight_end_date),
            new Date(row.campaign.active_flight_start_date),
          )
      );
    },
    [getYesterdaySpend],
  );

  const { dataTableProps, dataTableRef } = useDataTable({
    idKey: 'adlib_id',
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['campaign_name'],
    defaultSort: {
      key: 'campaign.active_flight_start_date',
      direction: 'desc',
    },
  });

  useDeepEffect(() => {
    (async () => {
      if (dataTableProps.visibleData.length === 0) {
        return;
      }
      const inputs = [];
      dataTableProps.visibleData.forEach((row) => {
        if (!row.campaign) {
          return;
        }
        const startDate = formatOnlyDate(row.campaign.active_flight_start_date);
        const endDate = formatOnlyDate(row.campaign.active_flight_end_date);
        inputs.push({
          agency_id: agencyId,
          advertiser_id: advertiserId,
          campaign_id: JSON.stringify(row.campaign_ids),
          time_range: 'custom',
          date_from: startDate,
          date_to: endDate,
          breakout: 'campaign',
        });
      });
      setFlightIsFetching(true);
      const flightData = await lazyCampaignPerformanceBatch({ inputs }).unwrap();
      setFlightData(flatten(flightData.data));
      setFlightIsFetching(false);
    })();
  }, [dataTableProps.visibleData]);

  const columns: Column[] = [
    {
      header: 'Campaign ID',
      accessor: 'campaign_ids',
      render: formatReportingId,
    },
    {
      header: 'Campaign Name',
      accessor: 'campaign_name',
      sortable: true,
    },
    {
      header: 'Start Date',
      accessor: 'campaign.active_flight_start_date',
      sortable: true,
      render: formatOnlyDate,
    },
    {
      header: 'End Date',
      accessor: 'campaign.active_flight_end_date',
      sortable: true,
      render: formatOnlyDate,
    },
    {
      header: 'Flight duration',
      accessor: 'flight_duration',
      render: (_, row) => {
        if (isMissingCampaign(row)) {
          return '-';
        }
        return differenceInDays(
          new Date(row.campaign.active_flight_end_date),
          new Date(row.campaign.active_flight_start_date),
        );
      },
    },
    {
      header: 'Days completed',
      accessor: 'days_completed',
      render: (_, row) => {
        if (isMissingCampaign(row)) {
          return '-';
        }
        return differenceInDays(
          min([new Date(), new Date(row.campaign.active_flight_end_date)]),
          new Date(row.campaign.active_flight_start_date),
        );
      },
    },
    {
      header: 'Budget',
      accessor: 'campaign.active_flight_budget',
      render: formatAmount,
    },
    {
      header: 'Daily Spend Target',
      accessor: 'daily_spend_target',
      render: (_, row) => {
        if (isMissingCampaign(row)) {
          return '-';
        }
        return formatAmount(
          row.campaign.active_flight_budget /
            differenceInDays(
              new Date(row.campaign.active_flight_end_date),
              new Date(row.campaign.active_flight_start_date),
            ),
        );
      },
    },
    {
      header: 'Spend Yesterday',
      accessor: 'spend_yesterday',
      render: (_, row: any) => {
        if (isMissingCampaign(row)) {
          return '-';
        }
        if (isCampaignEnd(row)) {
          return '-';
        }
        if (isYesterdayFetching) {
          return <DataTableCellLoading />;
        }
        return formatAmount(getYesterdaySpend(row));
      },
    },
    {
      header: 'Daily Difference',
      accessor: 'daily_difference',
      render: (_, row) => {
        if (isMissingCampaign(row)) {
          return '-';
        }
        if (isCampaignEnd(row)) {
          return '-';
        }
        if (isYesterdayFetching) {
          return <DataTableCellLoading />;
        }
        return formatAmount(getDailyDifference(row));
      },
    },
    {
      header: 'Total Spent',
      title: 'Actual spends till yesterday',
      accessor: 'total_spent',
      render: (_, row: any) => {
        if (isMissingCampaign(row)) {
          return '-';
        }
        if (flightIsFetching) {
          return <DataTableCellLoading />;
        }
        return formatAmount(getFlightSpend(row));
      },
    },
    {
      header: 'Total Difference',
      title: 'Est. Difference till end of campaign',
      accessor: 'total_difference',
      render: (_, row: any) => {
        if (isMissingCampaign(row)) {
          return '-';
        }
        if (flightIsFetching || isYesterdayFetching) {
          return <DataTableCellLoading />;
        }
        if (isCampaignEnd(row)) {
          return formatAmount(getFlightSpend(row) - row.campaign.active_flight_budget);
        } else {
          return formatAmount(
            getDailyDifference(row) * (differenceInDays(new Date(row.campaign.active_flight_end_date), new Date()) + 1),
          );
        }
      },
    },
    {
      header: 'Pacing',
      title: 'Est. budget consumption till end of campaign',
      accessor: 'pacing',
      render: (_, row) => {
        if (isMissingCampaign(row)) {
          return '-';
        }
        if (isYesterdayFetching || flightIsFetching) {
          return <DataTableCellLoading />;
        }
        if (isCampaignEnd(row)) {
          return formatPercentageWithPrecision((getFlightSpend(row) / row.campaign.active_flight_budget) * 100, 0);
        } else {
          return formatPercentageWithPrecision(
            ((getDailyDifference(row) *
              (differenceInDays(new Date(row.campaign.active_flight_end_date), new Date()) + 1) +
              Number(row.campaign.active_flight_budget)) /
              Number(row.campaign.active_flight_budget)) *
              100,
            0,
          );
        }
      },
    },
    {
      header: 'Setting',
      accessor: 'campaign.pacing',
      render: (value, row) => {
        if (isMissingCampaign(row)) {
          return '-';
        }
        return value;
      },
    },
    {
      header: 'Daily cap',
      accessor: 'campaign.budget_daily',
      render: (value, row) => {
        if (isMissingCampaign(row)) {
          return '-';
        }
        return formatAmount(value);
      },
    },
  ];

  if (isNotSelectAgencyAdvertiser) {
    return <ReportingInfo message="Please select agency and advertiser to see the report" />;
  }

  return (
    <TableContainer>
      <Flex gap="lg" align="center">
        <ControlledInput name="search" control={control} prefix="Search:" placeholder="Keyword" />
        <DataTableExport
          onExport={(type) => {
            dataTableRef?.current?.export(type, 'reporting-pacing');
          }}
        />
      </Flex>
      <Spacing size="lg" />
      <DataTable columns={columns} {...dataTableProps} scroll />
    </TableContainer>
  );
};

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;

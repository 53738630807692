import { Column, DataTable } from 'src/components/DataTable';
import { Action, Button, ControlledInput, Switch } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { Link } from 'react-router-dom';
import { growthApi } from 'src/services';
import { formatAmount, formatOnlyDate } from 'src/utils';
import { useForm } from 'react-hook-form';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { Archive } from 'src/components/Archive';
import { useDataTable, useRole, useUserAgencyAdvertiser } from 'src/hooks';
import { capitalize, pick, upperFirst } from 'lodash';
import { useEffect } from 'react';

const { useCampaignsQuery, useCreateOrUpdateCampaignMutation } = growthApi;

const OBJECTIVE_NAMES: any = {
  '1': 'Awareness',
  '2': 'Clicks & Traffic',
  '3': 'Video',
  '4': 'Conversions',
  '5': 'Audio',
};

type CampaignsFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  search?: string;
};

export const Campaigns = () => {
  const { canAccessAgency } = useRole();
  const { agencyId, advertiserId, changeAgencyAdvertiser } = useUserAgencyAdvertiser();
  const { watch, control, setValue } = useForm<CampaignsFormValues>({
    defaultValues: {
      agency_id: agencyId,
      advertiser_id: advertiserId,
    },
  });
  const values = watch();

  useEffect(() => {
    changeAgencyAdvertiser(values.agency_id, values.advertiser_id);
  }, [changeAgencyAdvertiser, values.agency_id, values.advertiser_id]);

  const { data, isFetching, error, refetch } = useCampaignsQuery({
    ...pick(values, ['agency_id', 'advertiser_id']),
    with_status: true,
    with_draft: true,
  });
  const [createOrUpdateCampaign] = useCreateOrUpdateCampaignMutation();
  const { dataTableProps, rowStatus, changeRowStatus } = useDataTable({
    idKey: 'adlib_id',
    data: data?.data,
    search: values.search,
    isLoading: isFetching,
    error,
    searchKeys: ['adlib_id', 't1_id', 'name', 'advertiser_name', 'start_date', 'end_date', 'effective_status'],
    defaultSort: {
      key: 'adlib_id',
      direction: 'desc',
    },
    sortNumberKeys: ['budget', 'status'],
    sortMapping: {
      adlib_id: (value) => {
        if (String(value).startsWith('draft-')) {
          // soft top of list when is draft
          return 1000000 + Number(value.replace('draft-', ''));
        } else {
          return Number(value);
        }
      },
    },
    onChangeRowStatus: async (id: any, field: string, value: any, row?: any) => {
      switch (field) {
        case 'status':
          await createOrUpdateCampaign({
            id,
            status: value ? 1 : 0,
            a: 'toggle-status',
            advertiser_id: row?.advertiser_id,
          }).unwrap();
          break;
      }
    },
  });

  const columns: Column[] = [
    {
      header: 'ID',
      accessor: 'adlib_id',
      sortable: true,
      render: (value, row) => (row.t1_id ? `${value} / ${row.t1_id}` : value),
    },
    { header: 'Name', accessor: 'name', width: '16rem', sortable: true },
    { header: 'Advertiser', accessor: 'advertiser_name', width: '10rem', sortable: true },
    { header: 'Objective', accessor: 'objective', render: (value) => OBJECTIVE_NAMES[value] ?? '-', sortable: true },
    {
      header: 'Budget',
      accessor: 'budget',
      render: (value, row) => {
        if (row.ongoing?.enabled) {
          if (['daily', 'weekly', 'monthly'].includes(row.ongoing.interval)) {
            return `${formatAmount(row.ongoing.interval_budget)} (${capitalize(row.ongoing.interval)})`;
          }
          if (['flights'].includes(row.ongoing.interval)) {
            const now = new Date();
            const currentFlight = row.ongoing.flights?.find(
              (flight: any) =>
                flight.budget &&
                flight.start_date &&
                flight.end_date &&
                now.valueOf() >= new Date(flight.start_date).valueOf() &&
                now.valueOf() <= new Date(flight.end_date).valueOf(),
            );
            return `${formatAmount(currentFlight?.budget)} (${capitalize(row.ongoing.interval)})`;
          }
          return '-';
        } else {
          return formatAmount(value);
        }
      },
      sortable: true,
    },
    { header: 'Start Date', accessor: 'start_date', sortable: true, render: formatOnlyDate },
    {
      header: 'End Date',
      accessor: 'end_date',
      sortable: true,
      render: (value, row) => {
        if (row.ongoing?.enabled) {
          return '-';
        } else {
          return formatOnlyDate(value);
        }
      },
    },
    { header: 'Status', accessor: 'effective_status', render: (value) => upperFirst(value), sortable: true },
    {
      header: 'On/Off',
      accessor: 'status',
      render: (value, row) => {
        if (row.disable_status) {
          return '-';
        }
        return (
          <Switch
            value={rowStatus[row.adlib_id]?.['status'] ?? Boolean(Number(value))}
            onChange={(status) => changeRowStatus(row.adlib_id, 'status', status, row)}
          />
        );
      },
      sortable: true,
    },
    {
      header: '',
      accessor: 'action',
      width: '3rem',
      render: (_, row) => {
        const type = `campaign${row.third_party ? `_${row.third_party}` : ''}`;
        return (
          <Flex gap="md">
            <Link to={`/activate/campaigns/edit?copy=${row.adlib_id}&advertiser_id=${row.advertiser_id}`}>
              <Action>Copy</Action>
            </Link>
            <Link to={`/activate/campaigns/edit?id=${row.adlib_id}&advertiser_id=${row.advertiser_id}`}>
              <Action>Edit</Action>
            </Link>
            <Archive type={type} typeId={row.adlib_id} onSuccess={() => refetch()} />
          </Flex>
        );
      },
    },
  ];

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Campaigns
          </Text>
          {canAccessAgency && (
            <>
              <AgencySelect
                prefix="Agency:"
                name="agency_id"
                control={control}
                withAll
                width="22rem"
                onValueChange={() => {
                  setValue('advertiser_id', undefined);
                }}
              />
              <AdvertiserSelect
                agencyId={values.agency_id}
                prefix="Advertiser:"
                name="advertiser_id"
                control={control}
                withAll
                width="22rem"
              />
            </>
          )}
          <ControlledInput width="22rem" prefix="Search:" name="search" control={control} placeholder="Keyword" />
        </Flex>
        <Flex gap="xxl">
          <Link to={`/activate/campaigns/new?advertiser_id=${values.advertiser_id}`}>
            <Button width="16rem" shadow>
              NEW CAMPAIGN
            </Button>
          </Link>
        </Flex>
      </Flex>
      <Spacing size="xl" />
      <DataTable columns={columns} {...dataTableProps} />
    </PageTemplate>
  );
};

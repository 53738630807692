import { Column, DataTable } from 'src/components/DataTable';
import { Button, ControlledInput, Switch } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { Link } from 'react-router-dom';
import { growthApi } from 'src/services';
import { useForm } from 'react-hook-form';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { Archive } from 'src/components/Archive';
import { useDataTable, useRole, useUserAgencyAdvertiser } from 'src/hooks';
import { useEffect } from 'react';
import { pick } from 'lodash';
import { formatOnlyDate } from 'src/utils';

const { useCreativesQuery, useCreateOrUpdateCreativeMutation, useLazyUrlQuery } = growthApi;

const TYPE_NAMES: any = {
  file: 'Banner',
  tag: 'Tag',
  native: 'Native',
  audio: 'Audio',
  video: 'Video',
  'magic ad': 'Magic Ad',
  html5: 'Html5',
};

type CreativesFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  search?: string;
};

export const Creatives = () => {
  const { canAccessAgency } = useRole();
  const { agencyId, advertiserId, changeAgencyAdvertiser } = useUserAgencyAdvertiser();
  const [url] = useLazyUrlQuery();
  const { watch, control, setValue } = useForm<CreativesFormValues>({
    defaultValues: {
      agency_id: agencyId,
      advertiser_id: advertiserId,
    },
  });
  const values = watch();

  useEffect(() => {
    changeAgencyAdvertiser(values.agency_id, values.advertiser_id);
  }, [changeAgencyAdvertiser, values.agency_id, values.advertiser_id]);

  const { data, isFetching, error, refetch } = useCreativesQuery({
    ...pick(values, ['agency_id', 'advertiser_id']),
    with_status: true,
  });
  const [createOrUpdateCreative] = useCreateOrUpdateCreativeMutation();
  const { dataTableProps, rowStatus, changeRowStatus } = useDataTable({
    idKey: 'adlib_id',
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['adlib_id', 'id', 'name', 'advertiser_name', 'created_on', 'effective_status'],
    defaultSort: {
      key: 'adlib_id',
      direction: 'desc',
    },
    sortNumberKeys: ['adlib_id', 'status'],
    onChangeRowStatus: async (id: any, field: string, value: any, row?: any) => {
      switch (field) {
        case 'status':
          await createOrUpdateCreative({
            id,
            status: value ? 1 : 0,
            a: 'toggle-status',
            advertiser_id: row?.advertiser_id,
          }).unwrap();
          break;
      }
    },
  });

  const columns: Column[] = [
    {
      header: 'ID',
      accessor: 'adlib_id',
      sortable: true,
      render: (value, row) => (row.id && row.id !== value && row.id !== '---' ? `${value} / ${row.id}` : value),
    },
    { header: 'Name', accessor: 'name', width: '15rem', sortable: true },
    { header: 'Advertiser', accessor: 'advertiser_name', width: '12rem', sortable: true },
    { header: 'Type', accessor: 'type', render: (value) => (value ? TYPE_NAMES[value] : ''), sortable: true },
    { header: 'Created At', accessor: 'created_on', sortable: true, render: formatOnlyDate },
    {
      header: 'Status',
      accessor: 'effective_status',
      sortable: true,
      render: (value: string, row: any) => {
        return `${value}${row.effective_status_notes ? ` (${row.effective_status_notes})` : ''}`;
      },
    },
    {
      header: 'Preview',
      accessor: 'preview_url',
      render: (previewUrl) => {
        return previewUrl ? (
          <Text
            color="primary"
            size="xs"
            weight={600}
            onClick={async () => {
              if (previewUrl.startsWith('http')) {
                window.open(previewUrl, '_blank');
              } else {
                const urlResult = await url({ url: previewUrl }).unwrap();
                window.open(urlResult.data, '_blank');
              }
            }}
            clickable
          >
            Click Here
          </Text>
        ) : null;
      },
      width: '8rem',
    },
    {
      header: 'On/Off',
      accessor: 'status',
      render: (value, row) => {
        if (row.disable_status) {
          return '-';
        }
        return (
          <Switch
            value={rowStatus[row.adlib_id]?.['status'] ?? Boolean(Number(value))}
            onChange={(status) => changeRowStatus(row.adlib_id, 'status', status, row)}
          />
        );
      },
      sortable: true,
    },
    {
      header: '',
      accessor: 'action',
      width: '3rem',
      render: (_, row) => {
        const type = `creative${row.third_party ? `_${row.third_party}` : ''}`;
        return (
          <Flex gap="md">
            <Link to={`/activate/creatives/edit?copy=${row.adlib_id}&advertiser_id=${row.advertiser_id}`}>
              <Text size="xs" weight={600} color="primary" clickable>
                Copy
              </Text>
            </Link>
            <Link to={`/activate/creatives/edit?id=${row.adlib_id}&advertiser_id=${row.advertiser_id}`}>
              <Text size="xs" weight={600} color="primary" clickable>
                Edit
              </Text>
            </Link>
            <Archive type={type} typeId={row.adlib_id} onSuccess={() => refetch()} />
          </Flex>
        );
      },
    },
  ];

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Creatives
          </Text>
          {canAccessAgency && (
            <>
              <AgencySelect
                prefix="Agency:"
                name="agency_id"
                control={control}
                withAll
                width="22rem"
                onValueChange={() => {
                  setValue('advertiser_id', undefined);
                }}
              />
              <AdvertiserSelect
                agencyId={values.agency_id}
                prefix="Advertiser:"
                name="advertiser_id"
                control={control}
                withAll
                width="22rem"
              />
            </>
          )}
          <ControlledInput width="22rem" prefix="Search: " name="search" control={control} placeholder="Keyword" />
        </Flex>
        <Flex gap="xxl">
          <Link to={`/activate/creatives/new?advertiser_id=${values.advertiser_id}`}>
            <Button width="16rem" shadow>
              NEW CREATIVE
            </Button>
          </Link>
        </Flex>
      </Flex>
      <Spacing size="xl" />
      <DataTable columns={columns} {...dataTableProps} />
    </PageTemplate>
  );
};

import { useEffect } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { ControlledInput, ControlledSelect, ControlledSelectOption, FormItem } from 'src/components/Form';
import { Flex, FlexItem } from 'src/components/Layout';
import { AudienceRule } from 'src/types';

export const CUSTOM_RULE_GEO_FENCE = 13;

enum GeoFenceType {
  LatLong = 1,
  Address = 2,
  Keyword = 3,
}

const GEO_FENCE_TYPE_OPTIONS: ControlledSelectOption[] = [
  {
    label: 'Lat Long',
    value: GeoFenceType.LatLong,
  },
  {
    label: 'Address',
    value: GeoFenceType.Address,
  },
  {
    label: 'Keyword',
    value: GeoFenceType.Keyword,
  },
];

const GEO_FENCE_UNIT_OPTIONS: ControlledSelectOption[] = [
  {
    label: 'Miles',
    value: 'miles',
  },
  {
    label: 'KM',
    value: 'km',
  },
];

type GeoFenceFormValues = {
  advertiserId?: number;
  type?: GeoFenceType;
  value?: string;
  radius?: number;
  unit?: 'miles' | 'km';
};

type CustomRuleTypeGeoFenceProps = {
  rule?: AudienceRule;
  agencyId?: number;
  advertiserId?: number;
};

export const CustomRuleTypeGeoFence = (props: CustomRuleTypeGeoFenceProps) => {
  const { rule, advertiserId } = props;

  const { control, watch, reset } = useForm<GeoFenceFormValues>({
    defaultValues: {
      advertiserId,
      radius: 1,
      unit: 'miles',
    },
  });
  const { setValue: setParentValue } = useFormContext();
  const values = watch();

  useEffect(() => {
    if (rule) {
      reset({
        advertiserId: rule.advertiser_id,
        type: rule.rule_sub_type_id,
        value: rule.rule_value,
        radius: rule.rule_value_additional,
        unit: rule.rule_sub_type_unit,
      });
    }
  }, [rule, reset]);

  useEffect(() => {
    setParentValue('advertiser_id', values.advertiserId);
    setParentValue('rule_sub_type_id', values.type);
    setParentValue('rule_value', values.value);
    setParentValue('rule_value_additional', values.radius);
    setParentValue('rule_sub_type_unit', values.unit);
    setParentValue('rule_value_name', `${values.value} (${values.radius ?? ''} ${values.unit})`);
  }, [setParentValue, values.advertiserId, values.value, values.type, values.unit, values.radius]);

  return (
    <FormItem label="Value">
      <Flex direction="column" gap="md">
        <ControlledSelect name="type" control={control} options={GEO_FENCE_TYPE_OPTIONS} placeholder="Select type" />
        {Boolean(values.type) && (
          <Flex gap="md">
            <FlexItem grow={1}>
              {values.type === GeoFenceType.LatLong && (
                <ControlledInput name="value" control={control} placeholder="40.748440, -73.985664" />
              )}
              {values.type === GeoFenceType.Address && (
                <ControlledInput name="value" control={control} placeholder="123 Main St, New York, NY 10001" />
              )}
              {values.type === GeoFenceType.Keyword && (
                <ControlledInput name="value" control={control} placeholder="keyword1, keyword2" />
              )}
            </FlexItem>
            <ControlledInput type="number" width="10rem" name="radius" control={control} placeholder="10.00" />
            <ControlledSelect width="10rem" name="unit" control={control} options={GEO_FENCE_UNIT_OPTIONS} />
          </Flex>
        )}
      </Flex>
    </FormItem>
  );
};

import { useRole } from 'src/hooks';
import { useWhiteLabelTheme } from './useWhiteLabelTheme';
import { growthApi } from 'src/services';

const { useMarkupQuery } = growthApi;

export const useMarkup = (agencyId: number, advertiserId?: number) => {
  const { whiteLabelId } = useRole();
  const { data: markupData } = useMarkupQuery({
    agency_id: agencyId,
    advertiser_id: advertiserId,
    white_label_id: whiteLabelId,
  });
  const theme = useWhiteLabelTheme();

  return markupData?.data ?? theme.markup;
};

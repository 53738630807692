import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { AudienceSelect } from 'src/components/AudienceSelect.tsx';
import { CreativeSelect } from 'src/components/CreativeSelect';
import {
  ControlledInput,
  ControlledRadioGroup,
  ControlledRadioGroupOption,
  ControlledSelect,
  Form,
  FormItem,
} from 'src/components/Form';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { FormAction } from 'src/components/Form/FormAction';
import { Flex, FlexItem, Grid, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { TOKENS } from 'src/design';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { getApiErrorMessage, TIMEZONE_OPTIONS, transformObject, TransformType } from 'src/utils';
import { useRole, useToast, useUserAgencyAdvertiser } from 'src/hooks';
import { CampaignLaunch } from './CampaignLaunch';
import { cloneDeep, isNaN } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { growthApi } from 'src/services';
import { PixelSelect } from 'src/components/PixelSelect';
import { Required } from 'src/components/Form';
import { DraftSupport } from 'src/components/DraftSupport';
import { addDays, differenceInDays, format, startOfDay } from 'date-fns';
import { AgencySelect } from 'src/components/AgencySelect';
import { Icon, IconProps } from 'src/components/Icon';
import { ControlledSwitch } from 'src/components/Form';
import { nanoid } from 'nanoid';

const { useCampaignsQuery, useCreateOrUpdateCampaignMutation } = growthApi;

enum CampaignObjective {
  Awareness = 1,
  ClickAndTraffic = 2,
  Video = 3,
  Conversions = 4,
  Audio = 5,
}

enum CampaignBillingType {
  CTR = 'ctr',
  ViewabilityRate = 'viewability_rate',
  CPC = 'cpc',
  CPV = 'cpv',
  CPCV = 'cpcv',
  VCR = 'vcr',
  CPA = 'cpa',
  ROI = 'roi',
  CPL = 'cpl',
  CPCL = 'cpcl',
}

enum CampaignPacing {
  SpendEvenly = 'evenly',
  SpendASAP = 'asap',
}

enum CampaignEngagement {
  NoEngagement,
  GrowthChannelPixel,
}

enum CampaignStep {
  Organize = 1,
  Objective = 2,
  Launch = 3,
}

type CampaignOngoingFlight = {
  id: string;
  budget?: number;
  start_date?: Date;
  end_date?: Date;
};

const CAMPAIGN_STEP_TITLES: Record<CampaignStep, string> = {
  [CampaignStep.Organize]: 'Organize your campaign',
  [CampaignStep.Objective]: 'Campaign Objective',
  [CampaignStep.Launch]: 'Launch',
};

const CAMPAIGN_BILLING_TYPE_OPTIONS = [
  {
    label: 'CTR',
    value: CampaignBillingType.CTR,
  },
  {
    label: 'Viewability Rate',
    value: CampaignBillingType.ViewabilityRate,
  },
  {
    label: 'CPC',
    value: CampaignBillingType.CPC,
  },
  {
    label: 'CPV',
    value: CampaignBillingType.CPV,
  },
  {
    label: 'CPCV',
    value: CampaignBillingType.CPCV,
  },
  {
    label: 'VCR',
    value: CampaignBillingType.VCR,
  },
  {
    label: 'CPA',
    value: CampaignBillingType.CPA,
  },
  {
    label: 'ROI',
    value: CampaignBillingType.ROI,
  },
  {
    label: 'CPL',
    value: CampaignBillingType.CPL,
  },
  {
    label: 'CPCL',
    value: CampaignBillingType.CPCL,
  },
];

const CAMPAIGN_ONGOING_INTERVAL_OPTIONS = [
  {
    label: 'Daily',
    value: 'daily',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Flights',
    value: 'flights',
  },
];

const CAMPAIGN_ONGOING_INTERVAL_DURING = {
  daily: 1,
  weekly: 7,
  monthly: 30,
};

const CAMPAIGN_FREQUENCY_INTERVAL_OPTIONS = [
  {
    label: 'Hour',
    value: 'hour',
  },
  {
    label: 'Day',
    value: 'day',
  },
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: 'Month',
    value: 'month',
  },
];

const CAMPAIGN_OBJECTIVE_BILLING_TYPES: Record<CampaignObjective, CampaignBillingType[]> = {
  [CampaignObjective.Awareness]: [CampaignBillingType.CTR, CampaignBillingType.ViewabilityRate],
  [CampaignObjective.ClickAndTraffic]: [CampaignBillingType.CPC, CampaignBillingType.CTR],
  [CampaignObjective.Video]: [
    CampaignBillingType.CPV,
    CampaignBillingType.CPCV,
    CampaignBillingType.VCR,
    CampaignBillingType.CPC,
    CampaignBillingType.CTR,
    CampaignBillingType.ViewabilityRate,
  ],
  [CampaignObjective.Conversions]: [CampaignBillingType.CPA, CampaignBillingType.ROI],
  [CampaignObjective.Audio]: [CampaignBillingType.CPL, CampaignBillingType.CPCL],
};

const CAMPAIGN_OBJECTIVE: { title: string; value: CampaignObjective; icon: IconProps['type'] }[] = [
  {
    title: 'Awareness',
    value: CampaignObjective.Awareness,
    icon: 'users',
  },
  {
    title: 'Clicks & Traffic',
    value: CampaignObjective.ClickAndTraffic,
    icon: 'pixels',
  },
  {
    title: 'Video',
    value: CampaignObjective.Video,
    icon: 'video',
  },
  {
    title: 'Audio',
    value: CampaignObjective.Audio,
    icon: 'voice',
  },
  {
    title: 'Conversions',
    value: CampaignObjective.Conversions,
    icon: 'bag',
  },
];

const CAMPAIGN_PACING_OPTIONS: ControlledRadioGroupOption[] = [
  {
    label: 'Spend Evenly Through End Date',
    value: CampaignPacing.SpendEvenly,
  },
  {
    label: 'Spend ASAP',
    value: CampaignPacing.SpendASAP,
  },
];

const CAMPAIGN_ENGAGEMENT_OPTIONS: ControlledRadioGroupOption[] = [
  {
    label: 'No engagement tracking',
    value: CampaignEngagement.NoEngagement,
  },
  {
    label: 'Use Pixel',
    value: CampaignEngagement.GrowthChannelPixel,
  },
];

const CAMPAIGN_DEFAULT_ONGOING_FLIGHTS: CampaignOngoingFlight[] = [{ id: nanoid() }];

type CampaignFormValues = {
  id?: any;
  name?: string;
  agency_id?: number;
  advertiser_id?: number;
  objective?: CampaignObjective;
  creatives?: number[];
  audience_id?: number;
  status?: number;
  budget?: {
    amount?: number;
    amount_daily?: number;
    max_bid_cpm?: number;
    currency_code?: string;
    billing_type?: CampaignBillingType;
    billing_target?: number;
    billing_roi_avg_order_value?: number;
    pacing?: CampaignPacing;
    cpa_action?: number;
  };
  schedule?: {
    start_date?: Date;
    end_date?: Date;
    timezone?: number;
  };
  delivery?: {
    frequency_number?: number;
    frequency_days?: number;
    frequency_amount?: number;
    frequency_interval?: string;
  };
  ongoing?: {
    enabled?: boolean;
    interval?: string;
    interval_budget?: number;
    flights?: CampaignOngoingFlight[];
  };
  engagement?: CampaignEngagement;
  total_spend_all_time?: number;
  white_label_id?: number;
  is_draft?: boolean;
};

export const CampaignEdit = () => {
  const { canAccessAgency, whiteLabelId } = useRole();
  const { agencyId, advertiserId } = useUserAgencyAdvertiser();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const copy = searchParams.get('copy');
  const isDraft = String(id).startsWith('draft-') || String(copy).startsWith('draft-');
  const advertiserIdFromUrl = searchParams.get('advertiser_id');
  const queryStep = searchParams.get('step');
  const isNew = !id;
  const { data, isLoading: isDetailLoading } = useCampaignsQuery(
    { id: id || copy, advertiser_id: advertiserIdFromUrl },
    { skip: isNew && !copy },
  );
  const [step, setStep] = useState<CampaignStep>(queryStep ? Number(queryStep) : CampaignStep.Organize);
  const defaultValues = useMemo(
    () => ({
      agency_id: agencyId,
      advertiser_id: advertiserId,
      objective: CampaignObjective.Awareness,
      name: '',
      status: 1,
      budget: {
        pacing: CampaignPacing.SpendEvenly,
        billing_type: CampaignBillingType.CTR,
      },
      creatives: [],
      ongoing: {
        enabled: false,
        flights: CAMPAIGN_DEFAULT_ONGOING_FLIGHTS,
      },
      engagement: CampaignEngagement.NoEngagement,
      is_draft: false,
    }),
    [advertiserId, agencyId],
  );
  const { control, watch, setValue, handleSubmit, reset } = useForm<CampaignFormValues>({
    defaultValues,
  });
  const values = watch();
  const [createOrUpdateCampaign, { isLoading }] = useCreateOrUpdateCampaignMutation();
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();

  const enableDraft = !id || isDraft;
  const isCreate = isNew || isDraft;

  useEffect(() => {
    if (data?.data) {
      const campaign = cloneDeep(data.data);
      if (copy) {
        campaign.name = `${campaign.name} copy`;
        delete campaign.id;
      }
      if (isDraft) {
        reset({
          id,
          ...draftToFormValues(campaign),
          is_draft: false,
        });
        return;
      }
      const transformedData = transformObject(campaign, {
        id: TransformType.Number,
        agency_id: TransformType.Number,
        advertiser_id: TransformType.Number,
        name: TransformType.None,
        objective: TransformType.Number,
        audience_id: TransformType.Number,
        creatives: TransformType.NumberArray,
        billing_target_type: TransformType.None,
        billing_target_amount: TransformType.Number,
        billing_roi_avg_order_value: TransformType.Number,
        start_date: TransformType.Date,
        end_date: TransformType.Date,
        timezone: TransformType.Number,
        budget: TransformType.Number,
        budget_daily: TransformType.Number,
        max_bid_cpm: TransformType.Number,
        pacing: TransformType.None,
        frequency_cap: TransformType.Number,
        frequency_cap_interval_days: TransformType.Number,
        frequency_amount: TransformType.Number,
        frequency_interval: TransformType.None,
        cpa_action: TransformType.None,
        total_spend_all_time: TransformType.Number,
        ongoing: TransformType.None,
      });
      // handle date
      transformedData.ongoing?.flights?.forEach((flight: any) => {
        if (flight.start_date) {
          flight.start_date = new Date(flight.start_date);
        }
        if (flight.end_date) {
          flight.end_date = new Date(flight.end_date);
        }
      });
      reset({
        ...transformedData,
        budget: {
          billing_type: transformedData.billing_target_type,
          billing_target: transformedData.billing_target_amount,
          billing_roi_avg_order_value: transformedData.billing_roi_avg_order_value,
          amount: transformedData.budget,
          amount_daily: transformedData.budget_daily,
          max_bid_cpm: transformedData.max_bid_cpm,
          pacing: transformedData.pacing,
          cpa_action: transformedData.cpa_action,
        },
        schedule: {
          start_date: transformedData.start_date,
          end_date: transformedData.end_date,
          timezone: transformedData.timezone,
        },
        delivery: {
          frequency_number: transformedData.frequency_cap,
          frequency_days: transformedData.frequency_cap_interval_days,
          frequency_amount: transformedData.frequency_amount,
          frequency_interval: transformedData.frequency_interval,
        },
        engagement: transformedData.cpa_action
          ? CampaignEngagement.GrowthChannelPixel
          : CampaignEngagement.NoEngagement,
      });
    }
  }, [copy, data, id, isDraft, reset]);

  useEffect(() => {
    searchParams.set('step', String(step));
    setSearchParams(searchParams.toString(), { replace: true });
  }, [searchParams, setSearchParams, step]);

  const step1Disabled = useMemo(() => {
    if (!values.name) {
      return true;
    }
    if (isNew && !values.agency_id) {
      return true;
    }
    if (isNew && !values.advertiser_id) {
      return true;
    }
    if (!values.audience_id) {
      return true;
    }
    if (!values.creatives || values.creatives.length === 0) {
      return true;
    }
    return false;
  }, [isNew, values.advertiser_id, values.agency_id, values.audience_id, values.creatives, values.name]);

  const step2Validate = useCallback(() => {
    if (values.ongoing?.enabled) {
      if (!values.ongoing?.interval) {
        showErrorToast('Ongoing cycle is required ');
        return false;
      }
      if (['daily', 'weekly', 'monthly'].includes(values.ongoing.interval)) {
        if (!values.ongoing.interval_budget) {
          showErrorToast('Ongoing budget is required');
          return false;
        }
      }
      if (['flights'].includes(values.ongoing.interval)) {
        if (
          !values.ongoing?.flights ||
          values.ongoing.flights.length === 0 ||
          values.ongoing.flights.some((flight) => !flight.budget || !flight.start_date || !flight.end_date)
        ) {
          showErrorToast('Ongoing flights budget, start date, end date is required');
          return false;
        }
      }
    } else {
      if (!values.schedule?.start_date) {
        showErrorToast('Start date is required');
        return false;
      }
      if (!values.schedule?.end_date) {
        showErrorToast('End date is required');
        return false;
      }
      if (values.schedule.end_date.valueOf() < values.schedule.start_date.valueOf()) {
        showErrorToast('End date must be later than the start date');
        return false;
      }
      if (!values.budget?.amount) {
        showErrorToast('Total budget is required');
        return false;
      }
    }
    return true;
  }, [showErrorToast, values]);

  const onAddOngoingFlight = (index: number) => {
    const flights = cloneDeep(values.ongoing.flights);
    flights.splice(index + 1, 0, { id: nanoid() });
    setValue('ongoing.flights', flights);
  };

  const onRemoveOngoingFlight = (index: number) => {
    setValue(
      'ongoing.flights',
      values.ongoing.flights.filter((_, flightIndex) => flightIndex !== index),
    );
  };

  const ongoingFlights = useMemo(() => {
    const now = new Date();
    let currentFlight: CampaignOngoingFlight | undefined = undefined;
    let targetBudget = 0;
    values.ongoing?.flights?.forEach((flight) => {
      if (flight.budget && flight.start_date && flight.end_date) {
        if (now.valueOf() >= flight.start_date.valueOf() && now.valueOf() <= flight.end_date.valueOf()) {
          currentFlight = flight;
        }
        if (now.valueOf() >= flight.start_date.valueOf()) {
          targetBudget += Number(flight.budget);
        }
      }
    });
    if (!currentFlight) {
      currentFlight = values.ongoing?.flights?.[0];
    }
    if (!targetBudget) {
      targetBudget = Number(values.ongoing?.flights?.[0]?.budget);
    }
    return {
      currentFlight,
      targetBudget,
    };
  }, [values]);

  const dailyDue = useMemo(() => {
    if (values.ongoing?.enabled) {
      if (['daily', 'weekly', 'monthly'].includes(values.ongoing?.interval) && values.ongoing?.interval_budget) {
        return (values.ongoing?.interval_budget / CAMPAIGN_ONGOING_INTERVAL_DURING[values.ongoing?.interval]).toFixed(
          2,
        );
      }
      if (['flights'].includes(values.ongoing?.interval) && ongoingFlights.currentFlight) {
        return (
          ongoingFlights.currentFlight.budget /
          differenceInDays(ongoingFlights.currentFlight.end_date, ongoingFlights.currentFlight.start_date)
        ).toFixed(2);
      }
    } else {
      if (values.budget?.amount_daily) {
        return values.budget.amount_daily;
      } else if (values.budget?.amount && values.schedule?.start_date && values.schedule?.end_date) {
        return (values.budget?.amount / differenceInDays(values.schedule.end_date, values.schedule.start_date)).toFixed(
          2,
        );
      }
    }
    return 0;
  }, [ongoingFlights, values]);

  const draftToFormValues = (draft: any) => {
    const formValues = cloneDeep(draft);
    if (formValues.schedule?.start_date) {
      formValues.schedule.start_date = new Date(formValues.schedule.start_date);
    }
    if (formValues.schedule?.end_date) {
      formValues.schedule.end_date = new Date(formValues.schedule.end_date);
    }
    formValues.ongoing?.flights?.forEach((flight: any) => {
      if (flight.start_date) {
        flight.start_date = new Date(flight.start_date);
      }
      if (flight.end_date) {
        flight.end_date = new Date(flight.end_date);
      }
    });
    return formValues;
  };

  const onSubmit = async (values: CampaignFormValues) => {
    const data = cloneDeep(values);
    if (!id) {
      data.id = undefined;
    } else if (isNaN(Number(id))) {
      data.id = id;
    } else {
      data.id = Number(id);
    }
    // set white label id
    data.white_label_id = whiteLabelId;
    if (data.schedule?.start_date) {
      data.schedule.start_date = format(new Date(data.schedule.start_date), 'yyyy-MM-dd HH:mm') as any;
    }
    if (data.schedule?.end_date) {
      data.schedule.end_date = format(new Date(data.schedule.end_date), 'yyyy-MM-dd HH:mm') as any;
    }
    data.ongoing?.flights?.forEach((flight: any) => {
      if (flight.start_date) {
        flight.start_date = format(new Date(flight.start_date), 'yyyy-MM-dd HH:mm') as any;
      }
      if (flight.end_date) {
        flight.end_date = format(new Date(flight.end_date), 'yyyy-MM-dd HH:mm') as any;
      }
    });
    // handel ongoing
    if (data.ongoing?.enabled) {
      const today = startOfDay(new Date());
      // only can set start date when isNew or isDraft
      if (isCreate) {
        data.schedule.start_date = format(today, 'yyyy-MM-dd HH:mm') as any;
      }
      if (['daily', 'weekly', 'monthly'].includes(data.ongoing.interval)) {
        data.schedule.end_date = format(
          addDays(today, CAMPAIGN_ONGOING_INTERVAL_DURING[data.ongoing.interval]),
          'yyyy-MM-dd HH:mm',
        ) as any;
        data.budget.amount =
          Math.ceil(
            differenceInDays(new Date(data.schedule.end_date), new Date(data.schedule.start_date)) /
              CAMPAIGN_ONGOING_INTERVAL_DURING[data.ongoing.interval],
          ) * Number(data.ongoing.interval_budget ?? 0);
      }
      if (['flights'].includes(data.ongoing?.interval)) {
        data.schedule.end_date = format(ongoingFlights.currentFlight?.end_date ?? today, 'yyyy-MM-dd HH:mm') as any;
        data.budget.amount = ongoingFlights.targetBudget;
      }
      // remove daily cap when ongoing type is daily
      if (['daily'].includes(data.ongoing?.interval)) {
        delete data.budget.amount_daily;
      }
    }
    try {
      await createOrUpdateCampaign(data).unwrap();
      if (isNew) {
        showSuccessToast(data.is_draft ? 'Create campaign draft successfully' : 'Create campaign successfully');
        localStorage.removeItem('campaign_draft');
        navigate('/activate/campaigns');
      } else {
        showSuccessToast(data.is_draft ? 'Save campaign draft successfully' : 'Save campaign successfully');
        navigate('/activate/campaigns');
      }
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const draftDisabled = !values.agency_id || !values.advertiser_id;

  const onDraftSubmit = () => {
    onSubmit({
      ...values,
      is_draft: true,
    });
  };

  return (
    <PageTemplate isLoading={isDetailLoading}>
      {step === CampaignStep.Organize && (
        <Flex width="100%" minHeight="100%" justify="center" align="center" direction="column">
          <Text size="xxl" weight={700}>
            {CAMPAIGN_STEP_TITLES[step]}
          </Text>
          <Spacing size="6rem" />
          <OrganizeFormContainer>
            {canAccessAgency && isNew && (
              <>
                <Flex>
                  <Text size="sm" weight={600}>
                    Agency
                  </Text>
                  <Required />
                </Flex>
                <AgencySelect name="agency_id" control={control} />
                <Flex>
                  <Text size="sm" weight={600}>
                    Advertiser
                  </Text>
                  <Required />
                </Flex>
                <AdvertiserSelect
                  agencyId={values.agency_id}
                  name="advertiser_id"
                  control={control}
                  allowNew
                  onValueChange={() => {
                    setValue('creatives', undefined);
                  }}
                />
              </>
            )}
            <Flex>
              <Text size="sm" weight={600}>
                Campaign name
              </Text>
              <Required />
            </Flex>
            <ControlledInput name="name" control={control} placeholder="Enter campaign name" />
            <Flex>
              <Text size="sm" weight={600}>
                Audience
              </Text>
              <Required />
            </Flex>
            <AudienceSelect
              name="audience_id"
              agencyId={values.agency_id}
              advertiserId={values.advertiser_id}
              control={control}
              allowNew
              newParams={{ from: 'campaign' }}
            />
            <Flex>
              <Text size="sm" weight={600}>
                Creatives
              </Text>
              <Required />
            </Flex>
            <CreativeSelect
              multiple
              agencyId={values.agency_id}
              advertiserId={values.advertiser_id}
              name="creatives"
              control={control}
              allowNew
              newParams={{ from: 'campaign' }}
            />
          </OrganizeFormContainer>
          <Spacing size="xxl" />
          <FormAction
            onSubmit={() => setStep(CampaignStep.Objective)}
            submitText="PROCEED"
            disabled={step1Disabled}
            enableDraft={enableDraft}
            isSubmitting={isLoading}
            onDraftSubmit={onDraftSubmit}
            draftDisabled={draftDisabled}
          />
        </Flex>
      )}
      {step === CampaignStep.Objective && (
        <>
          <Text size="xxl" weight={700}>
            {CAMPAIGN_STEP_TITLES[step]}
          </Text>
          <Spacing size="xl" />
          <Form width="70%">
            <Flex direction="column" gap="xl">
              {isNew && (
                <FormItem>
                  <Grid columns={3} gap="lg">
                    {CAMPAIGN_OBJECTIVE.map((objective) => (
                      <Type
                        key={objective.value}
                        active={objective.value === values.objective}
                        onClick={() => {
                          setValue('objective', objective.value);
                          setValue(
                            'budget.billing_type',
                            CAMPAIGN_OBJECTIVE_BILLING_TYPES[objective.value || CampaignObjective.Awareness][0],
                          );
                        }}
                      >
                        <Icon size="lg" type={objective.icon} />
                        <Text size="md" weight={500}>
                          {objective.title}
                        </Text>
                      </Type>
                    ))}
                  </Grid>
                </FormItem>
              )}
              <FormItem>
                <Text size="xl" weight={600}>
                  Select KPI
                </Text>
                <Spacing />
                <Flex gap="md">
                  <ControlledSelect
                    name="budget.billing_type"
                    control={control}
                    options={CAMPAIGN_OBJECTIVE_BILLING_TYPES[values.objective || CampaignObjective.Awareness].map(
                      (billingType) => CAMPAIGN_BILLING_TYPE_OPTIONS.find((option) => option.value === billingType),
                    )}
                    width="25rem"
                  />
                  {[
                    CampaignBillingType.CPV,
                    CampaignBillingType.CPCV,
                    CampaignBillingType.CPC,
                    CampaignBillingType.CPA,
                    CampaignBillingType.CPL,
                    CampaignBillingType.CPCL,
                  ].includes(values.budget?.billing_type) && (
                    <ControlledInput
                      type="number"
                      prefix="$"
                      name="budget.billing_target"
                      control={control}
                      placeholder="KPI"
                    />
                  )}
                  {[CampaignBillingType.CTR, CampaignBillingType.ViewabilityRate, CampaignBillingType.VCR].includes(
                    values.budget?.billing_type,
                  ) && (
                    <ControlledInput
                      type="number"
                      suffix="%"
                      name="budget.billing_target"
                      control={control}
                      placeholder="KPI"
                    />
                  )}
                  {values.budget?.billing_type === CampaignBillingType.ROI && (
                    <Flex width="100%" gap="md">
                      <ControlledInput
                        suffix=": 1"
                        name="budget.billing_roi_avg_order_value"
                        control={control}
                        placeholder="ROI Amount"
                      />
                      <ControlledInput
                        prefix="$"
                        name="budget.billing_target"
                        control={control}
                        placeholder="Average Order Value"
                      />
                    </Flex>
                  )}
                </Flex>
              </FormItem>
              <FormItem>
                <Flex>
                  <Text size="xl" weight={600}>
                    Schedule
                  </Text>
                  <Required />
                </Flex>
                <Spacing size="lg" />
                <Text size="sm">
                  <Flex direction="column" gap="md">
                    <Flex gap="md" align="center">
                      <div>Is Ongoing?</div>
                      <ControlledSwitch name="ongoing.enabled" control={control} />
                    </Flex>
                    {values.ongoing?.enabled ? (
                      <>
                        <Flex align="center" gap="md">
                          <div>Ongoing Cycle</div>
                          <ControlledSelect
                            name="ongoing.interval"
                            control={control}
                            width="12rem"
                            options={CAMPAIGN_ONGOING_INTERVAL_OPTIONS}
                            placeholder="Interval"
                          />
                          {['daily', 'weekly', 'monthly'].includes(values.ongoing?.interval) && (
                            <>
                              <div>Budget</div>
                              <ControlledInput
                                prefix="$"
                                name="ongoing.interval_budget"
                                type="number"
                                control={control}
                                width="16rem"
                              />
                            </>
                          )}
                          {['weekly', 'monthly', 'flights'].includes(values.ongoing?.interval) && (
                            <>
                              <div>Daily cap</div>
                              <ControlledInput
                                type="number"
                                prefix="$"
                                name="budget.amount_daily"
                                control={control}
                                width="10rem"
                              />
                            </>
                          )}
                          <div>Max Bid</div>
                          <ControlledInput
                            type="number"
                            prefix="$"
                            name="budget.max_bid_cpm"
                            control={control}
                            width="10rem"
                          />
                          <div>CPM</div>
                        </Flex>
                        {['flights'].includes(values.ongoing?.interval) && (
                          <>
                            <Text size="md" weight={600}>
                              Ongoing Flights
                            </Text>
                            {(values.ongoing?.flights ?? CAMPAIGN_DEFAULT_ONGOING_FLIGHTS).map((flight, index) => (
                              <Flex key={flight.id} align="center" gap="md">
                                <ControlledInput
                                  prefix="$"
                                  name={`ongoing.flights.${index}.budget`}
                                  type="number"
                                  control={control}
                                  width="16rem"
                                  placeholder="Budget"
                                />
                                <ControlledDatePicker
                                  name={`ongoing.flights.${index}.start_date`}
                                  control={control}
                                  placeholder="Start date"
                                  showTimeSelect
                                  width="24rem"
                                />
                                <ControlledDatePicker
                                  name={`ongoing.flights.${index}.end_date`}
                                  control={control}
                                  placeholder="End date"
                                  showTimeSelect
                                  width="24rem"
                                />
                                <Icon type="plus" color="primary" onClick={() => onAddOngoingFlight(index)} clickable />
                                {index !== 0 && (
                                  <Icon
                                    type="trash"
                                    color="error"
                                    onClick={() => onRemoveOngoingFlight(index)}
                                    clickable
                                  />
                                )}
                              </Flex>
                            ))}
                            <ControlledSelect
                              name="schedule.timezone"
                              control={control}
                              options={TIMEZONE_OPTIONS}
                              placeholder="Select timezone"
                              disabled={!isCreate}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Flex gap="md">
                          <FlexItem grow={1}>
                            <Flex direction="column" gap="md">
                              <Text size="sm">When should this campaign start?</Text>
                              <ControlledDatePicker
                                name="schedule.start_date"
                                control={control}
                                placeholder="Select start date"
                                showTimeSelect
                                disabled={
                                  !isNew && !isDraft && values.schedule?.start_date?.valueOf() <= new Date().valueOf()
                                }
                              />
                            </Flex>
                          </FlexItem>
                          <FlexItem grow={1}>
                            <Flex direction="column" gap="md">
                              <Text size="sm">When should this campaign finish?</Text>
                              <ControlledDatePicker
                                name="schedule.end_date"
                                control={control}
                                placeholder="Select end date"
                                showTimeSelect
                              />
                            </Flex>
                          </FlexItem>
                        </Flex>
                        <ControlledSelect
                          name="schedule.timezone"
                          control={control}
                          options={TIMEZONE_OPTIONS}
                          placeholder="Select timezone"
                          disabled={!isCreate}
                        />
                        <Flex align="center" gap="md">
                          <div>Budget</div>
                          <ControlledInput
                            type="number"
                            prefix="$"
                            name="budget.amount"
                            control={control}
                            width="16rem"
                          />
                          <div>Daily cap</div>
                          <ControlledInput
                            type="number"
                            prefix="$"
                            name="budget.amount_daily"
                            control={control}
                            width="10rem"
                          />
                          <div>Max Bid</div>
                          <ControlledInput
                            type="number"
                            prefix="$"
                            name="budget.max_bid_cpm"
                            control={control}
                            width="10rem"
                          />
                          <div>CPM</div>
                        </Flex>
                      </>
                    )}
                    <ControlledRadioGroup name="budget.pacing" control={control} options={CAMPAIGN_PACING_OPTIONS} />
                  </Flex>
                </Text>
              </FormItem>
              <FormItem>
                <Text size="xl" weight={600}>
                  Frequency capping
                </Text>
                <Spacing />
                <Text size="sm">
                  <Flex align="center" gap="sm">
                    <div>No more than</div>
                    <ControlledInput type="number" name="delivery.frequency_amount" control={control} width="10rem" />
                    <div>impression(s) per user every</div>
                    <ControlledSelect
                      name="delivery.frequency_interval"
                      options={CAMPAIGN_FREQUENCY_INTERVAL_OPTIONS}
                      control={control}
                      width="10rem"
                      placeholder="Interval"
                    />
                  </Flex>
                </Text>
              </FormItem>
              <FormItem>
                <Text size="xl" weight={600}>
                  Post-Click Engagement
                </Text>
                <Spacing />
                <Text size="sm">What action do you want to track after a click?</Text>
                <Spacing />
                <Flex>
                  <ControlledRadioGroup
                    name="engagement"
                    control={control}
                    options={CAMPAIGN_ENGAGEMENT_OPTIONS}
                    width="35rem"
                  />
                  <PixelSelect
                    agencyId={values.agency_id}
                    advertiserId={values.advertiser_id}
                    name="budget.cpa_action"
                    control={control}
                    idKey="id"
                    width="25rem"
                    onValueChange={(value) => {
                      setValue(
                        'engagement',
                        value ? CampaignEngagement.GrowthChannelPixel : CampaignEngagement.NoEngagement,
                      );
                    }}
                  />
                </Flex>
              </FormItem>
              <FormAction
                onBack={() => {
                  setStep(CampaignStep.Organize);
                }}
                onSubmit={() => {
                  if (step2Validate()) {
                    setStep(CampaignStep.Launch);
                  }
                }}
                submitText="NEXT"
                enableDraft={enableDraft}
                isSubmitting={isLoading}
                onDraftSubmit={onDraftSubmit}
                draftDisabled={draftDisabled}
              />
            </Flex>
          </Form>
        </>
      )}
      {step === CampaignStep.Launch && (
        <>
          <Text size="xxl" weight={700}>
            {CAMPAIGN_STEP_TITLES[step]}
          </Text>
          <Spacing size="xl" />
          <CampaignLaunch
            agencyId={values.agency_id}
            advertiserId={values.advertiser_id}
            dailyDue={Number(dailyDue)}
            onBack={() => setStep(CampaignStep.Objective)}
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isLoading}
            enableDraft={enableDraft}
            onDraftSubmit={onDraftSubmit}
            draftDisabled={draftDisabled}
          />
        </>
      )}
      <DraftSupport
        draftKey="campaign_draft"
        defaultValues={defaultValues}
        values={values}
        onUseDraft={(draft) => {
          reset(draftToFormValues(draft));
        }}
        // handleDraftParams={(draft, draftParams) => {
        //   if (draftParams?.audience_id) {
        //     draft.audience_id = Number(draftParams.audience_id);
        //   }
        //   if (draftParams?.creative_id) {
        //     if (!draft.creatives?.find((creativeId: number) => creativeId === Number(draftParams.creative_id))) {
        //       draft.creatives = [...(draft.creatives || []), Number(draftParams.creative_id)];
        //     }
        //   }
        // }}
        enabled={isNew && !copy}
      />
    </PageTemplate>
  );
};

const OrganizeFormContainer = styled.div`
  width: 50rem;
  display: grid;
  grid-template-columns: 16rem 1fr;
  gap: 1.2rem 0;
  align-items: center;
`;

const Type = styled.div<{ active?: boolean }>`
  padding: 2.4rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  text-align: center;
  background: white;
  box-shadow: ${TOKENS.shadow.default};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;

  img {
    width: 7.5rem;
    height: 7.5rem;
  }

  svg {
    width: 4rem;
    height: 4rem;
  }

  ${(props) =>
    props.active
      ? css`
          border: solid 0.4rem ${TOKENS.color.primary};
        `
      : ''}
`;

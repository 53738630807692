import { ControlledSelect, ControlledSelectProps } from '../Form';
import { growthApi } from 'src/services';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const { useCreativesQuery } = growthApi;

type CreativeSelectProps<T> = ControlledSelectProps<T> & {
  agencyId?: number;
  advertiserId?: number;
  withAll?: boolean;
  newParams?: any;
};

export const CreativeSelect = <T,>(props: CreativeSelectProps<T>) => {
  const { agencyId, advertiserId, withAll, newParams, ...rest } = props;

  const { data, isFetching } = useCreativesQuery({ agency_id: agencyId, advertiser_id: advertiserId });
  const navigate = useNavigate();

  const options = useMemo(() => {
    if (isFetching) {
      return [];
    }
    const dataOptions =
      data?.data?.map((creative: any) => ({
        label: `${creative.name} - ${creative.adlib_id}`,
        value: Number(creative.adlib_id),
      })) || [];
    return withAll ? [{ label: 'All', value: undefined }, ...dataOptions] : dataOptions;
  }, [isFetching, withAll, data]);

  return (
    <ControlledSelect
      options={options}
      isLoading={isFetching}
      placeholder="Select creative"
      newLabel="New Creative"
      onNew={() =>
        navigate(`/activate/creatives/new${newParams ? `?${new URLSearchParams(newParams).toString()}` : ''}`)
      }
      {...rest}
    />
  );
};

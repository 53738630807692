import { ControlledSelectOption } from 'src/components/Form';
import { subDays } from 'date-fns';

export enum DateRange {
  Yesterday = 'yesterday',
  Last7Days = '7',
  Last30Days = '30',
  Last90Days = '90',
  AllTime = 'all_time',
  Custom = 'custom',
}

export const DATE_RANGE_OPTIONS: ControlledSelectOption[] = [
  {
    label: 'Yesterday',
    value: DateRange.Yesterday,
  },
  {
    label: '7 Days',
    value: DateRange.Last7Days,
  },
  {
    label: '30 Days',
    value: DateRange.Last30Days,
  },
  {
    label: '90 Days',
    value: DateRange.Last90Days,
  },
  {
    label: 'All Time',
    value: DateRange.AllTime,
  },
];

export const DATE_RANGE_CUSTOM_OPTIONS: ControlledSelectOption[] = [
  ...DATE_RANGE_OPTIONS,
  {
    label: 'Custom',
    value: DateRange.Custom,
  },
];

export const parseDateRange = (dateRange: DateRange) => {
  const today = new Date();
  switch (dateRange) {
    case DateRange.Yesterday: {
      return {
        dateTo: subDays(today, 1),
        dateFrom: subDays(today, 1),
      };
    }
    case DateRange.Last7Days: {
      return {
        dateTo: today,
        dateFrom: subDays(today, 8),
      };
    }
    case DateRange.Last30Days: {
      return {
        dateTo: today,
        dateFrom: subDays(today, 30),
      };
    }
    case DateRange.Last90Days: {
      return {
        dateTo: today,
        dateFrom: subDays(today, 90),
      };
    }
  }
};

export const TIMEZONE_OPTIONS: ControlledSelectOption[] = [
  {
    label: '(GMT -11:00) Midway Island, Samoa',
    value: 2,
  },
  {
    label: '(GMT -10:00) Hawaii',
    value: 3,
  },
  {
    label: '(GMT -9:00) Alaska',
    value: 4,
  },
  {
    label: '(GMT -8:00) Pacific Time (US & Canada)',
    value: 5,
  },
  {
    label: '(GMT -7:00) Mountain Time (US & Canada)',
    value: 6,
  },
  {
    label: '(GMT -6:00) Central Time (US & Canada), Mexico City',
    value: 7,
  },
  {
    label: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
    value: 8,
  },
  {
    label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
    value: 9,
  },
  {
    label: '(GMT -3:30) Newfoundland',
    value: 10,
  },
  {
    label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
    value: 11,
  },
  {
    label: '(GMT -2:00) Mid-Atlantic',
    value: 12,
  },
  {
    label: '(GMT -1:00) Azores, Cape Verde Islands',
    value: 13,
  },
  {
    label: '(GMT) Greenwich Mean Time (no daylight savings)',
    value: 14,
  },
  {
    label: '(GMT) London, Lisbon, Casablanca',
    value: 15,
  },
  {
    label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
    value: 16,
  },
  {
    label: '(GMT +2:00) Kaliningrad, South Africa',
    value: 17,
  },
  {
    label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    value: 18,
  },
  {
    label: '(GMT +3:30) Tehran',
    value: 19,
  },
  {
    label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
    value: 20,
  },
  {
    label: '(GMT +4:30) Kabul',
    value: 21,
  },
  {
    label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    value: 22,
  },
  {
    label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
    value: 23,
  },
  {
    label: '(GMT +5:45) Kathmandu',
    value: 24,
  },
  {
    label: '(GMT +6:00) Almaty, Dhaka, Colombo',
    value: 25,
  },
  {
    label: '(GMT +7:00) Bangkok, Hanoi, Jakarta',
    value: 26,
  },
  {
    label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
    value: 27,
  },
  {
    label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    value: 28,
  },
  {
    label: '(GMT +9:30) Adelaide, Darwin',
    value: 29,
  },
  {
    label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
    value: 30,
  },
  {
    label: '(GMT +11:00) Pohnpei, Solomon Islands, New Caledonia',
    value: 31,
  },
  {
    label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
    value: 32,
  },
];

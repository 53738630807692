import { isArray } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, ControlledSelect, ControlledSelectOption, FormItem } from 'src/components/Form';
import { Flex, FlexItem, Text } from 'src/components/Layout';
import { growthApi } from 'src/services';
import { formatArray, formatText } from 'src/utils';
import styled from 'styled-components';
import { Tag } from '../Form/Tag';
import { DataProviderModal } from './DataProviderModal';

const { useAudiencesDatasetQuery } = growthApi;

export const DATA_PROVIDERS = [
  {
    ruleTypeId: 1,
    dataset: 'keywords',
  },
  {
    ruleTypeId: 9,
    dataset: 'city-state-region',
  },
  {
    ruleTypeId: 10,
    dataset: 'dma',
  },
  {
    ruleTypeId: 27,
    dataset: '3rd-party-audiences',
  },
];

const WITH_ALL_DATASETS = ['keywords', '3rd-party-audiences'];

type CustomRuleTypeDataProviderProps = {
  ruleTypeId: number;
  dataset: string;
};

export const CustomRuleTypeDataProvider = (props: CustomRuleTypeDataProviderProps) => {
  const { ruleTypeId, dataset } = props;

  const { control, watch: parentWatch, setValue: setParentValue } = useFormContext();
  const [dataProviderIsOpen, setDataProviderIsOpen] = useState<boolean>(false);
  const { data, isLoading } = useAudiencesDatasetQuery({
    dataset: 'rule_values',
    type: ruleTypeId,
  });

  const isCrossDataProvider = WITH_ALL_DATASETS.includes(dataset);

  const options: ControlledSelectOption[] = useMemo(() => {
    if (!data?.data) {
      return [];
    }
    const options = data.data.map((ruleType: any) => ({
      label: ruleType.name || ruleType.country || '',
      value: ruleType.id || ruleType.country,
    }));
    if (isCrossDataProvider) {
      return [{ label: 'All', value: -1 }, ...options];
    } else {
      return options;
    }
  }, [data?.data, isCrossDataProvider]);

  const parentValues = parentWatch();

  useEffect(() => {
    // auto open data provider modal if set open_data_provider_modal
    if (parentValues?.open_data_provider_modal) {
      setDataProviderIsOpen(true);
    }
  }, [parentValues?.open_data_provider_modal, setParentValue]);

  console.log(parentValues.rule_value);

  return (
    <>
      <FormItem label="Data Provider">
        <Flex gap="md" width="100%" align="center">
          <FlexItem grow={1}>
            <ControlledSelect
              name="rule_sub_type_id"
              control={control}
              placeholder="Select data provider"
              isLoading={isLoading}
              options={options}
              onValueChange={() => {
                if (!isCrossDataProvider) {
                  setParentValue('rule_value', '');
                  setParentValue('rule_value_name', []);
                }
              }}
            />
          </FlexItem>
          {Boolean(parentValues.rule_sub_type_id) && (
            <Button various="secondary" width="15rem" rounded={false} onClick={() => setDataProviderIsOpen(true)}>
              SELECT VALUES
            </Button>
          )}
        </Flex>
      </FormItem>
      <FormItem label="Value">
        <DataProviderValue>
          {isArray(parentValues.rule_value_name) && parentValues.rule_value_name.length > 0 ? (
            <Flex gap="xs" wrap="wrap">
              {parentValues.rule_value_name.map((row: any) => (
                <Tag
                  key={row.id}
                  onClose={() => {
                    setParentValue(
                      'rule_value',
                      // need format rule value
                      formatArray(parentValues.rule_value).filter((item: any) => item !== row.id),
                    );
                    setParentValue(
                      'rule_value_name',
                      parentValues.rule_value_name?.filter((item: any) => item.id !== row.id),
                    );
                  }}
                >
                  {formatText(row.full_path || row.name)}
                </Tag>
              ))}
            </Flex>
          ) : (
            <Flex justify="center">
              <Text size="sm" color="gray">
                Please select values
              </Text>
            </Flex>
          )}
        </DataProviderValue>
      </FormItem>
      <DataProviderModal
        dataset={dataset}
        parent={parentValues.rule_sub_type_id}
        isOpen={dataProviderIsOpen}
        onClose={() => setDataProviderIsOpen(false)}
      />
    </>
  );
};

const DataProviderValue = styled.div`
  padding: 0.6rem;
`;

import { Input } from 'src/components/Form';
import { Icon } from 'src/components/Icon';
import { Spacing } from 'src/components/Layout';
import { TOKENS } from 'src/design';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import styled from 'styled-components';

const PASSWORD_RULES = [
  {
    matcher: (password: string) => password.length >= 8,
    label: '8 characters',
  },
  {
    matcher: (password: string) => !/^[A-Za-z0-9 ]+$/.test(password),
    label: '1 special character',
  },
  {
    matcher: (password: string) => /[0-9]/.test(password),
    label: '1 number',
  },
  {
    matcher: (password: string) => /[A-Z]/.test(password),
    label: '1 uppercase letter',
  },
  {
    matcher: (password: string) => /[a-z]/.test(password),
    label: '1 lower case',
  },
];
const PASSWORD_MIN_SCORE = 3;
const PASSWORD_SCORE_COLORS = [
  TOKENS.color.grayLighter,
  TOKENS.color.error,
  TOKENS.color.error,
  TOKENS.color.warn,
  TOKENS.color.warn,
  TOKENS.color.primary,
];

const getPasswordScore = (password?: string) => {
  return PASSWORD_RULES.filter((rule) => Boolean(password && rule.matcher(password))).length;
};

export const passwordIsSafe = (password?: string) => {
  return getPasswordScore(password) >= PASSWORD_MIN_SCORE;
};

type PasswordFormItemsProps = {
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
};

export const PasswordFormItems = (props: PasswordFormItemsProps) => {
  const { register, watch } = props;

  const password = watch('password');

  return (
    <>
      <Input {...register('password')} type="password" placeholder="Enter password" icon="password" various="border" />
      <Spacing size="1.6rem" />
      <PasswordSafeContainer>
        <PasswordSafe score={getPasswordScore(password)} />
      </PasswordSafeContainer>
      <PasswordRulesContainer>
        {PASSWORD_RULES.map((rule) => (
          <PasswordRule key={rule.label} $passed={Boolean(password && rule.matcher(password))}>
            <Icon type="check" />
            <div>{rule.label}</div>
          </PasswordRule>
        ))}
      </PasswordRulesContainer>
      <Spacing size="1.2rem" />
      <Input
        {...register('confirmPassword')}
        type="password"
        placeholder="Confirm password"
        icon="password"
        various="border"
      />
    </>
  );
};

const PasswordRulesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  font-size: 1.2rem;
  padding: 0.6rem 0;
`;

const PasswordRule = styled.div<{ $passed?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.6rem;

  svg {
    fill: ${(props) => (props.$passed ? TOKENS.color.primary : TOKENS.color.gray)};
  }
`;

const PasswordSafeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0.4rem;
  background: ${TOKENS.color.grayLighter};
`;

const PasswordSafe = styled.div<{ score: number }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${(props) => (props.score / PASSWORD_RULES.length) * 100}%;
  background: ${(props) => PASSWORD_SCORE_COLORS[props.score]};
  border-radius: 100rem;
`;

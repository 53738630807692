import { useState } from 'react';
import { AddMoreFundsModal } from 'src/components/AddMoreFundsModal';
import { Button } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { SetBillingInformationModal } from 'src/components/SetBillingInformationModal';
import { TOKENS } from 'src/design';
import { useRole, useWhiteLabelTheme } from 'src/hooks';
import { growthApi } from 'src/services';
import { formatAmount } from 'src/utils';
import styled from 'styled-components';

const { useAdWalletQuery } = growthApi;

type CampaignLaunchProps = {
  agencyId?: number;
  advertiserId?: number;
  dailyDue?: number;
  isSubmitting?: boolean;
  onBack?: () => void;
  onSubmit?: () => void;
  enableDraft?: boolean;
  draftDisabled?: boolean;
  onDraftSubmit?: () => void;
};

export const CampaignLaunch = (props: CampaignLaunchProps) => {
  const {
    agencyId,
    advertiserId,
    dailyDue,
    onBack,
    onSubmit,
    enableDraft,
    draftDisabled,
    onDraftSubmit,
    isSubmitting,
  } = props;

  const { isAdmin, isWhiteLabel } = useRole();
  const theme = useWhiteLabelTheme();
  const {
    data,
    refetch,
    isLoading: isAdWalletLoading,
  } = useAdWalletQuery({ agency_id: agencyId, advertiser_id: advertiserId, with_credit_balance: true });

  const [setBillingInformationIsOpen, setSetBillingInformationIsOpen] = useState<boolean>(false);
  const [addMoreFundsIsOpen, setAddMoreFundsIsOpen] = useState<boolean>(false);

  const hasPaymentMethod = Boolean(data?.data?.payment_method_id);
  const haveEnoughBalance = Number(data?.data?.credit_balance ?? 0) >= Number(dailyDue);

  return (
    <LaunchContainer>
      <PayInfo>
        <Text size="lg" weight={600}>
          <Flex justify="space-between">
            <div>Account Balance</div>
            <div>{formatAmount(data?.data?.credit_balance)}</div>
          </Flex>
          <Spacing size="xl" />
          <Flex justify="space-between">
            <div>Daily Due</div>
            <div>{formatAmount(dailyDue)}</div>
          </Flex>
        </Text>
        <Spacing size="xxl" />
        <Text size="xs">Enter your credit card and finance contact information before launching the campaign.</Text>
        <Spacing size="xl" />
        <Flex gap="md" align="baseline">
          <Text weight={600}>Credit Card</Text>
          <Text size="xs">
            {hasPaymentMethod ? `${data?.data?.card?.brand} ************${data?.data?.card?.last4}` : 'No card on file'}
          </Text>
        </Flex>
      </PayInfo>
      <Flex gap="xl">
        <Button various="secondary" width="25rem" onClick={onBack}>
          BACK
        </Button>
        {!isAdWalletLoading && (
          <>
            {hasPaymentMethod ? (
              haveEnoughBalance ? (
                <Button width="25rem" shadow onClick={onSubmit} isLoading={isSubmitting}>
                  SUBMIT
                </Button>
              ) : (
                <Button width="25rem" shadow onClick={() => setAddMoreFundsIsOpen(true)}>
                  ADD MORE FUNDS
                </Button>
              )
            ) : (
              <>
                {haveEnoughBalance ? (
                  <Button width="25rem" shadow onClick={onSubmit} isLoading={isSubmitting}>
                    SUBMIT
                  </Button>
                ) : (
                  <Button width="25rem" shadow onClick={() => setSetBillingInformationIsOpen(true)}>
                    ADD INFORMATION
                  </Button>
                )}
                {(isAdmin || isWhiteLabel) && (
                  <Button width="25rem" various="secondary" onClick={onSubmit} isLoading={isSubmitting}>
                    LAUNCH WITHOUT CC
                  </Button>
                )}
              </>
            )}
          </>
        )}
        {enableDraft && (
          <Button
            width="25rem"
            various="secondary"
            onClick={onDraftSubmit}
            isLoading={isSubmitting}
            disabled={draftDisabled}
          >
            SAVE DRAFT
          </Button>
        )}
      </Flex>
      <PayNotice>
        <Text size="xs">
          Your credit card will be charged automatically as you spend. As a security measure for new credit cards, an
          initial {formatAmount(theme.initialCharge)} charge will be made and credited to your account. It can be
          refunded if not spent.
        </Text>
        <Spacing />
        <Text size="xs">By creating a campaign, you are agree to the terms and conditions.</Text>
      </PayNotice>
      <SetBillingInformationModal
        isOpen={setBillingInformationIsOpen}
        agencyId={agencyId}
        advertiserId={advertiserId}
        onClose={() => {
          setSetBillingInformationIsOpen(false);
        }}
        onSuccess={() => {
          setSetBillingInformationIsOpen(false);
          refetch();
        }}
      />
      <AddMoreFundsModal
        isOpen={addMoreFundsIsOpen}
        agencyId={agencyId}
        advertiserId={advertiserId}
        onClose={() => {
          setAddMoreFundsIsOpen(false);
        }}
        onSuccess={() => {
          setAddMoreFundsIsOpen(false);
          refetch();
        }}
      />
    </LaunchContainer>
  );
};

const LaunchContainer = styled.div`
  width: 100%;
  margin-top: ${TOKENS.spacing.xl};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${TOKENS.spacing.xl};
  gap: 2.4rem;
`;

const PayInfo = styled.div`
  width: 50%;
  padding: 3.6rem;
  background: white;
  border-radius: 1.2rem;
  box-shadow: ${TOKENS.shadow.default};
`;

const PayNotice = styled.div`
  width: 50%;
  padding: 3.6rem;
  background: #ebf7f2;
  border-radius: 1.2rem;
  box-shadow: ${TOKENS.shadow.default};
`;

import { Form, FormItem } from 'src/components/Form';
import { ControlledInput } from 'src/components/Form/ControlledInput';
import { Modal } from 'src/components/Modal';
import { useRole, useToast } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';
import { AgencySelect } from '../AgencySelect';
import { AdvertiserSelect } from '../AdvertiserSelect';

const { useCreateUserMutation } = growthApi;

type CreateUserModalProps = {
  isOpen: boolean;
  onSuccess: () => void;
  onClose: () => void;
};

type UserDSPBindingFormValues = {
  email?: string;
  password?: string;
  agency_id?: number;
  advertiser_id?: number;
};

export const CreateUserModal = (props: CreateUserModalProps) => {
  const { isOpen, onSuccess, onClose } = props;

  const { watch, handleSubmit, control } = useForm<UserDSPBindingFormValues>();
  const [createUser, { isLoading }] = useCreateUserMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const { isAgency, isWhiteLabel } = useRole();
  const values = watch();

  const onSubmit = async (values: UserDSPBindingFormValues) => {
    try {
      await createUser(values).unwrap();
      showSuccessToast('Create user successfully');
      onClose();
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Create User"
      isLoading={isLoading}
      onConfirm={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <Form>
        <FormItem label="Email" required>
          <ControlledInput name="email" control={control} placeholder="Enter email" />
        </FormItem>
        <FormItem label="Password" required>
          <ControlledInput name="password" control={control} placeholder="Enter password" />
        </FormItem>
        {(isAgency || isWhiteLabel) && (
          <>
            <FormItem label="Agency" required>
              <AgencySelect name="agency_id" control={control} />
            </FormItem>
            <FormItem label="Advertiser" required>
              <AdvertiserSelect agencyId={values.agency_id} name="advertiser_id" control={control} />
            </FormItem>
          </>
        )}
      </Form>
    </Modal>
  );
};

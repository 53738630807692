import { Form, FormItem } from 'src/components/Form';
import { ControlledInput } from 'src/components/Form/ControlledInput';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import { User } from 'src/types';
import { getApiErrorMessage } from 'src/utils';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { AgencySelect } from 'src/components/AgencySelect';

const { useUserDSPBindingMutation } = growthApi;

type UserDSPBindingModalProps = {
  isOpen: boolean;
  user?: User;
  onSuccess: () => void;
  onClose: () => void;
};

type UserDSPBindingFormValues = {
  userId: number;
  dspUsername: string;
  dspPassword: string;
  dspAgencyId: number;
  dspAdvertiserId: number;
};

export const UserDSPBindingModal = (props: UserDSPBindingModalProps) => {
  const { isOpen, user, onSuccess, onClose } = props;

  const { watch, handleSubmit, control, setValue, reset } = useForm<UserDSPBindingFormValues>();
  const [userDSPBinding, { isLoading }] = useUserDSPBindingMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const values = watch();

  useEffect(() => {
    if (isOpen) {
      reset({
        userId: user?.id,
        dspUsername: '',
        dspPassword: '',
        dspAgencyId: undefined,
        dspAdvertiserId: undefined,
      });
    }
  }, [isOpen, user, reset]);

  const onSubmit = async (values: UserDSPBindingFormValues) => {
    try {
      const apiResult = await userDSPBinding(values).unwrap();
      showSuccessToast(apiResult.message);
      onClose();
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="DSP Binding"
      isLoading={isLoading}
      confirmText="BINDING"
      onConfirm={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <Form>
        <FormItem label="DSP Username" required>
          <ControlledInput name="dspUsername" control={control} placeholder="Enter DSP username" />
        </FormItem>
        <FormItem label="DSP Password" required>
          <ControlledInput name="dspPassword" control={control} placeholder="Enter DSP password" />
        </FormItem>
        <FormItem label="Agency">
          <AgencySelect
            name="dspAgencyId"
            control={control}
            onValueChange={() => setValue('dspAdvertiserId', undefined)}
            disableAutoSelect
          />
        </FormItem>
        <FormItem label="Advertiser">
          <AdvertiserSelect agencyId={values.dspAgencyId} name="dspAdvertiserId" control={control} disableAutoSelect />
        </FormItem>
      </Form>
    </Modal>
  );
};

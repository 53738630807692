import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ControlledInput, ControlledSelect, Form, FormItem } from 'src/components/Form';
import { Icon } from 'src/components/Icon';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';

const { useUpdateAdvertiserExtMutation } = growthApi;

const THIRD_PARTY_PLATFORM_OPTIONS = [
  { label: 'Adlib', value: 'adlib' },
  { label: 'Stackadapt', value: 'stackadapt' },
  { label: 'Beeswax', value: 'beeswax' },
  { label: 'IQM', value: 'iqm' },
  { label: 'OR-Adlib', value: 'oradlib' },
];

type EditPlatformFormValues = {
  advertiser_id: number;
  third_party_type: string;
  third_party_id: number;
  platforms: {
    id: string;
    platform: string;
    platform_id: any;
  }[];
};

const DEFAULT_FORM_VALUES: EditPlatformFormValues = {
  advertiser_id: null,
  third_party_type: 'adlib',
  third_party_id: null,
  platforms: [],
};

type EditPlatformModalProps = {
  isOpen: boolean;
  advertiser: any;
  onClose: () => void;
  onSuccess: () => void;
};

export const EditPlatformModal = (props: EditPlatformModalProps) => {
  const { isOpen, advertiser, onClose, onSuccess } = props;

  const { watch, control, setValue, reset, handleSubmit } = useForm<EditPlatformFormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
  });
  const values = watch();

  const [updateAdvertiserExt, { isLoading }] = useUpdateAdvertiserExtMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  useEffect(() => {
    if (isOpen) {
      if (advertiser) {
        reset({
          advertiser_id: advertiser.id,
          third_party_type: advertiser.third_party_type || 'adlib',
          third_party_id: advertiser.third_party_id,
          platforms: advertiser.platforms || [],
        });
      } else {
        reset(DEFAULT_FORM_VALUES);
      }
    }
  }, [isOpen, advertiser, reset]);

  const onAddPlatform = () => {
    setValue('platforms', [...values.platforms, { id: nanoid(), platform: 'adlib', platform_id: '' }]);
  };

  const onRemovePlatform = (id: string) => {
    setValue(
      'platforms',
      values.platforms.filter((platform) => platform.id !== id),
    );
  };

  const onSubmit = async (values: EditPlatformFormValues) => {
    try {
      await updateAdvertiserExt(values).unwrap();
      showSuccessToast('Update advertiser platform successfully');
      onSuccess();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Edit Advertiser Platform"
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Form>
        <FormItem label="Current Platform" required>
          <Flex gap="md" width="100%">
            <FlexItem grow={1}>
              <ControlledSelect
                name="third_party_type"
                control={control}
                options={THIRD_PARTY_PLATFORM_OPTIONS}
                placeholder="Select platform"
              />
            </FlexItem>
            {/* {values.third_party_type && values.third_party_type !== 'adlib' && (
              <ControlledInput type="number" name="third_party_id" control={control} placeholder="Enter platform id" />
            )} */}
          </Flex>
        </FormItem>
        <FormItem
          label="Platforms"
          addition={
            <Text color="primary" size="sm" weight={600} onClick={onAddPlatform} clickable>
              Add Platform
            </Text>
          }
        >
          <Spacing />
          <Flex direction="column" gap="md">
            {values.platforms.map((platform, index) => (
              <Flex key={platform.id} gap="md" align="center">
                <ControlledSelect
                  name={`platforms.${index}.platform`}
                  control={control}
                  options={THIRD_PARTY_PLATFORM_OPTIONS}
                />
                <ControlledInput
                  name={`platforms.${index}.platform_id`}
                  control={control}
                  type="number"
                  placeholder="Enter platform id"
                />
                <Icon type="trash" color="error" onClick={() => onRemovePlatform(platform.id)} clickable />
              </Flex>
            ))}
          </Flex>
        </FormItem>
      </Form>
    </Modal>
  );
};

import {
  ControlledFile,
  ControlledInput,
  ControlledRadioGroup,
  ControlledRadioGroupOption,
  ControlledTextArea,
  Form,
  FormItem,
} from 'src/components/Form';
import { FormAction } from 'src/components/Form/FormAction';
import { Icon, IconProps } from 'src/components/Icon';
import { PageTemplate } from 'src/components/Template';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { TOKENS } from 'src/design';
import { useRole, useToast, useUserAgencyAdvertiser } from 'src/hooks';
import { ReactNode, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import {
  fileToBase64,
  getApiErrorMessage,
  getFileDimension,
  getFileExt,
  getFileSize,
  isCustomDomain,
  transformObject,
  TransformType,
} from 'src/utils';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { DraftSupport } from 'src/components/DraftSupport';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { format } from 'date-fns';
import { AgencySelect } from 'src/components/AgencySelect';
import { CreativePreview } from 'src/components/CreativePreview';

const { useCreativesQuery, useCreateOrUpdateCreativeMutation } = growthApi;

enum CreativeType {
  BannerAd = 'file',
  AdTag = 'tag',
  AudioAd = 'audio',
  VideoAd = 'video',
  NativeAd = 'native',
  HTML5Ad = 'html5',
}

enum CreativeAdTagType {
  Single,
  Bulk,
}

const CREATIVE_TYPES: { label: string; value: CreativeType; icon: IconProps['type'] }[] = [
  { label: 'Banner Ad', value: CreativeType.BannerAd, icon: 'image' },
  { label: 'Ad Tag', value: CreativeType.AdTag, icon: 'tag' },
  { label: 'HTML5 Ad', value: CreativeType.HTML5Ad, icon: 'html' },
  { label: 'Audio Ad', value: CreativeType.AudioAd, icon: 'voice' },
  { label: 'Video Ad', value: CreativeType.VideoAd, icon: 'video' },
  { label: 'Native Ad', value: CreativeType.NativeAd, icon: 'desktop' },
];

const CREATIVE_TYPE_TITLES: Record<CreativeType, string> = {
  [CreativeType.BannerAd]: 'Banner Ad',
  [CreativeType.AdTag]: 'Ad Tag',
  [CreativeType.HTML5Ad]: 'Html5 Ad',
  [CreativeType.AudioAd]: 'Audio Ad',
  [CreativeType.VideoAd]: 'Video Ad',
  [CreativeType.NativeAd]: 'Native Ad',
};

const CREATIVE_UPLOAD_NOTES: Record<CreativeType, ReactNode> = {
  [CreativeType.BannerAd]: (
    <Flex direction="column" gap="xs" width="100%">
      <Text size="xs">.jpg, .png, or .gif only (500KB max)</Text>
      <Text size="xs">Multiple files can be uploaded in a .zip (4MB max)</Text>
      <Text size="xs">All animations must stop after 15 seconds</Text>
      <Text size="xs">
        <Text weight={600} as="span">
          Display Ad Dimensions
        </Text>
        : 300x250, 728x90, 160x600, 300x600, 970x250, 336x280, 468x60, 120x600, 180x150, 250x250, 234x60, 970x90,
        200x200, 300x1050
      </Text>
      <Text size="xs">
        <Text weight={600} as="span">
          Mobile Ad Dimensions
        </Text>
        : 320x480, 320x50, 728x90, 300x250, 360x640, 300x50, 216x36, 120x20, 168x28, 480x320, 768x1024, 120x240
      </Text>
      <Text size="xs">
        <Text weight={600} as="span">
          DOOH Ad Dimensions
        </Text>
        : 1400x400, 840x400, 1200x400, 1920x1080, 1080x1920, 2160x3840, 1024x768, 1280x720, 2048x768, 1080x1440,
        1920x960, 2560x720
      </Text>
    </Flex>
  ),
  [CreativeType.AdTag]: (
    <Flex direction="column" gap="xs" width="100%">
      <a target="_blank" href="https://dsp.growthchannel.io/resources/bulk-ad-tags-template.xlsx" rel="noreferrer">
        <Text as="span" size="xs" color="primary">
          Download Bulk Ad Tag Template
        </Text>
      </a>
      <Text size="xs">Upload the completed Bulk Tag Template as an XLSX file (4MB Max)</Text>
    </Flex>
  ),
  [CreativeType.HTML5Ad]: (
    <Flex direction="column" gap="xs" width="100%">
      <Text size="xs">.zip files only (500KB max)</Text>
      {!isCustomDomain() && (
        <a
          target="_blank"
          href="https://growthchannel.io/help/article/what-are-the-compatible-html5-assets"
          rel="noreferrer"
        >
          <Text as="span" size="xs" color="primary">
            View Complete HTML5 Ad Specs
          </Text>
        </a>
      )}
    </Flex>
  ),
  [CreativeType.AudioAd]: (
    <Flex direction="column" gap="xs" width="100%">
      <Text size="xs">Upload Audio files only or audio + companion banners as a .zip (1GB) max</Text>
      <Text size="xs">Audio files must be .wav, .mp3, or .ogg</Text>
      <Text size="xs">Companion banners must be .gif, .jpg, or .png</Text>
      <Text size="xs">
        <Text weight={600} as="span">
          Audio Ad Companion Banner Dimensions
        </Text>
        : 300x250, 728x90, 300x50, 320x50, 500x500, 540x640, 640x640
      </Text>
    </Flex>
  ),
  [CreativeType.VideoAd]: (
    <Flex direction="column" gap="xs" width="100%">
      <Text size="xs">.flv, .mp4, .avi, wmv, .mpeg1/2, .webm or .mov files only (1GB max)</Text>
      <Text size="xs">
        <Text weight={600} as="span">
          Video Ad Dimensions
        </Text>
        : 1920x1080:700, 1920x800:700, 1280x720:400, 854x480:400,1024x768:400, 640x380:100, 640x480:100,
        640x360:100,480x360:100, 320x240:100, 300x250:100
      </Text>
      {!isCustomDomain() && (
        <Text size="xs">
          For all VPAID options, visit our{' '}
          <a
            target="_blank"
            href="https://growthchannel.io/help/article/video-and-vpaid-creatives-what-formats-are-supported"
            rel="noreferrer"
          >
            <Text as="span" color="primary">
              help center
            </Text>
          </a>
        </Text>
      )}
    </Flex>
  ),
  [CreativeType.NativeAd]: (
    <Flex direction="column" gap="xs" width="100%">
      <Text size="xs">Native image sizes must be 1600x1200, 1200x1200, or 1600x1600 below 500 kb.</Text>
      <Text size="xs">
        For multiple ads, upload a zip file containing 1) Native Image Assets and 2) a csv file that includes columns
        for Creative Name, Image Name, Headline, Subtitle, Sponsored by name
      </Text>
      <a target="_blank" href="https://dsp.growthchannel.io/resources/native-ad-template.csv" rel="noreferrer">
        <Text as="span" size="xs" color="primary">
          Download Native CSV Template
        </Text>
      </a>
    </Flex>
  ),
};

const AD_TAG_TYPE_OPTIONS: ControlledRadioGroupOption[] = [
  { label: 'Single Ad Tag', value: CreativeAdTagType.Single },
  { label: 'Bulk Upload', value: CreativeAdTagType.Bulk },
];

type CreativeFormValues = {
  id?: number;
  name?: string;
  type?: CreativeType;
  agency_id?: number;
  advertiser_id?: number;
  clickthrough_url?: string;
  third_party_impression_tracker?: string;
  tag?: string;
  ad_tag_type?: CreativeAdTagType;
  files?: File[];
  status?: number;
  start_date?: Date;
  end_date?: Date;
  s3?: string;
};

export const CreativeEdit = () => {
  const { canAccessAgency } = useRole();
  const { agencyId, advertiserId } = useUserAgencyAdvertiser();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const copy = searchParams.get('copy');
  const from = searchParams.get('from');
  const advertiserIdFromUrl = searchParams.get('advertiser_id');
  const isNew = !id;
  const { data, isLoading: isDetailLoading } = useCreativesQuery(
    { id: id || copy, advertiser_id: advertiserIdFromUrl },
    { skip: isNew && !copy },
  );
  const creativeName = data?.data?.name;
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const defaultValues = useMemo(
    () => ({
      agency_id: agencyId,
      advertiser_id: advertiserId,
      files: [],
      ad_tag_type: CreativeAdTagType.Single,
      status: 1,
    }),
    [advertiserId, agencyId],
  );
  const { handleSubmit, control, watch, setValue, reset } = useForm<CreativeFormValues>({
    defaultValues,
  });
  const [createOrUpdateCreative, { isLoading }] = useCreateOrUpdateCreativeMutation();

  const values = watch();

  useEffect(() => {
    if (data) {
      const setupFormValues = async () => {
        const transformedData = transformObject(data?.data, {
          id: TransformType.Number,
          agency_id: TransformType.Number,
          advertiser_id: TransformType.Number,
          name: TransformType.None,
          // filename: TransformType.None,
          type: TransformType.None,
          clickthrough_url: TransformType.None,
          third_party_impression_tracker: TransformType.None,
          status: TransformType.Number,
          start_date: TransformType.Date,
          end_date: TransformType.Date,
          tag: TransformType.None,
          s3: TransformType.None,
        });
        if (copy) {
          transformedData.name = `${transformedData.name} copy`;
          delete transformedData.id;
        }
        reset({
          ...transformedData,
          ad_tag_type: 0,
        });
      };
      setupFormValues();
    }
  }, [copy, data, reset]);

  const onValidate = async (values: CreativeFormValues) => {
    if (!values.agency_id) {
      return 'Please select agency';
    }
    if (!values.advertiser_id) {
      return 'Please select advertiser';
    }
    if (!values.name && values.type !== CreativeType.NativeAd) {
      return 'Please enter name';
    }
    if (!isNew) {
      return;
    }
    if (values.type === CreativeType.AdTag && values.ad_tag_type === CreativeAdTagType.Single) {
      return;
    }
    if (!values.files || values.files.length === 0) {
      return 'Please select upload files';
    }
    for (const file of values.files) {
      const fileSize = getFileSize(file);
      const fileExt = getFileExt(file);
      const fileSizeError = `File ${file.name} is too large`;
      const fileExtError = `File ${file.name} type is not support`;
      const dimensionError = `File ${file.name} dimension is invalid`;
      switch (values.type) {
        case CreativeType.BannerAd:
          switch (fileExt) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
              if (fileSize > 500 * 1024) {
                return fileSizeError;
              }
              const dimension = await getFileDimension(file);
              if (
                ![
                  '300x250',
                  '728x90',
                  '160x600',
                  '300x600',
                  '970x250',
                  '336x280',
                  '468x60',
                  '120x600',
                  '180x150',
                  '250x250',
                  '234x60',
                  '970x90',
                  '200x200',
                  '300x1050',
                  '1400x400',
                  '840x400',
                  '1200x400',
                  '1920x1080',
                  '1080x1920',
                  '2160x3840',
                  '1024x768',
                  '1280x720',
                  '2048x768',
                  '1080x1440',
                  '1920x960',
                  '2560x720',
                  // mobile
                  '320x480',
                  '320x50',
                  '728x90',
                  '300x250',
                  '360x640',
                  '300x50',
                  '216x36',
                  '120x20',
                  '168x28',
                  '480x320',
                  '768x1024',
                  '120x240',
                ].includes(dimension)
              ) {
                return dimensionError;
              }
              break;
            case 'zip':
              if (fileSize > 4 * 1024 * 1024) {
                return fileSizeError;
              }
              break;
            default:
              return fileExtError;
          }
          break;
        case CreativeType.VideoAd:
          switch (fileExt) {
            case 'flv':
            case 'mp4':
            case 'avi':
            case 'wmv':
            case 'mpeg':
            case 'webm':
            case 'mov':
              break;
            default:
              return fileExtError;
          }
          break;
        case CreativeType.NativeAd:
          switch (fileExt) {
            case 'zip':
              break;
            default:
              return fileExtError;
          }
          break;
      }
    }
  };

  const onSubmit = async (values: CreativeFormValues) => {
    try {
      const validateError = await onValidate(values);
      if (validateError) {
        showErrorToast(validateError);
        return;
      }
      const { files, ...rest } = values;
      await createOrUpdateCreative({
        ...rest,
        files:
          isNew && files
            ? await Promise.all(
                files.map(async (file: any) => ({
                  file: await fileToBase64(file),
                  file_name: file.name,
                  file_type: file.type,
                })),
              )
            : undefined,
        start_date: values.start_date ? format(new Date(values.start_date), 'yyyy-MM-dd HH:mm') : undefined,
        end_date: values.end_date ? format(new Date(values.end_date), 'yyyy-MM-dd HH:mm') : undefined,
      }).unwrap();
      if (isNew) {
        showSuccessToast('Create creative successfully');
        localStorage.removeItem('creative_draft');
        if (from === 'campaign') {
          navigate('/activate/campaigns/new?draft=1');
        } else {
          navigate('/activate/creatives');
        }
      } else {
        showSuccessToast('Save creative successfully');
        navigate('/activate/creatives');
      }
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate isLoading={isDetailLoading}>
      <Text size="xxl" weight={700}>
        {!values.type ? 'Create Creative' : 'Ad Builder'}
      </Text>
      <Spacing size="xl" />
      {!values.type ? (
        <FormItem>
          <TypeContainer>
            {CREATIVE_TYPES.map((type) => (
              <Type
                key={type.value}
                onClick={() => {
                  setValue('type', type.value);
                }}
              >
                <Icon type={type.icon} />
                <Text size="md" weight={600}>
                  {type.label}
                </Text>
              </Type>
            ))}
          </TypeContainer>
        </FormItem>
      ) : (
        <Flex gap="xxl">
          <FlexItem grow={1}>
            <Text size="lg" weight={600}>
              {CREATIVE_TYPE_TITLES[values.type!]}
            </Text>
            <Spacing size="lg" />
            <Form>
              {canAccessAgency && isNew && (
                <>
                  <AgencySelect
                    name="agency_id"
                    control={control}
                    onValueChange={() => setValue('advertiser_id', undefined)}
                  />
                  <AdvertiserSelect agencyId={values.agency_id} name="advertiser_id" control={control} />
                </>
              )}
              {values.type === CreativeType.AdTag && isNew && (
                <ControlledRadioGroup name="ad_tag_type" control={control} options={AD_TAG_TYPE_OPTIONS} />
              )}
              {!(values.type === CreativeType.AdTag && values.ad_tag_type === CreativeAdTagType.Bulk) &&
                values.type !== CreativeType.NativeAd && (
                  <ControlledInput name="name" control={control} placeholder="Creative name" />
                )}
              {!(values.type === CreativeType.AdTag && values.ad_tag_type === CreativeAdTagType.Single) && isNew && (
                <ControlledFile
                  name="files"
                  control={control}
                  notes={CREATIVE_UPLOAD_NOTES[values.type || CreativeType.BannerAd]}
                  multiple={true}
                />
              )}
              {!(values.type === CreativeType.AdTag && values.ad_tag_type === CreativeAdTagType.Bulk) && (
                <ControlledInput name="clickthrough_url" control={control} placeholder="Link to the landing page" />
              )}
              {!(values.type === CreativeType.AdTag && values.ad_tag_type === CreativeAdTagType.Bulk) && (
                <ControlledInput
                  name="third_party_impression_tracker"
                  control={control}
                  placeholder="Impression tracking (Optional)"
                />
              )}
              {values.type === CreativeType.AdTag && values.ad_tag_type === CreativeAdTagType.Single && (
                <Flex direction="column" gap="sm">
                  <ControlledTextArea name="tag" control={control} placeholder="Place code here" rows={10} />
                  {!isCustomDomain() && (
                    <a
                      target="_blank"
                      href="https://growthchannel.io/help/article/what-macros-can-i-use-for-3rd-party-adserver-tags-and-click-trackers"
                      rel="noreferrer"
                    >
                      <Text size="xs" color="primary">
                        See accepted macros
                      </Text>
                    </a>
                  )}
                </Flex>
              )}
              <Flex gap="md">
                <ControlledDatePicker
                  name="start_date"
                  control={control}
                  placeholder="Start date (Optional)"
                  showTimeSelect
                />
                <ControlledDatePicker
                  name="end_date"
                  control={control}
                  placeholder="End date (Optional)"
                  showTimeSelect
                />
              </Flex>
              <FormAction
                onBack={isNew && !copy ? () => setValue('type', undefined) : undefined}
                onSubmit={handleSubmit(onSubmit)}
                isSubmitting={isLoading}
                disabled={isDetailLoading}
              />
            </Form>
          </FlexItem>
          <FlexItem width="40rem">
            <Text size="lg" weight={600}>
              Ad Preview
            </Text>
            <Spacing size="lg" />
            <Flex direction="column" gap="md">
              {isNew ? (
                <>
                  {values.files && values.files.length > 0 ? (
                    <PreviewImages>
                      <Flex direction="column" gap="md">
                        {values.files.map((file, index) => {
                          if (!/image\/.*/.test(file.type)) {
                            return null;
                          }
                          return <PreviewImage key={index} alt="img" src={URL.createObjectURL(file)} />;
                        })}
                      </Flex>
                    </PreviewImages>
                  ) : (
                    <NoImage>
                      <Flex direction="column" gap="md" justify="center" align="center">
                        <Text size="sm">Select upload file to preview</Text>
                        <Text size="xs" color="gray">
                          (only support image)
                        </Text>
                      </Flex>
                    </NoImage>
                  )}
                </>
              ) : (
                <PreviewImages>
                  {/\.(jpg|jpeg|png|gif)$/.test(values.s3) ? (
                    <PreviewImage alt="img" src={values.s3} />
                  ) : (
                    <CreativePreview creativeName={creativeName} advertiserId={advertiserId}></CreativePreview>
                  )}
                </PreviewImages>
              )}
            </Flex>
          </FlexItem>
        </Flex>
      )}
      <DraftSupport
        draftKey="creative_draft"
        defaultValues={defaultValues}
        values={values}
        onUseDraft={(draft) => {
          if (draft.start_date) {
            draft.start_date = new Date(draft.start_date);
          }
          if (draft.end_date) {
            draft.end_date = new Date(draft.end_date);
          }
          reset(draft);
        }}
        enabled={isNew && !copy}
      />
    </PageTemplate>
  );
};

const TypeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  padding-top: 1.2rem;

  svg {
    width: 4rem;
    height: 4rem;
  }
`;

const Type = styled.div`
  padding: 3.6rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  background: white;
  box-shadow: ${TOKENS.shadow.default};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-items: center;
`;

const PreviewImages = styled.div`
  max-height: 60rem;
  overflow: auto;

  img {
    aspect-ratio: unset;
  }
`;

const PreviewImage = styled.img`
  width: 100%;
  object-fit: contain;
  background: white;
`;

const NoImage = styled.div`
  width: 40rem;
  height: 40rem;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;

import { ApexOptions } from 'apexcharts';
import { ControlledSelect } from 'src/components/Form/ControlledSelect';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { format } from 'date-fns';
import { CHART_COLORS, TOKENS } from 'src/design';
import { orderBy, take } from 'lodash';
import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { METRIC_OPTIONS } from './constants';
import { ReportingFormValues } from './Reporting';
import { useDataTable, useRole, useWhiteLabelTheme } from 'src/hooks';
import { ControlledInput, ControlledSwitch } from 'src/components/Form';
import { Column, DataTable, DataTableExport } from 'src/components/DataTable';
import { calcWithMarkup, formatAmount, formatPercentage } from 'src/utils';
import { Loading } from 'src/components/Loading';
import { ReportingInfo } from 'src/components/ReportingInfo';

const { useSiteTransparencyQuery } = growthApi;

const MAX_COUNT = 20;

type ReportingSitesFormValues = {
  metric?: string;
  search?: string;
  withMarkup?: boolean;
};

type ReportingSitesProps = Pick<
  ReportingFormValues,
  'timeRange' | 'dateFrom' | 'dateTo' | 'agencyId' | 'advertiserId' | 'campaignId'
> & {
  markup?: number;
};

export const ReportingSites = (props: ReportingSitesProps) => {
  const { timeRange, dateFrom, dateTo, agencyId, advertiserId, campaignId, markup } = props;

  const { canWithMarkup } = useRole();
  const theme = useWhiteLabelTheme();
  const isNotSelectAgencyAdvertiser = !agencyId || !advertiserId;

  const { data, isFetching, error } = useSiteTransparencyQuery(
    {
      time_range: timeRange,
      date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
      date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
      agency_id: agencyId,
      advertiser_id: advertiserId,
      campaign_id: campaignId,
    },
    { skip: isNotSelectAgencyAdvertiser },
  );

  const { watch, control } = useForm<ReportingSitesFormValues>({
    defaultValues: {
      metric: 'impressions',
      withMarkup: true,
    },
  });

  const values = watch();
  const finalMarkup = values.withMarkup ? markup : 0;

  const chartData = useMemo(() => {
    let xaxisCategories: any[] = [];
    let seriesData: any[] = [];

    if (data?.data?.length) {
      const orderedData = orderBy(data.data, (row) => Number(row[values.metric!]), 'desc');
      const resultData = take(orderedData, MAX_COUNT);
      xaxisCategories = resultData.map((row) => row.site_domain);
      seriesData = resultData.map((row) => row[values.metric!]);
    }

    return { xaxisCategories, seriesData };
  }, [data, values.metric]);

  const chartOptions: ApexOptions = {
    colors: CHART_COLORS(theme),
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: chartData.xaxisCategories,
    },
  };
  const chartSeries: ApexAxisChartSeries = [
    {
      name: METRIC_OPTIONS.find((option) => option.value === values.metric)?.label,
      data: chartData.seriesData,
    },
  ];

  const columns: Column[] = [
    {
      header: 'Site',
      accessor: 'site_domain',
      render: (value) => {
        return (
          <a href={`https://${value}`} target="_blank" rel="noreferrer">
            <Flex gap="md" align="center">
              <img
                alt="logo"
                width={20}
                height={20}
                src={`https://adlib-favicons.s3.us-east-1.amazonaws.com/${value}.png`}
              />
              <Text breakAll>{value}</Text>
            </Flex>
          </a>
        );
      },
      export: (value) => value,
      sortable: true,
    },
    {
      header: 'Impressions',
      accessor: 'impressions',
      sortable: true,
    },
    {
      header: 'Clicks',
      accessor: 'clicks',
      sortable: true,
    },
    {
      header: 'CTR',
      accessor: 'ctr',
      render: formatPercentage,
      sortable: true,
    },
    {
      header: 'CPM',
      accessor: 'cpm',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPC',
      accessor: 'cpc',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPV',
      accessor: 'cpv',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'Conversions',
      accessor: 'total_conversions',
      sortable: true,
    },
    {
      header: 'CPA',
      accessor: 'cpa',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'Spend',
      accessor: 'total_spend',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
  ];

  const { dataTableProps, dataTableRef } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['site_domain'],
    defaultSort: {
      key: 'impressions',
      direction: 'desc',
    },
    sortNumberKeys: ['impressions', 'clicks', 'ctr', 'cpm', 'cpc', 'cpv', 'total_conversions', 'cpa', 'total_spend'],
  });

  if (isNotSelectAgencyAdvertiser) {
    return <ReportingInfo message="Please select agency and advertiser to see the report" />;
  }

  return (
    <>
      <ChartContainer>
        <Flex direction="column" gap="xs">
          <Text size="xs">Metric</Text>
          <ControlledSelect name="metric" control={control} options={METRIC_OPTIONS} width="20rem" />
        </Flex>
        <Spacing size="lg" />
        {isFetching ? (
          <Loading height="40rem" />
        ) : (
          <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={400} />
        )}
      </ChartContainer>
      <Spacing size="lg" />
      <TableContainer>
        <Flex gap="lg" align="center">
          <ControlledInput name="search" control={control} prefix="Search:" placeholder="Keyword" />
          {canWithMarkup && (
            <FlexItem shrink={0}>
              <Flex gap="md" align="center">
                <Text size="xs">With markup</Text>
                <ControlledSwitch name="withMarkup" control={control} />
              </Flex>
            </FlexItem>
          )}
          <DataTableExport
            onExport={(type) => {
              dataTableRef?.current?.export(type, 'reporting-sites');
            }}
          />
        </Flex>
        <Spacing size="lg" />
        <DataTable columns={columns} {...dataTableProps} />
      </TableContainer>
    </>
  );
};

const ChartContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;

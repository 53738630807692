import { TOKENS } from 'src/design';
import styled, { css } from 'styled-components';

type TextColor = keyof typeof TOKENS.color;
type TextSize = keyof typeof TOKENS.fontSize;

export type TextProps = {
  color?: TextColor | (string & {});
  size?: TextSize;
  weight?: number;
  clickable?: boolean;
  ellipsis?: boolean;
  breakAll?: boolean;
  left?: string;
  align?: 'left' | 'center' | 'right';
};

export const Text = styled.div<TextProps>`
  ${(props) => (props.color ? `color: ${props.theme.color[props.color] ?? props.color};` : '')};
  ${(props) => (props.size ? `font-size: ${TOKENS.fontSize[props.size]};` : '')};
  ${(props) => (props.weight ? `font-weight: ${props.weight};` : '')};
  ${(props) => (props.align ? `text-align: ${props.align};` : '')};
  ${(props) => (props.clickable ? `cursor: pointer;` : '')};
  ${(props) => (props.breakAll ? `word-break: break-all;` : '')};
  ${(props) =>
    props.ellipsis
      ? css`
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        `
      : ''};
  ${(props) => (props.left ? `margin-left: ${props.left};` : '')};
`;

import { useMemo } from 'react';
import { growthApi } from 'src/services';

const { useAgenciesQuery } = growthApi;

export const useWhiteLabelAgencyIds = () => {
  const { data: agencyData, isLoading } = useAgenciesQuery();

  const whiteLabelAgencyIds: number[] = useMemo(() => {
    return (
      agencyData?.data?.reduce((prev, current) => {
        if (current.is_white_label) {
          return [...prev, current.id];
        } else {
          return prev;
        }
      }, []) || []
    );
  }, [agencyData?.data]);

  return { isLoading, whiteLabelAgencyIds };
};

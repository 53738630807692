export type ApiResult<T = any> = {
  status?: string;
  statusCode?: number;
  error?: string;
  message?: string | string[];
  data?: T;
};

export type ApiError<T = any> = {
  data: ApiResult<T>;
};

export type UserLevelPrivileges = {};

export type UserLevel = {
  id: number;
  price_monthly: number;
  price_annual: number;
  privileges: UserLevelPrivileges;
  order: number;
  is_custom: boolean;
};

export type User = {
  id: number;
  email: string;
  role: Role;
  is_admin: boolean;
  is_wl: boolean;
  belong_user_id?: number;
  billing?: UserBilling;
  payment?: any;
  dsp_username?: string;
  dsp_agency_id?: number;
  dsp_advertiser_id?: number;
  dsp_exclusion_rules?: any[];
  branding?: any;
  level: UserLevel;
  level_expired_at?: Date;
  third_party_mapping: Record<
    number,
    {
      advertiser_id: number;
      third_party_type: 'stackadapt';
      third_party_id: number;
    }
  >;
  workspace?: any;
};

export enum Role {
  Admin = 'admin',
  WhiteLabel = 'whiteLabel',
  Agency = 'agency',
  Advertiser = 'advertiser',
}

export type UserBilling = {
  stripe?: {
    customerId: string;
    paymentMethodId: string;
    card: {
      brand: string;
      last4: string;
    };
  };
  autoCredit?: {
    dailyCap?: number;
    period?: 'weekly' | 'monthly';
  };
  lastUpdatedAt?: number;
};

export type Audience = {
  id: number;
  name: string;
  'campaigns-targeted': number;
  created_by_name: string;
  created_on: string;
  updated_on: string;
};

export type AudienceGroup = {
  group_id?: number;
  name?: string;
  group_inclusion_type?: 'include' | 'exclude';
  rule_grouping_type?: 'and' | 'or';
};

export type AudienceRule = {
  id?: number;
  audience_group_id?: number;
  advertiser?: any;
  advertiser_id?: number;
  rule_inclusion_type?: 'include' | 'exclude';
  rule_category_id?: number;
  rule_type_id?: number;
  rule_type_id_name?: string;
  rule_response_type?: RuleResponseType;
  rule_sub_type_id?: number;
  rule_sub_type_unit?: any;
  rule_type_name?: string;
  rule_value?: any;
  rule_value_additional?: any;
  rule_value_name?: {
    full_path?: string;
    id?: number;
    name?: string;
  }[];
  multi_value_grouping_type?: 'or' | 'and';
};

export enum RuleResponseType {
  Text = 1,
  SingleSelect = 2,
  MultiSelect = 3,
  Custom = 5,
  YesNo = 6,
}

export type AudienceDataset = {
  id: number;
  name: string;
  rule_response_type: RuleResponseType;
  placeholder_text: string;
};

export type Creative = {
  adlib_id: number;
  name: string;
  advertiser_name: string;
  type: string;
  created_by_name: string;
  created_on: string;
  updated_on: string;
};

export type CreativeReport = {
  creative_id: number;
  creative_name: string;
  impressions: string;
  total_spend: string;
  ctr: string;
};

export type Pixel = {
  adlib_id: number;
  name: string;
  advertiser_name: string;
  created_by: string;
  created_on: string;
  updated_on: string;
};

export type Campaign = {
  adlib_id: number;
  id: number;
  name: string;
  advertiser_name: string;
  created_by: string;
  created_on: string;
};

export type Agency = {
  id: number;
  name: string;
};

export type Advertiser = {
  id: number;
  name: string;
};

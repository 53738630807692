import { TOKENS } from 'src/design';
import { growthApi } from 'src/services';
import styled from 'styled-components';

const { useCreativePreviewQuery } = growthApi;

type CreativePreviewProps = {
  creativeName?: string;
  advertiserId?: number;
};

export const CreativePreview = (props: CreativePreviewProps) => {
  const { creativeName, advertiserId } = props;

  const { data, isLoading } = useCreativePreviewQuery({
    creative_name: creativeName,
    advertiser_id: advertiserId,
  });

  return isLoading ? <ImagePlaceholder /> : data?.data ? <CreativePreviewImage src={data.data} /> : null;
};

const ImagePlaceholder = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  background: ${TOKENS.color.bgGray};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CreativePreviewImage = styled.img`
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
  background: ${TOKENS.color.bgGray};
`;

import { ControlledSelect, ControlledSelectProps } from '../Form';
import { growthApi } from 'src/services';
import { useEffect, useMemo } from 'react';
import { useController } from 'react-hook-form';

const { useAgenciesQuery } = growthApi;

type AgencySelectProps<T> = ControlledSelectProps<T> & {
  withAll?: boolean;
  disableAutoSelect?: boolean;
};

export const AgencySelect = <T,>(props: AgencySelectProps<T>) => {
  const { withAll, disableAutoSelect, ...rest } = props;

  const { data, isFetching } = useAgenciesQuery();

  const options = useMemo(() => {
    if (isFetching) {
      return [];
    }
    const dataOptions =
      data?.data?.map((agency) => ({
        label: `${agency.name} - ${agency.id}`,
        value: agency.id,
      })) || [];
    return withAll ? [{ label: 'All', value: undefined }, ...dataOptions] : dataOptions;
  }, [isFetching, withAll, data]);

  const {
    field: { onChange },
  } = useController(rest);

  useEffect(() => {
    if (disableAutoSelect) {
      return;
    }
    if ((options || []).filter((option) => Boolean(option.value)).length === 1) {
      // hack: fix the loading forever issue
      setTimeout(() => {
        onChange(options?.find((option) => Boolean(option.value)).value);
      }, 0);
    }
  }, [options, onChange, disableAutoSelect]);

  return <ControlledSelect options={options} isLoading={isFetching} placeholder="Select agency" {...rest} />;
};

import { ApexOptions } from 'apexcharts';
import { format } from 'date-fns';
import { CHART_COLORS } from 'src/design';
import { growthApi } from 'src/services';
import { calcWithMarkup, DateRange, fixPrecision, formatAmount, formatCount, formatPercentage } from 'src/utils';
import styled, { css } from 'styled-components';
import { OverviewFormValues } from './Overview';
import { Grid, Spacing, Text } from 'src/components/Layout';
import { useMemo, useState } from 'react';
import { Loading } from 'src/components/Loading';
import ReactApexChart from 'react-apexcharts';
import { DiffPercentage } from 'src/components/DiffPercentage';
import { nth } from 'lodash';
import { useWhiteLabelTheme } from 'src/hooks';

const { useCampaignPerformanceQuery } = growthApi;

type ChartSummaryProps = OverviewFormValues & {
  markup?: number;
};

export const ChartSummary = (props: ChartSummaryProps) => {
  const { timeRange, dateFrom, dateTo, agencyId, advertiserId, markup } = props;

  const summaryItems = [
    { label: 'CTR', value: 'ctr', format: formatPercentage },
    { label: 'Impressions', value: 'impressions', format: formatCount },
    { label: 'Clicks', value: 'clicks', format: formatCount },
    { label: 'Conversions', value: 'total_conversions', format: formatCount },
    {
      label: 'CPC',
      value: 'cpc',
      format: formatAmount,
    },
    {
      label: 'CPM',
      value: 'cpm',
      format: formatAmount,
    },
    {
      label: 'CPA',
      value: 'cpa',
      format: formatAmount,
    },
    {
      label: 'Spend',
      value: 'total_spend',
      format: formatAmount,
    },
  ];

  const theme = useWhiteLabelTheme();
  const [currentSummaryItem, setCurrentSummaryItem] = useState<string>('ctr');

  const { data, isFetching } = useCampaignPerformanceQuery({
    time_range: timeRange,
    date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
    date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
    agency_id: agencyId,
    advertiser_id: advertiserId,
    breakout: 'day',
  });

  const chartOptions: ApexOptions = {
    colors: CHART_COLORS(theme),
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      categories: data?.data?.map((row) => row.start_date) || [],
    },
  };
  const chartSeries: ApexAxisChartSeries = [
    {
      name: summaryItems.find((item) => item.value === currentSummaryItem)?.label,
      data:
        data?.data?.map((row) => (row[currentSummaryItem] !== '-' ? fixPrecision(row[currentSummaryItem]) : 0)) || [],
    },
  ];

  const currentValue = timeRange === DateRange.Yesterday ? nth(data?.data, -1) : nth(data?.data, -2);
  const lastValue = nth(data?.data, -3);

  const summary = useMemo(() => {
    let totalImpressions = 0;
    let totalClicks = 0;
    let totalSpend = 0;
    let totalConversions = 0;

    data?.data?.forEach((item) => {
      totalImpressions += item['impressions'] !== '-' ? Number(item['impressions'] ?? 0) : 0;
      totalClicks += item['clicks'] !== '-' ? Number(item['clicks'] ?? 0) : 0;
      totalConversions += item['total_conversions'] !== '-' ? Number(item['total_conversions'] ?? 0) : 0;
      totalSpend += item['total_spend'] !== '-' ? Number(item['total_spend'] ?? 0) : 0;
    });

    const totalSpendWithMarkup = calcWithMarkup(totalSpend, markup);

    return {
      totalImpressions,
      totalClicks,
      totalConversions,
      totalSpend: totalSpendWithMarkup === '-' ? 0 : totalSpendWithMarkup,
    };
  }, [data, markup]);

  return (
    <Container>
      <Text size="md" weight={700}>
        Summary
      </Text>
      <Spacing size="lg" />
      {isFetching ? (
        <Loading height="40rem" />
      ) : (
        <>
          <Grid columns={8} gap="md">
            {summaryItems.map((item) => {
              let value = 0;
              switch (item.value) {
                case 'impressions':
                  value = summary.totalImpressions;
                  break;
                case 'clicks':
                  value = summary.totalClicks;
                  break;
                case 'total_conversions':
                  value = summary.totalConversions;
                  break;
                case 'total_spend':
                  value = summary.totalSpend;
                  break;
                case 'ctr':
                  value = summary.totalImpressions === 0 ? 0 : (summary.totalClicks / summary.totalImpressions) * 100;
                  break;
                case 'cpc':
                  value = summary.totalClicks === 0 ? 0 : summary.totalSpend / summary.totalClicks;
                  break;
                case 'cpm':
                  value = summary.totalImpressions === 0 ? 0 : (summary.totalSpend / summary.totalImpressions) * 1000;
                  break;
                case 'cpa':
                  value = summary.totalConversions === 0 ? 0 : summary.totalSpend / summary.totalConversions;
                  break;
              }
              return (
                <SummaryItem
                  key={item.value}
                  active={currentSummaryItem === item.value}
                  onClick={() => setCurrentSummaryItem(item.value)}
                >
                  <Text size="xs">{item.label}</Text>
                  <Text size="lg" weight={700}>
                    {item.format ? item.format(value) : value}
                  </Text>
                  <DiffPercentage
                    color={currentSummaryItem === item.value ? 'white' : undefined}
                    value={currentValue?.[item.value]}
                    lastValue={lastValue?.[item.value]}
                  />
                </SummaryItem>
              );
            })}
          </Grid>
          <Spacing size="lg" />
          <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={300} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: white;
  padding: 1.6rem 2.4rem;
  box-shadow: ${(props) => props.theme.shadow.default};
  border-radius: 1rem;
`;

const SummaryItem = styled.div<{ active: boolean }>`
  ${(props) =>
    props.active
      ? css`
          color: white;
          background: ${(props) => props.theme.color.primary};
        `
      : css`
          background: #fbfbfd;
        `};
  padding: 1.2rem 1.6rem;
  border-radius: 1rem;
  cursor: pointer;
`;

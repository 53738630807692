import { Column, DataTable } from 'src/components/DataTable';
import { Action, Button, ControlledInput } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { growthApi } from 'src/services';
import { formatCount, formatDate, paramsToQuery } from 'src/utils';
import { useForm } from 'react-hook-form';
import { AgencySelect } from 'src/components/AgencySelect';
import { pick } from 'lodash';
import { Archive } from 'src/components/Archive';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { useDataTable, useRole, useUserAgencyAdvertiser } from 'src/hooks';

const { useAudiencesQuery } = growthApi;

type AudiencesFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  search?: string;
};

export const Audiences = () => {
  const { canAccessAgency } = useRole();
  const { agencyId, advertiserId, changeAgencyAdvertiser } = useUserAgencyAdvertiser();
  const { watch, setValue, control } = useForm<AudiencesFormValues>({
    defaultValues: {
      agency_id: agencyId,
      advertiser_id: advertiserId,
    },
  });
  const values = watch();

  useEffect(() => {
    changeAgencyAdvertiser(values.agency_id, values.advertiser_id);
  }, [changeAgencyAdvertiser, values.agency_id, values.advertiser_id]);

  const { data, isFetching, error, refetch } = useAudiencesQuery(pick(values, ['agency_id', 'advertiser_id']));

  const columns: Column[] = [
    { header: 'ID', accessor: 'id', sortable: true },
    { header: 'Name', accessor: 'name', sortable: true },
    { header: '# Campaigns Targeted', accessor: 'campaigns-targeted', sortable: true, render: formatCount },
    { header: 'Created At', accessor: 'created_on', sortable: true, render: formatDate },
    {
      header: '',
      accessor: 'action',
      width: '3rem',
      render: (_, row) => {
        const type = `audience${row.third_party ? `_${row.third_party}` : ''}`;
        return (
          <Flex gap="md">
            <Link to={`/activate/audiences/edit?${paramsToQuery({ copy: row.id, advertiser_id: row.advertiser_id })}`}>
              <Action>Copy</Action>
            </Link>
            <Link to={`/activate/audiences/edit?${paramsToQuery({ id: row.id, advertiser_id: row.advertiser_id })}`}>
              <Action>Edit</Action>
            </Link>
            <Archive type={type} typeId={row.id} onSuccess={() => refetch()} />
          </Flex>
        );
      },
    },
  ];

  const { dataTableProps } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    search: values.search,
    searchKeys: ['id', 'name'],
    sortNumberKeys: ['id'],
  });

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Audiences
          </Text>
          {canAccessAgency && (
            <>
              <AgencySelect
                prefix="Agency:"
                name="agency_id"
                control={control}
                withAll
                width="22rem"
                onValueChange={() => {
                  setValue('advertiser_id', undefined);
                }}
              />
              <AdvertiserSelect
                agencyId={values.agency_id}
                prefix="Advertiser:"
                name="advertiser_id"
                control={control}
                withAll
                width="22rem"
              />
            </>
          )}
          <ControlledInput prefix="Search: " name="search" control={control} placeholder="Keyword" width="22rem" />
        </Flex>
        <Flex gap="xxl">
          <Link to={`/activate/audiences/new?${paramsToQuery({ advertiser_id: advertiserId })}`}>
            <Button width="16rem" shadow>
              NEW AUDIENCE
            </Button>
          </Link>
        </Flex>
      </Flex>
      <Spacing size="xl" />
      <DataTable columns={columns} {...dataTableProps} />
    </PageTemplate>
  );
};

import { Column, DataTable } from 'src/components/DataTable';
import { Action, Button, ControlledInput } from 'src/components/Form';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { useForm } from 'react-hook-form';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { growthApi } from 'src/services';
import { useDataTable, useRole, useUserAgencyAdvertiser } from 'src/hooks';
import { formatDate, imageUrl, isCustomDomain } from 'src/utils';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import styled from 'styled-components';
import { TOKENS } from 'src/design';

const { useCustomAudienceInsightsQuery } = growthApi;

type InsightFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  search?: string;
};

export const Insights = () => {
  const { canAccessAgency } = useRole();
  const { agencyId, advertiserId, changeAgencyAdvertiser } = useUserAgencyAdvertiser();
  const { watch, control, setValue } = useForm<InsightFormValues>({
    defaultValues: {
      agency_id: agencyId,
      advertiser_id: advertiserId,
    },
  });
  const values = watch();
  const { data, isFetching, error } = useCustomAudienceInsightsQuery({
    agency_id: values.agency_id,
    advertiser_id: values.advertiser_id,
  });

  useEffect(() => {
    changeAgencyAdvertiser(values.agency_id, values.advertiser_id);
  }, [changeAgencyAdvertiser, values.agency_id, values.advertiser_id]);

  const { dataTableProps } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['id', 'name'],
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    sortNumberKeys: ['id'],
  });

  const columns: Column[] = [
    {
      header: 'ID',
      accessor: 'id',
      sortable: true,
    },
    {
      header: 'Name',
      accessor: 'name',
      sortable: true,
    },
    { header: 'Created At', accessor: 'created_at', render: formatDate, sortable: true },
    {
      header: '',
      accessor: '_action',
      render: (_: any, row: any) => (
        <Link to={`/activate/insights/view?id=${row.id}`}>
          <Action>View</Action>
        </Link>
      ),
    },
  ];

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Insights
          </Text>
          {canAccessAgency && (
            <>
              <AgencySelect
                prefix="Agency: "
                name="agency_id"
                control={control}
                withAll
                width="22rem"
                onValueChange={() => setValue('advertiser_id', undefined)}
              />
              <AdvertiserSelect
                agencyId={values.agency_id}
                prefix="Advertiser:"
                name="advertiser_id"
                control={control}
                withAll
                width="22rem"
              />
            </>
          )}
          <ControlledInput width="22rem" prefix="Search: " name="search" control={control} placeholder="Keyword" />
        </Flex>
      </Flex>
      <Spacing size="xl" />
      <Flex gap="md">
        <FlexItem grow={1}>
          <DataTable columns={columns} {...dataTableProps} />
        </FlexItem>
        {!isCustomDomain() && (
          <FlexItem width="45rem">
            <Card>
              <CardContent>
                <Flex direction="column" gap="md">
                  <Text size="lg" weight={700}>
                    Understand{' '}
                    <Text as="span" color="primary">
                      Audience Insights
                    </Text>
                  </Text>
                  <Text size="sm">
                    Identify consumer demographics and behavioral profiles of your US audience from advertising
                    campaigns, location visits, and website visitors.
                    <br />
                    <a href="https://www.growthchannel.com/events-iq" target="_blank" rel="noreferrer">
                      <Text color="primary">Learn more{'>>'}</Text>
                    </a>
                  </Text>
                  <a href="https://share.hsforms.com/15LqIGyJlQ5Onz7_82rz8Ow3vw0w" target="_blank" rel="noreferrer">
                    <Button width="20rem" shadow>
                      SUBMIT A REQUEST
                    </Button>
                  </a>
                </Flex>
              </CardContent>
              <img src={imageUrl('welcome-bg.png')} alt="background" />
            </Card>
          </FlexItem>
        )}
      </Flex>
    </PageTemplate>
  );
};

const Card = styled.div`
  position: relative;
  overflow: hidden;
  padding: 2rem 3.6rem 2rem 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  background: white;
  border-radius: 0.4rem;
  word-break: break-word;

  img {
    position: absolute;
    width: 18rem;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
`;

const CardContent = styled.div`
  position: relative;
  z-index: 2;
`;

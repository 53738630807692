import { useForm } from 'react-hook-form';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { AgencySelect } from 'src/components/AgencySelect';
import { ControlledInput, Form, FormItem } from 'src/components/Form';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { getApiErrorMessage } from 'src/utils';

const { useCreateCustomAudienceInsightMutation } = growthApi;

type CustomAudienceShareFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  name?: string;
};

type CustomAudienceShareProps = {
  data?: any;
  isOpen: boolean;
  onClose: () => void;
};

export const CustomAudienceShare = (props: CustomAudienceShareProps) => {
  const { data, isOpen, onClose } = props;
  const { control, watch, handleSubmit } = useForm<CustomAudienceShareFormValues>();
  const values = watch();
  const [createCustomAudienceInsight, { isLoading }] = useCreateCustomAudienceInsightMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const onSubmit = async (values: CustomAudienceShareFormValues) => {
    try {
      await createCustomAudienceInsight({ ...values, data }).unwrap();
      showSuccessToast('Share custom audience successfully');
      onClose();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <Modal
      title="Share Custom Audience"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Form>
        <FormItem label="Agency" required>
          <AgencySelect name="agency_id" control={control} />
        </FormItem>
        <FormItem label="Advertiser">
          <AdvertiserSelect agencyId={values.agency_id} name="advertiser_id" control={control} />
        </FormItem>
        <FormItem label="Name" required>
          <ControlledInput name="name" control={control} placeholder="Enter name" />
        </FormItem>
      </Form>
    </Modal>
  );
};

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DataCreditsBanner } from 'src/components/DataCreditsBanner';
import { Button, ControlledInput } from 'src/components/Form';
import { Icon } from 'src/components/Icon';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { UserBanner } from 'src/components/UserBanner';
import { useReloadUser, useToast, useWorkspace } from 'src/hooks';
import { growthApi } from 'src/services';
import { formatAmountWithPrecision, getApiErrorMessage } from 'src/utils';
import styled, { css } from 'styled-components';

const { useUpdateWorkspaceMutation } = growthApi;

const PRICING_PLANS = [
  {
    title: 'Basic',
    plan: 'basic',
    price: 200,
    privileges: [
      '3 users',
      '1 website',
      'Premium advertising',
      'Persona builder',
      'Cookieless Tracking',
      'Geofencing real-time events',
      'Geofencing past events as an Add-on',
      'Custom Audiences as an Add-on',
      'Managed Service as an Add-on',
      'White Label as an Add-on',
      'Audience Insights as an Add-on',
      '250 Monthly AI Data Credits',
    ],
  },
  {
    title: 'Plus',
    plan: 'plus',
    price: 1500,
    privileges: [
      '5 users',
      '1 website',
      'Premium advertising',
      'Persona builder',
      'Cookieless Tracking',
      'Geofencing real-time & past events',
      '1 Geofencing request',
      'Custom Audiences up to 3k records',
      'Managed Service',
      'White Label as an Add-on',
      'Audience Insights: up to 3k site visitors',
      '1,000 Monthly AI Data Credits',
    ],
  },
  {
    title: 'Pro',
    plan: 'pro',
    price: 5000,
    privileges: [
      '10 users',
      '10 websites',
      'Premium advertising',
      'Persona builder',
      'Cookieless Tracking',
      'Geofencing real-time & past events',
      'Up to 5 Geofencing requests',
      'Custom Audiences up to 10k records',
      'Managed Service',
      'White Label',
      'Audience Insights: up to 10k site visitors',
      '5,000 Monthly AI Data Credits',
    ],
  },
  // {
  //   title: 'Enterprise',
  //   plan: 'enterprise',
  //   price: 15000,
  //   privileges: [
  //     'Unlimited users',
  //     '50 websites',
  //     'Premium advertising',
  //     'Persona builder',
  //     'Cookieless Tracking',
  //     'Geofencing real-time & past events',
  //     'Up to 15 Geofencing requests',
  //     'Custom Audiences up to 50k records',
  //     'Managed Service',
  //     'White Label',
  //     'Audience Insights: up to 50k site visitors',
  //     '15,000 Monthly AI Data Credits',
  //   ],
  // },
];

type PricingFormValues = {
  dataCredits?: number;
};

export const Pricing = () => {
  const workspace = useWorkspace();
  const { watch, control } = useForm<PricingFormValues>({
    defaultValues: {
      dataCredits: 1000,
    },
  });
  const values = watch();
  const reloadUser = useReloadUser();
  const [updateWorkspace, { isLoading }] = useUpdateWorkspaceMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const [plan, setPlan] = useState<string>();
  const nav = useNavigate();

  const onSubmit = async (plan?: string) => {
    setPlan(plan);
    try {
      await updateWorkspace({
        id: workspace?.id,
        plan: plan,
        data_credits: plan ? undefined : values.dataCredits,
      }).unwrap();
      showSuccessToast(plan ? 'Subscribe successfully' : 'Top up data credits successfully');
      reloadUser();
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex justify="space-between" align="center" clickable onClick={() => nav(-1)}>
          <Icon type="back" color="primary" />
          <Text size="md" color="primary" weight={600}>
            Back
          </Text>
        </Flex>
        <Flex align="center" gap="lg">
          <DataCreditsBanner />
          <UserBanner />
        </Flex>
      </Flex>
      <Spacing size="5rem" />
      <Flex justify="center">
        <Text size="xl" weight={700}>
          <Text as="span">Choose your </Text>
          <Text as="span" color="primary">
            growth plan
          </Text>
        </Text>
      </Flex>
      <Spacing size="6rem" />
      <Flex justify="center" gap="md">
        {PRICING_PLANS.map((item) => (
          <div key={item.plan}>
            <PriceItem active={workspace?.plan === item.plan}>
              <PriceItemHeader active={workspace?.plan === item.plan}>{item.title}</PriceItemHeader>
              <Spacing size="xl" />
              <Text size="xl" weight={600} align="center">
                {formatAmountWithPrecision(item.price, 0)}
                <Text as="span" size="md">
                  /mo
                </Text>
              </Text>
              <Spacing size="xl" />
              {item.privileges.map((privilege) => (
                <TextWithPadding key={privilege} size="sm">
                  {privilege}
                </TextWithPadding>
              ))}
              <Spacing size="xl" />
              <Flex justify="center">
                <Button
                  various="primary"
                  shadow
                  width="16rem"
                  isLoading={plan === item.plan && isLoading}
                  disabled={workspace?.plan === item.plan}
                  onClick={() => {
                    onSubmit(item.plan);
                  }}
                >
                  {workspace?.plan === item.plan ? 'CURRENT PLAN' : 'SUBSCRIBE'}
                </Button>
              </Flex>
              <Spacing size="xxl" />
            </PriceItem>
          </div>
        ))}
        <div>
          <PriceItem>
            <PriceItemHeader>Data credits</PriceItemHeader>
            <Spacing size="xl" />
            <Text size="xl" weight={600} align="center">
              ${Number((values.dataCredits * 0.2).toFixed(2))}
            </Text>
            <TextWithPadding size="sm" align="center">
              <Text>Top up data credits</Text>
              <Spacing size="lg" />
              <Flex justify="center">
                <ControlledInput name="dataCredits" control={control} width="16rem" />
              </Flex>
            </TextWithPadding>
            <Spacing />
            <TextWithPadding size="sm">
              <Flex gap="sm">
                <Text weight={600}>250</Text>
                <Text>Channel strategy</Text>
              </Flex>
            </TextWithPadding>
            <TextWithPadding size="sm">
              <Flex gap="sm">
                <Text weight={600}>250</Text>
                <Text>Persona profile</Text>
              </Flex>
            </TextWithPadding>
            <TextWithPadding size="sm">
              <Flex gap="sm">
                <Text weight={600}>150</Text>
                <Text>Website content</Text>
              </Flex>
            </TextWithPadding>
            <TextWithPadding size="sm">
              <Flex gap="sm">
                <Text weight={600}>100</Text>
                <Text>Advertising copy</Text>
              </Flex>
            </TextWithPadding>
            <TextWithPadding size="sm">
              <Flex gap="sm">
                <Text weight={600}>150</Text>
                <Text>Custom audiences</Text>
              </Flex>
            </TextWithPadding>
            <TextWithPadding size="sm">
              <Flex gap="sm">
                <Text weight={600}>50</Text>
                <Text>Benchmarks</Text>
              </Flex>
            </TextWithPadding>
            <TextWithPadding size="sm">
              <Flex gap="sm">
                <Text weight={600}>50</Text>
                <Text>Competitor analysis</Text>
              </Flex>
            </TextWithPadding>
            <Spacing size="xl" />
            <Flex justify="center">
              <Button
                various="primary"
                shadow
                width="16rem"
                isLoading={plan === undefined && isLoading}
                onClick={() => {
                  onSubmit();
                }}
              >
                TOP UP
              </Button>
            </Flex>
            <Spacing size="xxl" />
          </PriceItem>
        </div>
      </Flex>
    </PageTemplate>
  );
};

const PriceItem = styled.div<{ active?: boolean }>`
  width: 28rem;
  border-radius: 1rem;
  overflow: hidden;
  background: white;

  input {
    text-align: center;
  }

  ${(props) =>
    props.active
      ? css`
          border: solid 0.2rem ${props.theme.color.primary};
        `
      : css`
          border: solid 0.2rem ${props.theme.color.bgGray};
        `}
`;

const PriceItemHeader = styled.div<{ active?: boolean }>`
  font-size: 1.8rem;
  text-align: center;
  line-height: 5rem;
  font-weight: 600;

  ${(props) =>
    props.active
      ? css`
          color: white;
          background: ${props.theme.color.primary};
        `
      : css`
          background: #f2f2f2;
        `}
`;

const TextWithPadding = styled(Text)`
  padding: 0.6rem 2.4rem;
`;

import { ControlledInput, ControlledRadioGroup, Form, FormItem } from 'src/components/Form';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AudienceGroup } from './AudienceEdit';
import { GROUP_INCLUSION_OPTIONS } from 'src/constants';

type NewRoleGroupModalProps = {
  isOpen: boolean;
  groups?: AudienceGroup[];
  onSuccess: (values: AudienceGroup) => void;
  onClose: () => void;
};

export const NewRoleGroupModal = (props: NewRoleGroupModalProps) => {
  const { isOpen, groups, onSuccess, onClose } = props;

  const { control, handleSubmit, reset } = useForm<AudienceGroup>();
  const { showErrorToast } = useToast();

  useEffect(() => {
    if (isOpen) {
      const newGroupId = groups && groups.length > 0 ? (groups[groups.length - 1].group_id || 0) + 1 : 1;
      reset({
        id: 0,
        group_id: newGroupId,
        name: '',
        multi_value_grouping_type: 'and',
        group_inclusion_type: 'include',
        rule_grouping_type: 'or',
      });
    }
  }, [groups, isOpen, reset]);

  const onSubmit = (values: AudienceGroup) => {
    if (!values.name) {
      showErrorToast('Please enter role group name');
      return;
    }
    if (groups?.find((group) => group.name === values.name)) {
      showErrorToast(`Role group name existed`);
      return;
    }
    onSuccess(values);
    onClose();
  };

  return (
    <Modal title="New Role Group" isOpen={isOpen} onClose={onClose} onConfirm={handleSubmit(onSubmit)}>
      <Form>
        <FormItem label="Type">
          <ControlledRadioGroup name="group_inclusion_type" control={control} options={GROUP_INCLUSION_OPTIONS} />
        </FormItem>
        <FormItem label="Role Group Name">
          <ControlledInput name="name" control={control} placeholder="Enter role group name" />
        </FormItem>
      </Form>
    </Modal>
  );
};

import { orderBy, take } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { DataTable } from 'src/components/DataTable';
import { Error } from 'src/components/Error';
import { Button, ControlledSelect, ControlledSelectOption, FormAction, Label, Tab, Tabs } from 'src/components/Form';
import { Flex, Grid, Spacing, Text } from 'src/components/Layout';
import { Loading } from 'src/components/Loading';
import { PageTemplate } from 'src/components/Template';
import { CHART_COLORS, TOKENS } from 'src/design';
import { useDataTable, UseDataTableOptions, useWhiteLabelTheme } from 'src/hooks';
import { growthApi } from 'src/services';
import { fixPrecision, formatCount, getApiErrorMessage } from 'src/utils';
import styled from 'styled-components';
import { CustomAudienceShare } from './CustomAudienceShare';

const { useCustomAudiencesQuery } = growthApi;

enum CustomAudienceTab {
  Table = 'table',
  Chart = 'chart',
  Political = 'Political',
}

const PIE_CHARTS = [
  { label: 'State', dataKey: 'state' },
  { label: 'Gender', dataKey: 'gender' },
  { label: 'Martial status', dataKey: 'martialStatus' },
  { label: 'Dwelling type', dataKey: 'dwellingType' },
  { label: 'Education level ', dataKey: 'educationLevel' },
  { label: 'Household income', dataKey: 'homeownerIncome' },
  { label: 'Household size', dataKey: 'householdSize' },
  { label: 'Homeowner status', dataKey: 'homeownerStatus' },
  { label: 'Networth', dataKey: 'netWorth' },
  { label: 'Household presence', dataKey: 'householdPresence' },
  { label: 'Estimated age', dataKey: 'estimatedAge' },
  { label: "Buyer's spectator", dataKey: 'buyerSpectator' },
];

const BAR_CHARTS = [
  {
    label: 'City',
    dataKey: 'city',
  },
  {
    label: 'Zip',
    dataKey: 'zip',
  },
  {
    label: "Buyer's consideration",
    dataKey: 'buyerConsideration',
  },
  {
    label: "Buyer's interest",
    dataKey: 'buyerInterest',
  },
  {
    label: "Buyer's entertainment",
    dataKey: 'buyerEntertainment',
  },
  {
    label: "Buyer's donor type",
    dataKey: 'buyerDonorType',
  },
];

const CUSTOM_AUDIENCE_TABS: Tab[] = [
  {
    label: 'Chart report',
    value: CustomAudienceTab.Chart,
  },
  { label: 'Table report', value: CustomAudienceTab.Table },
  { label: 'Political report', value: CustomAudienceTab.Political },
];

const PERCENTAGE_CATEGORIES = [
  '0% - 10%',
  '11% - 20%',
  '21% - 30%',
  '31% - 40%',
  '41% - 50%',
  '51% - 60%',
  '61% - 70%',
  '71% - 80%',
  '81% - 90%',
  '91% - 100%',
];

const AMOUNT_CATEGORIES = [
  '$0 - $100',
  '$100 - $200',
  '$200 - $300',
  '$300 - $400',
  '$400 - $500',
  '$500 - $600',
  '$600 - $700',
  '$700 - $800',
  '$800 - $900',
  '$900 - $1000',
  '$1000+',
];

const AMOUNT_KEYS = ['Federal Donation Amount Prediction', 'State Donation Amount Prediction'];

const POLITICAL_PARTY_KEYS = [
  'Conservative Party Probability',
  'Democrat Party Probability',
  'Independent Party Probability',
  'Liberal Party Probability',
  'Libertarian Party Probability',
  'Republican Party Probability',
];

const objCovertToArray = (obj?: object) => {
  return Object.entries(obj || {}).map(([key, value]) => ({ label: key, value }));
};

export const CustomAudienceView = () => {
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const isDir = searchParams.get('is_dir');
  const [filters, setFilters] = useState<any>();
  const { watch, control } = useForm<any>();
  const values = watch();
  const { data, isLoading, isFetching, error } = useCustomAudiencesQuery({
    key,
    is_dir: isDir,
    filters: filters ? JSON.stringify(filters) : undefined,
  });
  const [tab, setTab] = useState<CustomAudienceTab>(CustomAudienceTab.Chart);
  const theme = useWhiteLabelTheme();
  const [shareIsOpen, setShareIsOpen] = useState<boolean>(false);
  const isFirstRef = useRef<boolean>(true);
  const [filterData, setFilterData] = useState<any>();

  const chartData = data?.data || {};

  // first data with full options
  useEffect(() => {
    if (data?.data && isFirstRef.current === true) {
      setFilterData(data?.data);
      isFirstRef.current = false;
    }
  }, [data?.data]);

  const generateColumns = (labelHeader: string) => {
    return [
      {
        header: labelHeader,
        accessor: 'label',
        sortable: true,
      },
      {
        header: 'Count',
        accessor: 'value',
        sortable: true,
        width: '12rem',
      },
    ];
  };

  const commonDataTableProps: UseDataTableOptions = {
    idKey: 'label',
    defaultSort: {
      key: 'value',
      direction: 'desc',
    },
    sortNumberKeys: ['value'],
    defaultPageSize: 10,
  };

  const cityData = useMemo(() => {
    return objCovertToArray(chartData.city);
  }, [chartData.city]);

  const zipData = useMemo(() => {
    return objCovertToArray(chartData.zip);
  }, [chartData.zip]);

  const buyerConsiderationData = useMemo(() => {
    return objCovertToArray(chartData.buyerConsideration);
  }, [chartData.buyerConsideration]);

  const buyerInterestData = useMemo(() => {
    return objCovertToArray(chartData.buyerInterest);
  }, [chartData.buyerInterest]);

  const buyerDonorTypeData = useMemo(() => {
    return objCovertToArray(chartData.buyerDonorType);
  }, [chartData.buyerDonorType]);

  const { dataTableProps: cityDataTableProps } = useDataTable({
    ...commonDataTableProps,
    data: cityData,
  });

  const { dataTableProps: zipDataTableProps } = useDataTable({
    ...commonDataTableProps,
    data: zipData,
  });

  const { dataTableProps: buyerConsiderationDataTableProps } = useDataTable({
    ...commonDataTableProps,
    data: buyerConsiderationData,
  });

  const { dataTableProps: buyerInterestDataTableProps } = useDataTable({
    ...commonDataTableProps,
    data: buyerInterestData,
  });

  const { dataTableProps: buyerDonorTypeDataTableProps } = useDataTable({
    ...commonDataTableProps,
    data: buyerDonorTypeData,
  });

  const getFilterOptions = useCallback(
    (key: string): ControlledSelectOption[] => {
      return Object.keys(filterData?.[key] || {}).map((key) => ({
        label: key,
        value: key,
      }));
    },
    [filterData],
  );

  const stateData = orderBy(objCovertToArray(chartData['state']), 'value', 'desc');

  return (
    <PageTemplate isLoading={isLoading}>
      <Text size="xxl" weight={700}>
        View Custom Audience
      </Text>
      <Spacing size="xl" />
      <Flex justify="space-between" align="center">
        <Text size="xl" weight={600}>
          Filters
        </Text>
        <Button width="12rem" onClick={() => setFilters(values)}>
          APPLY
        </Button>
      </Flex>
      <Spacing size="md" />
      <Grid columns={4} gap={['md', 'xl']}>
        {[...PIE_CHARTS, ...BAR_CHARTS].map((chart) => {
          return (
            <Flex key={chart.dataKey} direction="column">
              <Label size="md" weight={500}>
                {chart.label}
              </Label>
              <ControlledSelect
                name={chart.dataKey}
                control={control}
                options={getFilterOptions(chart.dataKey)}
                multiple
              />
            </Flex>
          );
        })}
        {POLITICAL_PARTY_KEYS.map((key) => {
          return (
            <Flex key={key} direction="column">
              <Label size="md" weight={500}>
                {key}
              </Label>
              <ControlledSelect
                name={key}
                control={control}
                options={PERCENTAGE_CATEGORIES.map((category) => ({ label: category, value: category }))}
                multiple
              />
            </Flex>
          );
        })}
      </Grid>
      <Spacing size="xl" />
      <Text size="xl" weight={600}>
        Custom Audience Visualize
      </Text>
      <Spacing size="xl" />
      <Tabs value={tab} onChange={setTab as any} tabs={CUSTOM_AUDIENCE_TABS} />
      <Spacing size="md" />
      {isFetching ? (
        <Loading height="40rem" />
      ) : error ? (
        <Error error={getApiErrorMessage(error)} height="40rem" />
      ) : (
        <>
          {tab === CustomAudienceTab.Chart && (
            <>
              <Grid columns={2} gap="md" justify="center" align="center">
                <BlockContainer>
                  <Flex direction="column" gap="md" align="center">
                    <Text size="xl" weight={600}>
                      {formatCount(chartData.unique)}
                    </Text>
                    <Text size="sm">Unique Devices</Text>
                  </Flex>
                </BlockContainer>
                <BlockContainer>
                  <Flex direction="column" gap="md" align="center">
                    <Text size="xl" weight={600}>
                      {formatCount(chartData.total)}
                    </Text>
                    <Text size="sm">Total Visits</Text>
                  </Flex>
                </BlockContainer>
              </Grid>
              <Spacing />
              <Grid columns={3} gap="md">
                {PIE_CHARTS.map((chart) => (
                  <BlockContainer key={chart.dataKey}>
                    <Text weight={600}>{chart.label}</Text>
                    <Spacing size="xl" />
                    <ReactApexChart
                      options={{ labels: Object.keys(chartData[chart.dataKey] || {}), colors: CHART_COLORS(theme) }}
                      series={Object.values<number>(chartData[chart.dataKey] || {})}
                      type="pie"
                      height="300"
                    />
                  </BlockContainer>
                ))}
              </Grid>
              <Spacing />
              <Grid columns={2} gap="md">
                {BAR_CHARTS.map((chart) => {
                  const barChartData = take(orderBy(objCovertToArray(chartData[chart.dataKey]), 'value', 'desc'), 12);
                  return (
                    <BlockContainer key={chart.dataKey}>
                      <Text weight={600}>{chart.label}</Text>
                      <Spacing size="xl" />
                      <ReactApexChart
                        options={{
                          dataLabels: {
                            enabled: false,
                          },
                          xaxis: {
                            categories: barChartData.map((item) => item.label),
                          },
                          plotOptions: {
                            bar: {
                              horizontal: true,
                            },
                          },
                          colors: CHART_COLORS(theme),
                        }}
                        series={[{ data: barChartData.map((item) => item.value) }]}
                        type="bar"
                        height="300"
                      />
                    </BlockContainer>
                  );
                })}
              </Grid>
              <Spacing />
              <BlockContainer>
                <Text weight={600}>State</Text>
                <Spacing size="xl" />
                <ReactApexChart
                  options={{
                    dataLabels: {
                      enabled: false,
                    },
                    xaxis: {
                      categories: stateData.map((item) => item.label),
                    },
                    colors: CHART_COLORS(theme),
                  }}
                  series={[{ data: stateData.map((item) => item.value) }]}
                  type="bar"
                  height="300"
                />
              </BlockContainer>
            </>
          )}
          {tab === CustomAudienceTab.Table && (
            <Grid columns={2} gap="xl">
              <BlockContainer>
                <DataTable columns={generateColumns('City')} {...cityDataTableProps} />
              </BlockContainer>
              <BlockContainer>
                <DataTable columns={generateColumns('Zip')} {...zipDataTableProps} />
              </BlockContainer>
              <BlockContainer>
                <DataTable columns={generateColumns("Buyer's consideration")} {...buyerConsiderationDataTableProps} />
              </BlockContainer>
              <BlockContainer>
                <DataTable columns={generateColumns("Visitor's interest")} {...buyerInterestDataTableProps} />
              </BlockContainer>
              <BlockContainer>
                <DataTable columns={generateColumns('Donor type')} {...buyerDonorTypeDataTableProps} />
              </BlockContainer>
            </Grid>
          )}
          {tab === CustomAudienceTab.Political && (
            <>
              <Flex direction="column" gap="md">
                <BlockContainer>
                  <Text weight={600}>Political Party Probability</Text>
                  <Spacing size="xl" />
                  <ReactApexChart
                    options={{
                      dataLabels: {
                        enabled: false,
                      },
                      xaxis: {
                        categories: PERCENTAGE_CATEGORIES,
                      },
                      colors: CHART_COLORS(theme),
                    }}
                    series={POLITICAL_PARTY_KEYS.map((key) => ({
                      name: key,
                      data: PERCENTAGE_CATEGORIES.map((category) => chartData[key]?.[category] ?? 0),
                    }))}
                    type="line"
                    height="400"
                  />
                </BlockContainer>
                <Grid columns={3} gap="md">
                  <BlockContainer>
                    <Text weight={600}>Political Party Score</Text>
                    <Spacing size="xl" />
                    <ReactApexChart
                      options={{
                        labels: POLITICAL_PARTY_KEYS.map((key) => key.replace(' Probability', '')),
                        colors: CHART_COLORS(theme),
                      }}
                      series={POLITICAL_PARTY_KEYS.map((key) =>
                        fixPrecision(chartData.politicalPartyScore?.[key.replace(' Probability', '')]),
                      )}
                      type="pie"
                      height="300"
                    />
                  </BlockContainer>
                  <>
                    {[
                      'Presidential Primary Turnout Probability',
                      'Midterm Primary Turnout Probability',
                      'Federal Donation Amount Prediction',
                      'State Donation Amount Prediction',
                      'Presidential General Probability Turnout',
                      'Federal Donor Probability',
                      'State Donor Probability',
                    ].map((key) => (
                      <BlockContainer key={key}>
                        <Text weight={600}>{key}</Text>
                        <Spacing size="xl" />
                        <ReactApexChart
                          options={{
                            dataLabels: {
                              enabled: false,
                            },
                            xaxis: {
                              categories: AMOUNT_KEYS.includes(key) ? AMOUNT_CATEGORIES : PERCENTAGE_CATEGORIES,
                            },
                            colors: CHART_COLORS(theme),
                          }}
                          series={[
                            {
                              data: (AMOUNT_KEYS.includes(key) ? AMOUNT_CATEGORIES : PERCENTAGE_CATEGORIES).map(
                                (category) => chartData[key]?.[category] ?? 0,
                              ),
                            },
                          ]}
                          type={AMOUNT_KEYS.includes(key) ? 'line' : 'bar'}
                          height="300"
                        />
                      </BlockContainer>
                    ))}
                    <BlockContainer>
                      <Text weight={600}>Donation Amount Percentile</Text>
                      <Spacing size="xl" />
                      <ReactApexChart
                        options={{
                          dataLabels: {
                            enabled: false,
                          },
                          xaxis: {
                            categories: PERCENTAGE_CATEGORIES,
                          },
                          colors: CHART_COLORS(theme),
                        }}
                        series={['Federal Donation Amount Percentile', 'State Donation Amount Percentile'].map(
                          (key) => ({
                            name: key,
                            data: PERCENTAGE_CATEGORIES.map((category) => chartData[key]?.[category] ?? 0),
                          }),
                        )}
                        type="line"
                        height="300"
                      />
                    </BlockContainer>
                  </>
                </Grid>
              </Flex>
            </>
          )}
        </>
      )}
      <FormAction submitText="SHARE" disabled={isFetching} onSubmit={() => setShareIsOpen(true)} />
      <CustomAudienceShare data={data?.data} isOpen={shareIsOpen} onClose={() => setShareIsOpen(false)} />
    </PageTemplate>
  );
};

const BlockContainer = styled.div`
  background: white;
  padding: 1.6rem 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
  width: 100%;
  box-sizing: border-box;
`;

import { PageTemplate } from 'src/components/Template';
import { Column, DataTable } from 'src/components/DataTable';
import { growthApi } from 'src/services';
import { formatBoolean, formatDate } from 'src/utils';
import { Action, Button, ControlledInput, Switch } from 'src/components/Form';
import { useEffect, useState } from 'react';
import { Role, User } from 'src/types';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { useForm } from 'react-hook-form';
import { useAppDispatch, userSlice } from 'src/store';
import { UserDSPBindingModal } from 'src/components/UserDSPBindingModal';
import { CreateUserModal } from 'src/components/CreateUserModal';
import { useDataTable } from 'src/hooks';
import { Archive } from 'src/components/Archive';

const { useUsersQuery, useProfileQuery, useUpdateUserProfileMutation } = growthApi;

type UsersFormValues = {
  search?: string;
};

export const Users = () => {
  const { data, isFetching, error, refetch } = useUsersQuery({});
  const { data: profile, refetch: profileRefetch } = useProfileQuery();
  const dispatch = useAppDispatch();
  const [dspBindingIsOpen, setDSPBindingIsOpen] = useState<boolean>(false);
  const [createIsOpen, setCreateIsOpen] = useState<boolean>(false);
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const { control, watch } = useForm<UsersFormValues>();
  const values = watch();

  useEffect(() => {
    if (profile) {
      dispatch(userSlice.actions.updateUser(profile.data));
    }
  }, [dispatch, profile]);

  const { dataTableProps, selectedRow, setSelectedRow, rowStatus, changeRowStatus } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['id', 'email', 'agency_name'],
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    sortNumberKeys: ['is_confirmed', 'is_admin'],
    onChangeRowStatus: async (id: any, field: string, value: any) => {
      await updateUserProfile({
        user_id: id,
        [field]: value,
      }).unwrap();
    },
  });

  const columns: Column[] = [
    { header: 'ID', accessor: 'id', sortable: true },
    { header: 'Email', accessor: 'email', sortable: true },
    {
      header: 'DSP',
      accessor: 'dsp_username',
      sortable: true,
      render: (value: any, user: User) => (
        <Flex gap="sm">
          <Text>{formatBoolean(value)}</Text>
          <Action
            icon="edit"
            onClick={() => {
              setSelectedRow(user);
              setDSPBindingIsOpen(true);
            }}
          />
        </Flex>
      ),
      when: (user?: User) => [Role.Admin].includes(user?.role),
    },
    {
      header: 'Agency',
      accessor: 'agency_name',
      sortable: true,
      render: (value: any) => (value ? value : '-'),
    },
    {
      header: 'Confirmed',
      accessor: 'is_confirmed',
      sortable: true,
      render: (value: any, row: User) => (
        <Switch
          value={rowStatus[row.id]?.is_confirmed ?? Boolean(Number(value))}
          onChange={(value) => changeRowStatus(row.id, 'is_confirmed', value)}
        />
      ),
      when: (user?: User) => [Role.Admin].includes(user?.role),
    },
    {
      header: 'Admin',
      accessor: 'is_admin',
      sortable: true,
      render: (value: any, row: User) => (
        <Switch
          value={rowStatus[row.id]?.is_admin ?? Boolean(Number(value))}
          onChange={(value) => changeRowStatus(row.id, 'is_admin', value)}
        />
      ),
      when: (user?: User) => [Role.Admin].includes(user?.role),
    },
    { header: 'Created At', accessor: 'created_at', render: formatDate, sortable: true },
    {
      header: '',
      accessor: 'action',
      render: (_: any, user: User) => (
        <Flex gap="md">
          <Archive type="user" typeId={user.id} onSuccess={() => refetch()} />
        </Flex>
      ),
    },
  ];

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Users
          </Text>
          <ControlledInput width="30rem" prefix="Search:" name="search" control={control} placeholder="Keyword" />
        </Flex>
        <Button width="16rem" shadow onClick={() => setCreateIsOpen(true)}>
          NEW USER
        </Button>
      </Flex>
      <Spacing size="xl" />
      <DataTable columns={columns} {...dataTableProps} />
      <UserDSPBindingModal
        isOpen={dspBindingIsOpen}
        user={selectedRow}
        onSuccess={() => {
          refetch();
          profileRefetch();
        }}
        onClose={() => setDSPBindingIsOpen(false)}
      />
      <CreateUserModal
        isOpen={createIsOpen}
        onSuccess={() => {
          refetch();
        }}
        onClose={() => setCreateIsOpen(false)}
      />
    </PageTemplate>
  );
};

import { PageTemplate } from 'src/components/Template';
import { Text, Spacing, Flex, FlexItem } from 'src/components/Layout';
import { ControlledSelect, ControlledSelectOption } from 'src/components/Form';
import { useForm } from 'react-hook-form';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { subDays } from 'date-fns';
import { useEffect } from 'react';
import { UserBanner } from 'src/components/UserBanner';
import { ReportingCampaign } from './ReportingCampaign';
import { ReportingSites } from './ReportingSites';
import { ReportingAuctionResults } from './ReportingAuctionResults';
import { DateRange, DATE_RANGE_CUSTOM_OPTIONS, parseDateRange } from 'src/utils';
import { ReportingCreatives } from './ReportingCreatives';
import { ReportingGeo } from './ReportingGEO';
import { useSearchParams } from 'react-router-dom';
import { ReportingApps } from './ReportingApps';
import { useMarkup, useRole, useUserAgencyAdvertiser } from 'src/hooks';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { ReportingPacing } from './ReportingPacing';
import { ReportingCampaignSelect } from 'src/components/ReportingCampaignSelect';

enum ReportingType {
  Campaigns = 'campaigns',
  Sites = 'sites',
  Apps = 'apps',
  Geo = 'geo',
  Creatives = 'creatives',
  AuctionResults = 'auction-results',
  Pacing = 'pacing',
}

export type ReportingFormValues = {
  type?: ReportingType;
  agencyId?: number;
  advertiserId?: number;
  campaignId?: number;
  timeRange?: DateRange;
  dateFrom?: Date;
  dateTo?: Date;
};

const REPORT_TYPE_OPTIONS: ControlledSelectOption[] = [
  { label: 'Campaign', value: ReportingType.Campaigns },
  { label: 'Sites', value: ReportingType.Sites },
  { label: 'Apps', value: ReportingType.Apps },
  { label: 'Geo', value: ReportingType.Geo },
  { label: 'Creatives', value: ReportingType.Creatives },
  { label: 'Auction Results', value: ReportingType.AuctionResults },
  { label: 'Pacing', value: ReportingType.Pacing },
];

export const Reporting = () => {
  const { canAccessAgency } = useRole();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryType = searchParams.get('type');
  const { agencyId, advertiserId, changeAgencyAdvertiser } = useUserAgencyAdvertiser();
  const { control, watch, setValue } = useForm<ReportingFormValues>({
    defaultValues: {
      agencyId,
      advertiserId,
      type: (queryType ?? ReportingType.Campaigns) as ReportingType,
      timeRange: DateRange.Last30Days,
      dateFrom: subDays(new Date(), 30),
      dateTo: new Date(),
    },
  });
  const values = watch();

  useEffect(() => {
    changeAgencyAdvertiser(values.agencyId, values.advertiserId);
  }, [changeAgencyAdvertiser, values.advertiserId, values.agencyId]);

  const markup = useMarkup(values.agencyId, values.advertiserId);

  // update url when type change
  useEffect(() => {
    searchParams.set('type', values.type);
    setSearchParams(searchParams.toString(), { replace: true });
  }, [searchParams, setSearchParams, values.type]);

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex gap="lg" align="center">
          <Text size="xxl" weight={700}>
            Reporting
          </Text>
          <ControlledSelect
            name="type"
            control={control}
            options={REPORT_TYPE_OPTIONS}
            prefix="Type:"
            placeholder="Select type"
            width="22rem"
          />
        </Flex>
        <UserBanner />
      </Flex>
      <Spacing size="xl" />
      <Flex width="100%" gap="md">
        {canAccessAgency && (
          <>
            <FlexItem grow={1}>
              <Flex direction="column" gap="sm">
                <Text size="xs">Agency</Text>
                <AgencySelect
                  name="agencyId"
                  control={control}
                  withAll
                  onValueChange={() => {
                    setValue('advertiserId', undefined);
                    setValue('campaignId', null);
                  }}
                />
              </Flex>
            </FlexItem>
            <FlexItem grow={1}>
              <Flex direction="column" gap="sm">
                <Text size="xs">Advertiser</Text>
                <AdvertiserSelect
                  name="advertiserId"
                  control={control}
                  agencyId={values.agencyId}
                  withAll
                  onValueChange={() => {
                    setValue('campaignId', null);
                  }}
                />
              </Flex>
            </FlexItem>
          </>
        )}
        {values.type !== ReportingType.Pacing && (
          <>
            <FlexItem grow={1}>
              <Flex direction="column" gap="sm">
                <Text size="xs">Campaign</Text>
                <ReportingCampaignSelect
                  name="campaignId"
                  control={control}
                  agencyId={values.agencyId}
                  advertiserId={values.advertiserId}
                  withAll
                />
              </Flex>
            </FlexItem>
            <FlexItem grow={1}>
              <Flex direction="column" gap="sm">
                <Text size="xs">Date Range</Text>
                <ControlledSelect
                  name="timeRange"
                  control={control}
                  options={DATE_RANGE_CUSTOM_OPTIONS}
                  onValueChange={(timeRange) => {
                    const { dateTo, dateFrom } = parseDateRange(timeRange) || {};
                    setValue('dateTo', dateTo);
                    setValue('dateFrom', dateFrom);
                  }}
                />
              </Flex>
            </FlexItem>
            {values.timeRange === DateRange.Custom && (
              <>
                <FlexItem grow={1}>
                  <Flex direction="column" gap="sm">
                    <Text size="xs">Date From</Text>
                    <ControlledDatePicker name="dateFrom" control={control} />
                  </Flex>
                </FlexItem>
                <FlexItem grow={1}>
                  <Flex direction="column" gap="sm">
                    <Text size="xs">Date To</Text>
                    <ControlledDatePicker name="dateTo" control={control} />
                  </Flex>
                </FlexItem>
              </>
            )}
          </>
        )}
      </Flex>
      <Spacing size="lg" />
      {values.type === ReportingType.Campaigns && <ReportingCampaign {...values} markup={markup} />}
      {values.type === ReportingType.Sites && <ReportingSites {...values} markup={markup} />}
      {values.type === ReportingType.Apps && <ReportingApps {...values} markup={markup} />}
      {values.type === ReportingType.Geo && <ReportingGeo {...values} markup={markup} />}
      {values.type === ReportingType.Creatives && <ReportingCreatives {...values} markup={markup} />}
      {values.type === ReportingType.AuctionResults && <ReportingAuctionResults {...values} />}
      {values.type === ReportingType.Pacing && <ReportingPacing {...values} markup={markup} />}
    </PageTemplate>
  );
};

import { Column, DataTable } from 'src/components/DataTable';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { growthApi } from 'src/services';
import { formatPercentage } from 'src/utils';
import { PageTemplate } from 'src/components/Template';
import { Action, Button, ControlledInput, Switch } from 'src/components/Form';
import { useForm } from 'react-hook-form';
import { useDataTable, useRole } from 'src/hooks';
import { Role, User } from 'src/types';
import { useState } from 'react';
import { EditMarkupModal } from 'src/components/EditMarkupModal';
import { EditAgencyModal } from './EditAgencyModal';
import { Archive } from 'src/components/Archive';

const { useAgenciesQuery, useUpdateAgencyExtMutation } = growthApi;

type AgenciesFormValues = {
  search?: string;
};

export const Agencies = () => {
  const { isAdmin } = useRole();
  const { data, error, isFetching, refetch } = useAgenciesQuery();
  const [updateAgencyExt] = useUpdateAgencyExtMutation();
  const { watch, control } = useForm<AgenciesFormValues>();
  const values = watch();
  const [editAgencyIsOpen, setEditAgencyIsOpen] = useState(false);
  const { dataTableProps, selectedRow, setSelectedRow, rowStatus, changeRowStatus } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['id', 'name'],
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    sortNumberKeys: ['id', 'is_white_label', 'markup'],
    onChangeRowStatus: async (id: any, field: string, value: any) => {
      await updateAgencyExt({
        agency_id: id,
        [field]: value,
      }).unwrap();
    },
  });
  const [editMarkupIsOpen, setEditMarkupIsOpen] = useState<boolean>(false);

  const columns: Column[] = [
    { header: 'ID', accessor: 'id', sortable: true },
    { header: 'Agency Name', accessor: 'name', sortable: true },
    {
      header: 'White Label',
      accessor: 'is_white_label',
      render: (value: any, row: any) => (
        <Switch
          value={rowStatus[row.id]?.is_white_label ?? Boolean(value)}
          onChange={(value) => changeRowStatus(row.id, 'is_white_label', value)}
        />
      ),
      sortable: true,
      when: (user?: User) => [Role.Admin].includes(user?.role),
    },
    {
      header: 'Markup',
      accessor: 'markup',
      render: (value: any, row: any) =>
        row.is_white_label ? (
          <Flex gap="sm">
            <Text>{value ? formatPercentage(value) : '-'}</Text>
            <Action
              icon="edit"
              onClick={() => {
                setSelectedRow(row);
                setEditMarkupIsOpen(true);
              }}
            />
          </Flex>
        ) : (
          '-'
        ),
      sortable: true,
      when: (user?: User) => [Role.Admin].includes(user?.role),
    },
    {
      header: '',
      accessor: 'action',
      width: '3rem',
      render: (_, row) => (
        <Flex gap="md">
          <Action
            onClick={() => {
              setSelectedRow(row);
              setEditAgencyIsOpen(true);
            }}
          >
            Edit
          </Action>
          <Archive type="agency" typeId={row.id} onSuccess={() => refetch()} />
        </Flex>
      ),
      when: (user?: User) => [Role.Admin].includes(user?.role),
    },
  ];

  return (
    <PageTemplate>
      <Flex align="center" justify="space-between">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Agencies
          </Text>
          <ControlledInput width="30rem" prefix="Search:" name="search" control={control} placeholder="Keyword" />
        </Flex>
        {isAdmin && (
          <Button
            width="auto"
            shadow
            onClick={() => {
              setSelectedRow(undefined);
              setEditAgencyIsOpen(true);
            }}
          >
            NEW AGENCY
          </Button>
        )}
      </Flex>
      <Spacing size="xl" />
      <DataTable columns={columns} {...dataTableProps} />
      <EditMarkupModal
        isOpen={editMarkupIsOpen}
        onClose={() => setEditMarkupIsOpen(false)}
        onSuccess={() => refetch()}
        type="agency"
        id={selectedRow?.id}
      />
      <EditAgencyModal
        isOpen={editAgencyIsOpen}
        agency={selectedRow}
        onClose={() => setEditAgencyIsOpen(false)}
        onSuccess={() => {
          setEditAgencyIsOpen(false);
          refetch();
        }}
      />
    </PageTemplate>
  );
};

import { get, set } from 'lodash';

export const transformNumber = (value: any) => {
  if (value === undefined || value === null) {
    return undefined;
  }
  return Number(value);
};

export const transformNumberArray = (value: any) => {
  if (value === undefined || value === null) {
    return undefined;
  }
  if (typeof value === 'string') {
    return value.split('|').map(Number);
  }
  return value.map(Number);
};

export const transformDate = (value: any) => {
  if (!value) {
    return undefined;
  }
  return new Date(value);
};

export enum TransformType {
  Number,
  NumberArray,
  Date,
  None,
}

type TransformParams = {
  [path: string]: TransformType;
};

export const transformObject = (source: object, transformParams: TransformParams) => {
  const result: any = {};
  Object.entries(transformParams).forEach(([path, type]) => {
    let value = get(source, path);
    switch (type) {
      case TransformType.Number:
        value = transformNumber(value);
        break;
      case TransformType.NumberArray:
        value = transformNumberArray(value);
        break;
      case TransformType.Date:
        value = transformDate(value);
        break;
    }
    set(result, path, value);
  });
  return result;
};

import { Icon } from 'src/components/Icon';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { TOKENS } from 'src/design';
import { ChangeEvent, DragEvent, ReactNode, useRef } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import styled from 'styled-components';
import { useToast } from 'src/hooks';
import { Tag } from './Tag';

type ControlledFileProps<T> = UseControllerProps<T> & {
  notes?: ReactNode;
  filename?: string;
  disabled?: boolean;
  disableReason?: string;
  multiple?: boolean;
};

export const ControlledFile = <T,>(props: ControlledFileProps<T>) => {
  const { notes, filename, disabled = false, disableReason, multiple, ...useControllerProps } = props;

  const { showErrorToast } = useToast();

  const {
    field: { value, onChange },
  } = useController(useControllerProps);
  const inputRef = useRef<HTMLInputElement>(null);

  const typedValue = value as any;

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    multiple ? onChange([...(typedValue || []), ...Array.from(files)]) : onChange(files?.[0]);
    event.target.value = null;
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.items.length > 0) {
      const items = event.dataTransfer.items;
      multiple
        ? onChange([...(typedValue || []), ...Array.from(items).map((item) => item.getAsFile())])
        : onChange(items?.[0]?.getAsFile());
    } else if (event.dataTransfer.files.length > 0) {
      const files = event.dataTransfer.files;
      multiple ? onChange([...(typedValue || []), ...Array.from(files)]) : onChange(files?.[0]);
    }
  };

  const onRemoveItem = (index?: number) => {
    if (index === undefined) {
      onChange(null);
    } else {
      onChange(typedValue.filter((_item: any, itemIndex: number) => itemIndex !== index));
    }
  };

  return (
    <Container>
      <Flex
        direction="column"
        align="center"
        gap="sm"
        width="100%"
        onDrop={onDrop}
        onDragOver={(event) => event.preventDefault()}
        onDragEnter={(event) => event.preventDefault()}
        onDragLeave={(event) => event.preventDefault()}
      >
        <UploadImage
          onClick={() => {
            if (disabled) {
              showErrorToast(disableReason);
              return;
            }
            inputRef.current?.click();
          }}
        >
          <Icon type="file" size="lg" />
          <Text size="sm" weight={500}>
            Upload File{multiple ? 's' : ''}
          </Text>
          <input ref={inputRef} type="file" onChange={onFileChange} multiple={multiple} />
        </UploadImage>
        <Spacing size="sm" />
        {value &&
          (multiple ? (
            <>
              {typedValue.map((item: any, index: number) => (
                <Tag key={index} onClose={() => onRemoveItem(index)}>
                  {item.name}
                </Tag>
              ))}
            </>
          ) : (
            <>
              <Tag onClose={() => onRemoveItem()}>{typedValue.name}</Tag>
            </>
          ))}
        {notes && <Spacing />}
        {notes}
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  background: white;
  padding: 2.4rem 4.8rem;
  border-radius: 1rem;
  box-shadow: ${TOKENS.shadow.default};
`;

const UploadImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${TOKENS.spacing.sm};
  cursor: pointer;

  input {
    display: none;
  }

  svg {
    width: 4rem;
    height: 4rem;
    fill: ${(props) => props.theme.color.primary};
  }
`;

import { TOKENS } from 'src/design';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from 'src/components/Form';

type ModalProps = {
  isOpen: boolean;
  title: string;
  confirmText?: string;
  cancelText?: string;
  isLoading?: boolean;
  disabled?: boolean;
  width?: string;
  onConfirm: () => void;
  onClose: () => void;
  children?: ReactNode;
};

export const Modal = (props: ModalProps) => {
  const {
    isOpen,
    title,
    confirmText = 'CONFIRM',
    cancelText = 'CANCEL',
    isLoading,
    disabled,
    width,
    onConfirm,
    onClose,
    children,
  } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <ModalMask />
      <ModalContainer>
        <Content width={width}>
          <Header>{title}</Header>
          <Body>{children}</Body>
          <Footer>
            <Button various="secondary" width="20rem" onClick={onClose}>
              {cancelText}
            </Button>
            <Button
              various="primary"
              shadow
              isLoading={isLoading}
              width="20rem"
              onClick={onConfirm}
              disabled={disabled}
            >
              {confirmText}
            </Button>
          </Footer>
        </Content>
      </ModalContainer>
    </>
  );
};

const ModalMask = styled.div`
  position: fixed;
  z-index: 400;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalContainer = styled.div`
  position: fixed;
  z-index: 500;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div<{ width?: string }>`
  background: white;
  width: ${(props) => props.width ?? '60rem'};
  border-radius: 0.6rem;
  box-shadow: ${TOKENS.shadow.default};
  padding: 2.4rem;
  box-sizing: border-box;
  max-height: 90vh;
  overflow-y: auto;
`;

const Header = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
`;

const Body = styled.div`
  padding: 2.4rem 0;
`;

const Footer = styled.div`
  padding: 1.2rem 0;
  display: flex;
  justify-content: center;
  gap: 3.6rem;
`;

import { Button, Input } from 'src/components/Form';
import { Spacing } from 'src/components/Layout';
import styled from 'styled-components';
import { growthApi } from 'src/services';
import { useForm } from 'react-hook-form';
import { useAppDispatch, userSlice } from 'src/store';
import { Link, useNavigate } from 'react-router-dom';
import { AuthTemplate } from 'src/components/Template';
import { useToast } from 'src/hooks';
import { getApiErrorMessage, isCustomDomain } from 'src/utils';

const { useLoginMutation } = growthApi;

type LoginFormValues = {
  email: string;
  password: string;
};

export const Login = () => {
  const [login] = useLoginMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showErrorToast } = useToast();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<LoginFormValues>();

  const values: LoginFormValues = watch();
  const isDisabled = !values.email || !values.password;

  const onSubmit = async (values: LoginFormValues) => {
    try {
      const apiResult = await login(values).unwrap();
      dispatch(userSlice.actions.login(apiResult.data!));
      navigate('/activate/overview');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
      setValue('password', '');
    }
  };

  return (
    <AuthTemplate title="Sign in">
      <Input {...register('email')} placeholder="Enter your Email" icon="email" various="border" />
      <Spacing size="1.8rem" />
      <Input {...register('password')} type="password" placeholder="Enter password" icon="password" various="border" />
      <Spacing size="1.2rem" />
      <Button onClick={handleSubmit(onSubmit)} disabled={isDisabled} isLoading={isSubmitting}>
        Login
      </Button>
      {!isCustomDomain() && (
        <>
          <ForgetPassword to="/forget-password">Forgot your password?</ForgetPassword>
          <Register to="/signup">Create your account</Register>
        </>
      )}
    </AuthTemplate>
  );
};

const ForgetPassword = styled(Link)`
  margin-top: 1.2rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.primary};
  border-bottom: solid 0.2rem ${(props) => props.theme.color.primary};
  text-decoration: none;
`;

const Register = styled(Link)`
  color: ${(props) => props.theme.color.primary};
  font-weight: 600;
  margin-top: 2.4rem;
  text-decoration: none;
`;

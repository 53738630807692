import { compact, get, orderBy } from 'lodash';
import { useMemo, useState, useRef, useEffect } from 'react';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, DataTableRef, Sort } from 'src/components/DataTable';
import { getApiErrorMessage } from 'src/utils';

export type UseDataTableOptions = {
  idKey?: string;
  data?: any[];
  error?: any;
  isLoading?: boolean;
  defaultPageSize?: number;
  search?: string;
  searchKeys?: any[];
  defaultSort?: Sort;
  sortNumberKeys?: any[];
  sortMapping?: Record<string, (value: any) => any>;
  onChangeRowStatus?: (id: any, field: string, value: any, row?: any) => void | Promise<void>;
  onExpend?: (id: any) => any | Promise<any>;
};

export const useDataTable = (options: UseDataTableOptions) => {
  const {
    idKey,
    data,
    error,
    isLoading,
    defaultPageSize = DEFAULT_PAGE_SIZE,
    search,
    searchKeys,
    defaultSort,
    sortNumberKeys,
    sortMapping,
    onChangeRowStatus,
    onExpend,
  } = options;

  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [sort, setSort] = useState<Sort>(defaultSort || {});
  const [rowStatus, setRowStatus] = useState<Record<string, any>>({});
  const [selectedRow, setSelectedRow] = useState<any>();
  const [expendIds, setExpendIds] = useState<any[]>([]);
  const [expendLoadingIds, setExpendLoadingIds] = useState<any[]>([]);
  const [expendData, setExpendData] = useState<Record<any, any>>({});
  const dataTableRef = useRef<DataTableRef>();

  const changeRowStatus = async (id: any, field: string, value: any, row?: any) => {
    await onChangeRowStatus?.(id, field, value, row);
    setRowStatus((rowStatus) => ({
      ...rowStatus,
      [id]: {
        ...rowStatus[id],
        [field]: value,
      },
    }));
  };

  const isExpend = (id: any) => {
    return expendIds.includes(id);
  };

  const isExpendLoading = (id: any) => {
    return expendLoadingIds.includes(id);
  };

  const triggerExpend = async (id: any) => {
    // if id is expend, close it, else load expend data
    if (expendIds.includes(id)) {
      setExpendIds((expendIds) => expendIds.filter((expendId) => expendId !== id));
    } else {
      if (!expendData[id]) {
        setExpendLoadingIds((expendLoadingIds) => [...expendLoadingIds, id]);
        const newExpendData = await onExpend?.(id);
        setExpendLoadingIds((expendLoadingIds) => expendLoadingIds.filter((loadingId) => loadingId !== id));
        setExpendData((expendData) => ({
          ...expendData,
          [id]: newExpendData,
        }));
      }
      setExpendIds((expendIds) => [...expendIds, id]);
    }
  };

  const getExpendData = () => {
    let result: any = [];
    expendIds.forEach((expendId) => (result = [...result, ...expendData[expendId]]));
    return result;
  };

  const clearExpendData = () => {
    setExpendIds([]);
    setExpendData({});
  };

  // set page to 1 when sort & search change
  useEffect(() => {
    setPage(1);
  }, [sort, search]);

  // clear expend data, if data is change
  useEffect(() => {
    clearExpendData();
  }, [data]);

  const visibleData = useMemo(() => {
    // combine data & row status
    let result = (data || []).map((item) => ({
      ...item,
      ...rowStatus[get(item, idKey)],
    }));
    // search filter
    if (search && searchKeys) {
      result = result.filter((row) =>
        compact(searchKeys.map((searchKey) => get(row, searchKey))).some((value) => {
          return String(value)
            .toLowerCase()
            .includes(search?.toLowerCase() || '');
        }),
      );
    }
    // order
    if (sort) {
      result = orderBy(
        result,
        (item) =>
          sortMapping?.[sort.key]
            ? sortMapping?.[sort.key](get(item, sort.key))
            : sortNumberKeys?.includes(sort.key)
            ? Number(get(item, sort.key)) || 0
            : get(item, sort.key) ?? '',
        sort.direction,
      );
    }
    // current page
    return result.filter((_, index) => index >= (page - 1) * pageSize && index < page * pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, search, searchKeys, sort, idKey, sortMapping, sortNumberKeys, page, pageSize]);

  return {
    // page
    setPage,
    setPageSize,
    // selected row
    selectedRow,
    setSelectedRow,
    // row status
    rowStatus,
    changeRowStatus,
    // expend
    isExpend,
    isExpendLoading,
    triggerExpend,
    getExpendData,
    // ref
    dataTableRef,
    // generate data table props
    dataTableProps: {
      idKey,
      visibleData,
      data: data || [],
      isLoading,
      error: getApiErrorMessage(error),
      total: data?.length || 0,
      page,
      onPageChange: setPage,
      pageSize,
      sort,
      onSortChange: setSort,
      expendIds,
      expendData,
      ref: dataTableRef,
    },
  };
};

import { Button, ControlledFile, Form, FormItem } from 'src/components/Form';
import { ControlledInput } from 'src/components/Form/ControlledInput';
import { FormAction } from 'src/components/Form/FormAction';
import { PageTemplate } from 'src/components/Template';
import { Spacing, Text } from 'src/components/Layout';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { AudienceGroups } from './AudienceGroups';
import { NewRoleGroupModal } from './NewRoleGroupModal';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { growthApi } from 'src/services';
import { fileToBase64, getApiErrorMessage, transformObject, TransformType } from 'src/utils';
import { useRole, useToast, useUserAgencyAdvertiser } from 'src/hooks';
import { AgencySelect } from 'src/components/AgencySelect';
import { RuleResponseType } from 'src/types';
import { useAppSelector } from 'src/store';
import { EditTargetingRuleModal } from 'src/components/EditTargetingRuleModal';
import { DraftSupport } from 'src/components/DraftSupport';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { TOKENS } from 'src/design';
import { Icon, IconProps } from 'src/components/Icon';
import { cloneDeep } from 'lodash';

const { useAudiencesQuery, useCreateOrUpdatePixelMutation, useCreateOrUpdateAudienceMutation } = growthApi;

export type AudienceGroup = {
  id?: number;
  group_id?: number;
  name?: string;
  group_inclusion_type?: 'include' | 'exclude';
  multi_value_grouping_type?: 'and' | 'or';
  rule_grouping_type?: 'and' | 'or';
};

export type AudienceRule = {
  id?: number;
  audience_group_id?: number;
  rule_inclusion_type?: 'include' | 'exclude';
  rule_category_id?: number;
  rule_type_id?: number;
  rule_type_id_name?: string;
  rule_response_type?: RuleResponseType;
  rule_sub_type_id?: number;
  rule_type_name?: string;
  rule_value?: any;
  rule_value_additional?: any;
  rule_value_name?: {
    full_path?: string;
    id?: number;
    name?: string;
  }[];
  multi_value_grouping_type?: 'or' | 'and';
  open_data_provider_modal?: boolean;
};

enum AudienceStrategy {
  Custom = 'custom',
  PreBuilt = 'pre_built',
  GeoFencing = 'geo_fencing',
  Lookalikes = 'lookalikes',
  ReMarketing = 're_marketing',
  ContextAndKeywords = 'context_keywords',
  TargetSites = 'target_sites',
  StartFromScratch = 'start_from_scratch',
}

enum AudienceStep {
  Strategy = 'strategy',
  Form = 'form',
  Custom = 'custom',
}

export type AudienceFormValues = {
  name?: string;
  agency_id?: number;
  advertiser_id?: number;
  file?: File;
  global_grouping_settings?: {
    include_rules?: 'and' | 'or';
    exclude_rules?: 'and' | 'or';
  };
  no_group_settings?: {
    include_grouping: 'and' | 'or';
    exclude_grouping: 'and' | 'or';
  };
  no_group_rulegrouping?: {
    include_grouping?: 'and' | 'or';
    exclude_grouping?: 'and' | 'or';
  };
  rule_grouping_mode?: string;
  groups?: AudienceGroup[];
  rules?: AudienceRule[];
  white_label_id?: number;
  step?: AudienceStep;
  strategy?: AudienceStrategy;
  preset_rule?: AudienceRule;
};

const AUDIENCE_STRATEGIES: { label: string; icon?: IconProps['type']; value: AudienceStrategy }[] = [
  {
    label: 'Upload a custom audience',
    icon: 'file',
    value: AudienceStrategy.Custom,
  },
  {
    label: 'Select a pre-built audience',
    icon: 'preBuilt',
    value: AudienceStrategy.PreBuilt,
  },
  {
    label: 'GeoFencing',
    icon: 'geo',
    value: AudienceStrategy.GeoFencing,
  },
  {
    label: 'Lookalikes',
    icon: 'copy',
    value: AudienceStrategy.Lookalikes,
  },
  {
    label: 'ReMarketing',
    icon: 'reMarketing',
    value: AudienceStrategy.ReMarketing,
  },
  {
    label: 'Context & keywords',
    icon: 'keywords',
    value: AudienceStrategy.ContextAndKeywords,
  },
  {
    label: 'Target sites',
    icon: 'sites',
    value: AudienceStrategy.TargetSites,
  },
  {
    label: 'Start from scratch',
    icon: 'edit',
    value: AudienceStrategy.StartFromScratch,
  },
];

export const AudienceEdit = () => {
  const { canAccessAgency, whiteLabelId } = useRole();
  const { agencyId, advertiserId } = useUserAgencyAdvertiser();
  const user = useAppSelector((state) => state.user.user);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const copy = searchParams.get('copy');
  const from = searchParams.get('from');
  const advertiserIdFromUrl = searchParams.get('advertiser_id') ?? undefined;
  const isNew = !id;
  const { data: audienceData, isLoading: isAudienceLoading } = useAudiencesQuery(
    { id: id || copy, advertiser_id: advertiserIdFromUrl },
    { skip: isNew && !copy },
  );
  const { data: audienceRulesData, isLoading: isAudienceRulesLoading } = useAudiencesQuery(
    { id: id || copy, type: 'rules', advertiser_id: advertiserIdFromUrl },
    { skip: isNew && !copy },
  );
  const [createOrUpdateAudience, { isLoading }] = useCreateOrUpdateAudienceMutation();
  const [createOrUpdatePixel] = useCreateOrUpdatePixelMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const exclusionRules = useMemo(() => user?.dsp_exclusion_rules || [], [user?.dsp_exclusion_rules]);
  const defaultValues: AudienceFormValues = useMemo(
    () => ({
      agency_id: agencyId,
      advertiser_id: advertiserId,
      global_grouping_settings: {
        include_rules: 'or',
        exclude_rules: 'or',
      },
      no_group_settings: {
        include_grouping: 'or',
        exclude_grouping: 'or',
      },
      no_group_rulegrouping: {
        include_grouping: 'or',
        exclude_grouping: 'or',
      },
      rule_grouping_mode: 'multirow',
      groups: [],
      rules: exclusionRules,
      white_label_id: whiteLabelId,
      step: AudienceStep.Strategy,
    }),
    [advertiserId, agencyId, exclusionRules, whiteLabelId],
  );
  const useFormReturns = useForm<AudienceFormValues>({
    defaultValues,
  });
  const { handleSubmit, control, watch, setValue, reset } = useFormReturns;
  const [isStrategyPreparing, setIsStrategyPreparing] = useState<boolean>(false);
  const [newRoleGroupIsOpen, setNewRoleGroupIsOpen] = useState<boolean>(false);
  const [editTargetingRuleIsOpen, setEditTargetingRuleIsOpen] = useState<boolean>(false);
  const [editRuleIndex, setEditRuleIndex] = useState<number>();

  useEffect(() => {
    if (audienceData && audienceRulesData) {
      const audienceTransformedData = transformObject(audienceData?.data, {
        id: TransformType.Number,
        name: TransformType.None,
        agency_id: TransformType.Number,
        advertiser_id: TransformType.Number,
        global_grouping_include: TransformType.None,
        global_grouping_exclude: TransformType.None,
        rule_grouping_include: TransformType.None,
        rule_grouping_exclude: TransformType.None,
      });
      // change name & delete id if copy
      if (copy) {
        audienceTransformedData.name = `${audienceTransformedData.name} copy`;
        delete audienceTransformedData.id;
      }
      reset({
        ...audienceTransformedData,
        global_grouping_settings: {
          include_rules: audienceTransformedData.global_grouping_include || 'or',
          exclude_rules: audienceTransformedData.global_grouping_exclude || 'or',
        },
        no_group_rulegrouping: {
          include_grouping: audienceTransformedData.rule_grouping_include || 'or',
          exclude_grouping: audienceTransformedData.rule_grouping_exclude || 'or',
        },
        groups: audienceRulesData?.data?.groups || [],
        rules: audienceRulesData?.data?.rules || [],
        step: AudienceStep.Form,
      });
    }
  }, [audienceData, audienceRulesData, copy, reset]);

  const values = watch();
  const hasIncludeRule = Boolean(values.rules?.find((rule) => rule.rule_inclusion_type === 'include'));

  const onSelectStrategy = (strategy: AudienceStrategy) => {
    switch (strategy) {
      case AudienceStrategy.PreBuilt:
        setValue('preset_rule', {
          audience_group_id: 0,
          multi_value_grouping_type: 'or',
          rule_category_id: 10,
          rule_inclusion_type: 'include',
          rule_response_type: 5,
          rule_sub_type_id: -1,
          rule_type_id: 27,
          rule_type_id_name: '3rd Party Audiences',
          rule_type_name: 'Data Provider',
          open_data_provider_modal: true,
        });
        setEditTargetingRuleIsOpen(true);
        break;
      case AudienceStrategy.GeoFencing:
        setValue('preset_rule', {
          audience_group_id: 0,
          multi_value_grouping_type: 'or',
          rule_category_id: 3,
          rule_inclusion_type: 'include',
          rule_type_id: 13,
          rule_type_id_name: 'Geo',
          rule_sub_type_id: 2,
          rule_type_name: 'Geo Fence',
          rule_response_type: 5,
        });
        setEditTargetingRuleIsOpen(true);
        break;
      case AudienceStrategy.Lookalikes:
      case AudienceStrategy.ReMarketing:
        setValue('preset_rule', {
          audience_group_id: 0,
          rule_type_id: 25,
          rule_category_id: 8,
          rule_sub_type_id: 0,
          multi_value_grouping_type: 'or',
          rule_inclusion_type: 'include',
          rule_type_id_name: 'My Pixels',
          rule_type_name: 'Pixels',
          rule_response_type: 5,
        });
        setEditTargetingRuleIsOpen(true);
        break;
      case AudienceStrategy.ContextAndKeywords:
        setValue('preset_rule', {
          rule_inclusion_type: 'include',
          audience_group_id: 0,
          rule_type_id: 0,
          rule_category_id: 1,
          rule_sub_type_id: 0,
          multi_value_grouping_type: 'or',
          rule_type_id_name: 'Context & Keywords',
        });
        setEditTargetingRuleIsOpen(true);
        break;
      case AudienceStrategy.TargetSites:
        setValue('preset_rule', {
          rule_inclusion_type: 'include',
          audience_group_id: 0,
          rule_type_id: 0,
          rule_category_id: 2,
          rule_sub_type_id: 0,
          multi_value_grouping_type: 'or',
          rule_type_id_name: 'Sites & Apps',
        });
        setEditTargetingRuleIsOpen(true);
        break;
      case AudienceStrategy.Custom:
      case AudienceStrategy.StartFromScratch:
        setValue('preset_rule', undefined);
        break;
    }
    setValue('strategy', strategy);
    setValue('step', AudienceStep.Form);
    setValue('rules', exclusionRules);
  };

  const onAddNewRoleGroup = useCallback(
    (group: AudienceGroup) => {
      setValue('groups', [...(values.groups || []), group]);
    },
    [setValue, values.groups],
  );

  const onEditTargetingRule = useCallback(
    (rule: AudienceRule) => {
      if (editRuleIndex !== undefined) {
        const copiedRules = [...(values.rules || [])];
        copiedRules[editRuleIndex] = rule;
        setValue('rules', copiedRules);
      } else {
        setValue('rules', [...(values.rules || []), rule]);
      }
      setEditTargetingRuleIsOpen(false);
    },
    [editRuleIndex, setValue, values.rules],
  );

  const onRuleRemove = (ruleIndex: number) => {
    setValue(
      'rules',
      values.rules?.filter((_, index) => index !== ruleIndex),
    );
  };

  const onRuleEdit = (ruleIndex: number) => {
    setEditRuleIndex(ruleIndex);
    setEditTargetingRuleIsOpen(true);
  };

  const onValidate = (values: AudienceFormValues) => {
    if (isNew && !values.agency_id) {
      showErrorToast('Please select agency');
      return false;
    }
    // some old audience don't have advertiser_id
    if (isNew && !values.advertiser_id) {
      showErrorToast('Please select advertiser');
      return false;
    }
    if (!values.name) {
      showErrorToast('Please enter audience name');
      return false;
    }
    if (values.strategy === AudienceStrategy.Custom) {
      if (!values.file) {
        showErrorToast('Please upload custom audience file');
        return false;
      }
    } else {
      if (!values.rules?.find((rule) => rule.rule_inclusion_type === 'include')) {
        showErrorToast('Please add include rule');
        return false;
      }
      if (values.strategy === AudienceStrategy.Lookalikes) {
        // check if added pixels rules
        if (!values.rules?.find((rule) => rule.rule_inclusion_type === 'include' && rule.rule_type_id === 25)) {
          showErrorToast('Please add pixel include rule for create lookalikes audience');
        }
      }
    }
    return true;
  };

  const onStrategyPrepare = async (values: AudienceFormValues) => {
    const { file, ...rest } = values;
    const copiedValues: any = cloneDeep(rest);
    switch (values.strategy) {
      case AudienceStrategy.Custom: {
        copiedValues.file = await fileToBase64(file);
        // generate same name pixel, and add to rules
        const newPixel = await createOrUpdatePixel({
          agency_id: values.agency_id,
          advertiser_id: values.advertiser_id,
          name: values.name,
        }).unwrap();
        const newPixelAdlibId = newPixel.data.adlib_id;
        copiedValues.rules.push({
          audience_group_id: 0,
          rule_type_id: 25,
          rule_category_id: 8,
          rule_sub_type_id: 0,
          multi_value_grouping_type: 'or',
          rule_inclusion_type: 'include',
          rule_type_id_name: 'My Pixels',
          rule_type_name: 'Pixels',
          rule_response_type: 5,
          rule_value: [newPixelAdlibId],
        });
        break;
      }
      case AudienceStrategy.Lookalikes: {
        // generate pixel LAL - xxx, and replace to current pixel
        const pixelRule = copiedValues.rules?.find(
          (rule: any) => rule.rule_inclusion_type === 'include' && rule.rule_type_id === 25,
        );
        const newPixel = await createOrUpdatePixel({
          agency_id: values.agency_id,
          advertiser_id: values.advertiser_id,
          name: `LAL - ${pixelRule.rule_value[0]}`,
        }).unwrap();
        const newPixelAdlibId = newPixel.data.adlib_id;
        pixelRule.rule_value = [newPixelAdlibId];
        break;
      }
    }
    return copiedValues;
  };

  const onSubmit = async (values: AudienceFormValues) => {
    try {
      if (!onValidate(values)) {
        return false;
      }
      setIsStrategyPreparing(true);
      const strategyPreparedValues = await onStrategyPrepare(values);
      setIsStrategyPreparing(false);
      const { data } = await createOrUpdateAudience(strategyPreparedValues).unwrap();
      if (isNew) {
        showSuccessToast('Create audience successfully');
        localStorage.removeItem('audience_draft');
        if (from === 'campaign') {
          navigate(`/activate/campaigns/new?draft=1&draft_params=${JSON.stringify({ audience_id: data.id })}`);
        } else {
          navigate('/activate/audiences');
        }
      } else {
        showSuccessToast('Save audience successfully');
        navigate('/activate/audiences');
      }
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <PageTemplate isLoading={isAudienceLoading || isAudienceRulesLoading}>
      <Title>{isNew ? 'New Audience' : 'Edit Audience'}</Title>
      <FormProvider {...useFormReturns}>
        {values.step === AudienceStep.Strategy && (
          <FormItem>
            <TypeContainer>
              {AUDIENCE_STRATEGIES.map((type) => (
                <Type key={type.value} onClick={() => onSelectStrategy(type.value)}>
                  <Icon type={type.icon} />
                  <Text size="md" weight={600}>
                    {type.label}
                  </Text>
                </Type>
              ))}
            </TypeContainer>
          </FormItem>
        )}
        {values.step === AudienceStep.Form && (
          <Form width="70%">
            {canAccessAgency && isNew && (
              <>
                <FormItem label="Agency" required>
                  <AgencySelect
                    name="agency_id"
                    control={control}
                    onValueChange={() => setValue('advertiser_id', undefined)}
                  />
                </FormItem>
                <FormItem label="Advertiser" required>
                  <AdvertiserSelect agencyId={values.agency_id} name="advertiser_id" control={control} />
                </FormItem>
              </>
            )}
            <FormItem label="Audience Name" required>
              <ControlledInput name="name" control={control} placeholder="Enter audience name" />
            </FormItem>
            {values.strategy === AudienceStrategy.Custom ? (
              <FormItem label="Custom Audience File" required>
                <ControlledFile name="file" control={control} />
              </FormItem>
            ) : (
              <FormItem label="Targeting Rules" required>
                <ButtonContainer>
                  <Button width="22rem" various="secondary" rounded={false} onClick={() => setNewRoleGroupIsOpen(true)}>
                    ADD NEW ROLE GROUP
                  </Button>
                  <Button
                    width="22rem"
                    rounded={false}
                    onClick={() => {
                      setEditRuleIndex(undefined);
                      setEditTargetingRuleIsOpen(true);
                    }}
                  >
                    ADD NEW TARGETING RULE
                  </Button>
                </ButtonContainer>
                <Spacing size="md" />
                <Text size="xs">Targeting rules for this Audience can be seen below:</Text>
                <Spacing size="md" />
                <AudienceGroups onRuleEdit={onRuleEdit} onRuleRemove={onRuleRemove} />
              </FormItem>
            )}
            <FormAction
              onSubmit={handleSubmit(onSubmit)}
              onBack={isNew && !copy ? () => setValue('step', AudienceStep.Strategy) : undefined}
              isSubmitting={isLoading || isStrategyPreparing}
            />
          </Form>
        )}
      </FormProvider>
      <NewRoleGroupModal
        isOpen={newRoleGroupIsOpen}
        groups={values.groups}
        onSuccess={onAddNewRoleGroup}
        onClose={() => setNewRoleGroupIsOpen(false)}
      />
      <EditTargetingRuleModal
        isOpen={editTargetingRuleIsOpen}
        groups={values.groups}
        // use template default rule, when don't exists include rule
        rule={
          editRuleIndex !== undefined ? values.rules?.[editRuleIndex] : hasIncludeRule ? undefined : values.preset_rule
        }
        agencyId={values.agency_id}
        advertiserId={values.advertiser_id}
        onSuccess={onEditTargetingRule}
        onClose={() => setEditTargetingRuleIsOpen(false)}
      />
      <DraftSupport
        draftKey="audience_draft"
        defaultValues={defaultValues}
        values={values}
        onUseDraft={reset}
        enabled={isNew && !copy}
      />
    </PageTemplate>
  );
};

const Title = styled.div`
  font-size: 3.6rem;
  font-weight: 700;
  padding-bottom: 2.4rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1.2rem;
`;

const TypeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  padding-top: 1.2rem;

  svg {
    width: 4rem;
    height: 4rem;
  }
`;

const Type = styled.div`
  padding: 3.6rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  background: white;
  box-shadow: ${TOKENS.shadow.default};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-items: center;
`;

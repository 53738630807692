import { ApexOptions } from 'apexcharts';
import { Column, DataTable, DataTableExport } from 'src/components/DataTable';
import { ControlledInput, ControlledSelect, ControlledSwitch } from 'src/components/Form';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { format } from 'date-fns';
import { TOKENS, CHART_COLORS } from 'src/design';
import ReactApexChart from 'react-apexcharts';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { calcWithMarkup, formatAmount, formatPercentage, formatReportingId } from 'src/utils';
import { METRIC_OPTIONS } from './constants';
import { ReportingFormValues } from './Reporting';
import { useDataTable, useRole, useWhiteLabelTheme } from 'src/hooks';
import { Loading } from 'src/components/Loading';
import { ReportingInfo } from 'src/components/ReportingInfo';

const { useCampaignPerformanceQuery } = growthApi;

type ReportingCampaignFormValues = {
  metric1: string;
  metric2: string;
  search?: string;
  withMarkup?: boolean;
};

type ReportingCampaignProps = Pick<
  ReportingFormValues,
  'timeRange' | 'dateFrom' | 'dateTo' | 'agencyId' | 'advertiserId' | 'campaignId'
> & {
  markup?: number;
};

export const ReportingCampaign = (props: ReportingCampaignProps) => {
  const { timeRange, dateFrom, dateTo, agencyId, advertiserId, campaignId, markup } = props;

  const { canWithMarkup } = useRole();
  const theme = useWhiteLabelTheme();
  const { watch, control } = useForm<ReportingCampaignFormValues>({
    defaultValues: {
      metric1: 'impressions',
      metric2: 'clicks',
      withMarkup: true,
    },
  });
  const values = watch();
  const finalMarkup = values.withMarkup ? markup : 0;
  const isNotSelectAgencyAdvertiser = !agencyId || !advertiserId;

  const commonFilter = {
    time_range: timeRange,
    date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
    date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
    agency_id: agencyId,
    advertiser_id: advertiserId,
    campaign_id: campaignId,
  };

  const {
    data: campaignData,
    isFetching: campaignIsFetching,
    error: campaignError,
  } = useCampaignPerformanceQuery(
    {
      ...commonFilter,
      breakout: 'campaign',
      with_campaign: true,
    },
    { skip: isNotSelectAgencyAdvertiser },
  );

  const { data: chartData, isFetching: dayIsFetching } = useCampaignPerformanceQuery(
    {
      ...commonFilter,
      breakout: 'day',
    },
    { skip: isNotSelectAgencyAdvertiser },
  );

  const metricWithMarkup = (row: any, metric: string) => {
    if (['cpm', 'cpc', 'cpv', 'cpa', 'total_spend'].includes(metric)) {
      return calcWithMarkup(row[metric] ?? 0, finalMarkup);
    } else {
      return row[metric] ?? 0;
    }
  };

  const chartOptions: ApexOptions = {
    colors: CHART_COLORS(theme),
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      categories: chartData?.data?.map((row) => row.start_date) || [],
    },
    yaxis: [
      {
        decimalsInFloat: 2,
      },
      {
        decimalsInFloat: 2,
        opposite: true,
      },
    ],
  };
  const chartSeries: ApexAxisChartSeries = [
    {
      name: METRIC_OPTIONS.find((option) => option.value === values.metric1)?.label,
      data: chartData?.data?.map((row) => metricWithMarkup(row, values.metric1)) || [],
    },
    {
      name: METRIC_OPTIONS.find((option) => option.value === values.metric2)?.label,
      data: chartData?.data?.map((row) => metricWithMarkup(row, values.metric2)) || [],
    },
  ];

  const { dataTableProps, dataTableRef } = useDataTable({
    idKey: '_',
    data: campaignData?.data,
    isLoading: campaignIsFetching,
    error: campaignError,
    search: values.search,
    searchKeys: ['campaign_name'],
    defaultSort: {
      key: 'campaign.active_flight_start_date',
      direction: 'desc',
    },
    sortNumberKeys: [
      'impressions',
      'clicks',
      'ctr',
      'cpm',
      'cpc',
      'cpv',
      'total_conversions',
      'cpa',
      'total_spend',
      'campaign.budget',
    ],
  });

  const columns: Column[] = [
    {
      accessor: 'campaign_ids',
      header: 'ID',
      render: formatReportingId,
    },
    {
      accessor: 'campaign_name',
      header: 'Name',
      sortable: true,
    },
    {
      header: 'Impressions',
      accessor: 'impressions',
      sortable: true,
    },
    {
      header: 'Clicks',
      accessor: 'clicks',
      sortable: true,
    },
    {
      header: 'CTR',
      accessor: 'ctr',
      render: formatPercentage,
      sortable: true,
    },
    {
      header: 'CPM',
      accessor: 'cpm',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPC',
      accessor: 'cpc',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'CPV',
      accessor: 'cpv',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'Conversions',
      accessor: 'total_conversions',
      sortable: true,
    },
    {
      header: 'CPA',
      accessor: 'cpa',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'Spend',
      accessor: 'total_spend',
      render: (value: any) => formatAmount(calcWithMarkup(value, finalMarkup)),
      sortable: true,
    },
    {
      header: 'Budget',
      accessor: 'campaign.budget',
      render: formatAmount,
      sortable: true,
    },
  ];

  if (isNotSelectAgencyAdvertiser) {
    return <ReportingInfo message="Please select agency and advertiser to see the report" />;
  }

  return (
    <>
      <ChartContainer>
        <Flex gap="md">
          <Flex direction="column" gap="xs">
            <Text size="xs">Metric 1</Text>
            <ControlledSelect name="metric1" control={control} options={METRIC_OPTIONS} width="20rem" />
          </Flex>
          <Flex direction="column" gap="xs">
            <Text size="xs">Metric 2</Text>
            <ControlledSelect name="metric2" control={control} options={METRIC_OPTIONS} width="20rem" />
          </Flex>
        </Flex>
        <Spacing size="lg" />
        {dayIsFetching ? (
          <Loading height="35rem" />
        ) : (
          <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={350} />
        )}
      </ChartContainer>
      <Spacing size="lg" />
      <TableContainer>
        <Flex gap="lg" align="center">
          <FlexItem grow={1}>
            <ControlledInput name="search" control={control} prefix="Search:" placeholder="Keyword" />
          </FlexItem>
          {canWithMarkup && (
            <FlexItem shrink={0}>
              <Flex gap="md" align="center">
                <Text size="xs">With markup</Text>
                <ControlledSwitch name="withMarkup" control={control} />
              </Flex>
            </FlexItem>
          )}
          <DataTableExport
            onExport={(type) => {
              dataTableRef?.current?.export(type, 'reporting-campaign');
            }}
          />
        </Flex>
        <Spacing size="lg" />
        <DataTable columns={columns} {...dataTableProps} />
      </TableContainer>
    </>
  );
};

const ChartContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;

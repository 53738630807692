import { Text, Flex } from 'src/components/Layout';
import { Icon } from 'src/components/Icon';
import styled from 'styled-components';

export const GotoLegacyApp = () => {
  return (
    <Container href="https://app.growthchannel.io" target="_blank">
      <Flex justify="space-between" align="center">
        <Text size="sm">Go to legacy app</Text>
        <Icon size="sm" type="link" />
      </Flex>
    </Container>
  );
};

const Container = styled.a`
  position: absolute;
  background: #f6f6f6;
  padding: 0.8rem 1.2rem;
  bottom: 2.4rem;
  left: 1.2rem;
  right: 1.2rem;
  border-radius: 0.4rem;
`;

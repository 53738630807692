export const fileToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const base64ToFile = async (base64: string, fileName: string, fileType: string) => {
  const res: Response = await fetch(base64);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: fileType });
};

export const getFileDimension = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (readerEvent) {
      const image = new Image();
      image.src = readerEvent.target.result as any;
      image.onload = () => {
        resolve(`${image.width}x${image.height}`);
      };
    };
  });
};

export const getFileSize = (file: File) => {
  return file.size;
};

export const getFileExt = (file: File) => {
  return file.name.split('.').pop();
};

import { TOKENS } from 'src/design';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/store';
import styled from 'styled-components';
import { Sidebar } from '../Sidebar';
import { Flex } from '../Layout';
import { Loading } from '../Loading';

export type PageTemplateProps = {
  isLoading?: boolean;
  children?: ReactNode;
};

export const PageTemplate = (props: PageTemplateProps) => {
  const { isLoading, children } = props;

  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  return (
    <PageContainer>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <ContentContainer>
        {isLoading ? (
          <Flex width="100%" height="100%" direction="column" justify="center" align="center">
            <Loading />
          </Flex>
        ) : (
          children
        )}
      </ContentContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
  background: ${TOKENS.color.bgGray};
`;

const SidebarContainer = styled.div`
  padding: 1.2rem 0 1.2rem 1.2rem;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  padding: 2.4rem 3.6rem;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
`;

import { Route, Routes, Navigate } from 'react-router-dom';
import { Reporting } from 'src/pages/Activate/Reporting';
import { AudienceEdit, Audiences } from 'src/pages/Activate/Audiences';
import { CreativeEdit, Creatives } from 'src/pages/Activate/Creatives';
import { PixelEdit, Pixels } from 'src/pages/Activate/Pixels';
import { CampaignEdit, Campaigns } from 'src/pages/Activate/Campaigns';
import { Overview as ActivateOverview } from 'src/pages/Activate/Overview';
import { Login } from 'src/pages/Login';
import { Signup } from 'src/pages/Signup';
import { ForgetPassword } from 'src/pages/ForgetPassword';
import { Confirm } from 'src/pages/Confirm';
import { ResetPassword } from 'src/pages/ResetPassword';
import { NotFound } from 'src/components/Info/NotFound';
import { Users } from 'src/pages/Admin/Users';
import { Login as V1Login } from 'src/pages/V1/Login';
import { Transactions } from 'src/pages/Admin/Transactions';
import { Advertisers } from 'src/pages/Admin/Advertisers';
import { AdWallet } from 'src/pages/Settings/AdWallet';
import { ExclusionRules } from 'src/pages/Settings/ExclusionRules';
import { ThemeProvider } from 'styled-components';
import { LoadingTemplate } from 'src/components/Template';
import { useWhiteLabel, useStatus, useWhiteLabelTheme, useRedirectLogin } from 'src/hooks';
import { ForecastEdit, Forecasts as ActivateForecasts } from './pages/Activate/Forecasts';
import { Forecasts as AdminForecasts } from './pages/Admin/Forecasts';
import { Agencies } from './pages/Admin/Agencies';
import { WhiteLabel } from './pages/Settings/WhiteLabel';
import { CustomAudiences, CustomAudienceView } from './pages/Admin/CustomAudiences';
import { Insights, InsightView } from './pages/Activate/Insights';
import { MarkupRules } from './pages/Admin/MarkupRules';
import {
  Overview as PlanningOverview,
  Start as PlanningStart,
  Pricing as PlanningPricing,
} from 'src/pages/Planning/Overview';
import { Personas, PersonaEdit } from './pages/Planning/Personas';
import { BindingCreditCard } from './pages/BindingCreditCard';
import { Security } from './pages/Settings/Security';

export const App = () => {
  const { isLoaded } = useStatus();
  useWhiteLabel();
  useRedirectLogin();
  const theme = useWhiteLabelTheme();

  if (!isLoaded) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingTemplate />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Navigate to="/activate/overview" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/confirm" element={<Confirm />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/binding-credit-card" element={<BindingCreditCard />} />
        <Route path="/activate/overview" element={<ActivateOverview />} />
        <Route path="/activate/reporting" element={<Reporting />} />
        <Route path="/activate/audiences" element={<Audiences />} />
        <Route path="/activate/audiences/new" element={<AudienceEdit />} />
        <Route path="/activate/audiences/edit" element={<AudienceEdit />} />
        <Route path="/activate/creatives" element={<Creatives />} />
        <Route path="/activate/creatives/new" element={<CreativeEdit />} />
        <Route path="/activate/creatives/edit" element={<CreativeEdit />} />
        <Route path="/activate/pixels" element={<Pixels />} />
        <Route path="/activate/pixels/new" element={<PixelEdit />} />
        <Route path="/activate/pixels/edit" element={<PixelEdit />} />
        <Route path="/activate/campaigns" element={<Campaigns />} />
        <Route path="/activate/campaigns/new" element={<CampaignEdit />} />
        <Route path="/activate/campaigns/edit" element={<CampaignEdit />} />
        <Route path="/activate/forecasts" element={<ActivateForecasts />} />
        <Route path="/activate/forecasts/new" element={<ForecastEdit />} />
        <Route path="/activate/forecasts/edit" element={<ForecastEdit />} />
        <Route path="/activate/insights" element={<Insights />} />
        <Route path="/activate/insights/view" element={<InsightView />} />
        <Route path="/planning/overview" element={<PlanningOverview />} />
        <Route path="/planning/start" element={<PlanningStart />} />
        <Route path="/planning/pricing" element={<PlanningPricing />} />
        <Route path="/planning/personas" element={<Personas />} />
        <Route path="/planning/personas/edit" element={<PersonaEdit />} />
        <Route path="/settings/ad-wallet" element={<AdWallet />} />
        <Route path="/settings/exclusion-rules" element={<ExclusionRules />} />
        <Route path="/settings/white-label" element={<WhiteLabel />} />
        <Route path="/settings/security" element={<Security />} />
        <Route path="/admin/agencies" element={<Agencies />} />
        <Route path="/admin/advertisers" element={<Advertisers />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/markup-rules" element={<MarkupRules />} />
        <Route path="/admin/transactions" element={<Transactions />} />
        <Route path="/admin/forecasts" element={<AdminForecasts />} />
        <Route path="/admin/custom-audiences" element={<CustomAudiences />} />
        <Route path="/admin/custom-audiences/view" element={<CustomAudienceView />} />
        <Route path="/v1/login" element={<V1Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ThemeProvider>
  );
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiResult, User } from 'src/types';
import { RootState, userSlice } from 'src/store';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { omitBy, isUndefined } from 'lodash';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_GROWTH_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).user.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithAutoLogout: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    api.dispatch(userSlice.actions.logout());
  }
  return result;
};

export const growthApi = createApi({
  reducerPath: 'growthApi',
  baseQuery: baseQueryWithAutoLogout,
  endpoints: (builder) => ({
    login: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/login',
        method: 'POST',
        body,
      }),
    }),
    v1Login: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'v1/login',
        method: 'POST',
        body,
      }),
    }),
    v1SyncUsers: builder.mutation<ApiResult, void>({
      query: (body) => ({
        url: 'v1/sync-users',
        method: 'POST',
        body,
      }),
    }),
    signup: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/signup',
        method: 'POST',
        body,
      }),
    }),
    confirm: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/confirm',
        method: 'POST',
        body,
      }),
    }),
    forgetPassword: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/forget-password',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/reset-password',
        method: 'POST',
        body,
      }),
    }),
    profile: builder.query<ApiResult, void>({
      query: () => ({
        url: 'user/profile',
        method: 'GET',
      }),
    }),
    updateProfile: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/update-profile',
        method: 'POST',
        body,
      }),
    }),
    updateUserProfile: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/update-user-profile',
        method: 'POST',
        body,
      }),
    }),
    creditBalance: builder.query<ApiResult, void>({
      query: () => ({
        url: 'user/credit-balance',
        method: 'GET',
      }),
    }),
    useCreditBalance: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `user/user-credit-balance?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
        method: 'GET',
      }),
    }),
    userCharge: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/user-charge',
        method: 'POST',
        body,
      }),
    }),
    createUser: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/create-user',
        method: 'POST',
        body,
      }),
    }),
    addCreditBalance: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/credit-balance',
        method: 'POST',
        body,
      }),
    }),
    setBillingInformation: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/billing-information',
        method: 'POST',
        body,
      }),
    }),
    setAutoCredit: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/auto-credit',
        method: 'POST',
        body,
      }),
    }),
    audiences: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/audiences?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    audiencesDataset: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/audiences-dataset?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    audiencesDatasetQuery: builder.mutation<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/audiences-dataset?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    creatives: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/creatives?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    url: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/url?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    currency: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/currency?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    creativeReports: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/creative-reports?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    creativePreview: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/creative-preview?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    geo: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/geo?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    pixels: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/pixels?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    campaigns: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/campaigns?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    createOrUpdateCampaign: builder.mutation<ApiResult<any[]>, any>({
      query: (body) => {
        return {
          url: 'dsp/campaigns',
          method: 'POST',
          body,
        };
      },
    }),
    forecasts: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/forecasts?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    createOrUpdateForecast: builder.mutation<ApiResult<any[]>, any>({
      query: (body) => {
        return {
          url: 'dsp/forecasts',
          method: 'POST',
          body,
        };
      },
    }),
    customAudiences: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/custom-audiences?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    customAudienceActivations: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/custom-audiences/activations?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    createCustomAudienceActivation: builder.mutation<ApiResult, any>({
      query: (body) => {
        return {
          url: 'dsp/custom-audiences/activations',
          method: 'POST',
          body,
        };
      },
    }),
    customAudienceInsights: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/custom-audiences/insights?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    createCustomAudienceInsight: builder.mutation<ApiResult, any>({
      query: (body) => {
        return {
          url: 'dsp/custom-audiences/insights',
          method: 'POST',
          body,
        };
      },
    }),
    activateCustomAudienceInsight: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/custom-audiences/insights/activate?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    markupRules: builder.query<ApiResult, any>({
      query: (params) => {
        return {
          url: `dsp/markup-rules?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    updateMarkupRules: builder.mutation<ApiResult, any>({
      query: (body) => {
        return {
          url: 'dsp/markup-rules',
          method: 'POST',
          body,
        };
      },
    }),
    campaignPerformance: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/campaign-performance?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    campaignPerformanceBatch: builder.query<ApiResult<any[]>, any>({
      query: (body) => {
        return {
          url: 'dsp/campaign-performance-batch',
          method: 'POST',
          body,
        };
      },
    }),
    siteTransparency: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/site-transparency?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    appTransparency: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/app-transparency?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    winLoss: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/win-loss?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    agencies: builder.query<ApiResult<any[]>, void>({
      query: () => ({
        url: 'dsp/agencies',
        method: 'GET',
      }),
    }),
    advertisers: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/advertisers?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    verticals: builder.query<ApiResult<any[]>, any>({
      query: (params) => {
        return {
          url: `dsp/verticals?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
          method: 'GET',
        };
      },
    }),
    createOrUpdatePixel: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/pixels',
        method: 'POST',
        body,
      }),
    }),
    archivePixel: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/pixels/archive',
        method: 'POST',
        body,
      }),
    }),
    createOrUpdateCreative: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/creatives',
        method: 'POST',
        body,
      }),
    }),
    createOrUpdateAudience: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/audiences',
        method: 'POST',
        body,
      }),
    }),
    createOrUpdateAgency: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/agencies',
        method: 'POST',
        body,
      }),
    }),
    createOrUpdateAdvertiser: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/advertisers',
        method: 'POST',
        body,
      }),
    }),
    users: builder.query<ApiResult<User[]>, any>({
      query: (params) => ({
        url: `user/list?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
        method: 'GET',
      }),
    }),
    userDSPBinding: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'user/dsp-binding',
        method: 'POST',
        body,
      }),
    }),
    dspArchive: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/archive',
        method: 'POST',
        body,
      }),
    }),
    transactions: builder.query<ApiResult, any>({
      query: () => ({
        url: 'dsp/ad-wallet/transactions',
        method: 'GET',
      }),
    }),
    manualDspBilling: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'crontab/dsp-billing',
        method: 'POST',
        body,
      }),
    }),
    status: builder.query<ApiResult, any>({
      query: (body) => ({
        url: 'user/status',
        method: 'POST',
        body,
      }),
    }),
    updateAgencyExt: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/agency-ext',
        method: 'POST',
        body,
      }),
    }),
    updateAdvertiserExt: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/advertiser-ext',
        method: 'POST',
        body,
      }),
    }),
    whiteLabel: builder.query<ApiResult, any>({
      query: () => ({
        url: 'dsp/white-label',
        method: 'GET',
      }),
    }),
    updateWhiteLabel: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/white-label',
        method: 'POST',
        body,
      }),
    }),
    adWallet: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `dsp/ad-wallet?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
        method: 'GET',
      }),
    }),
    markup: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `dsp/markup?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
        method: 'GET',
      }),
    }),
    updateAdWalletPaymentMethod: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/ad-wallet/update-payment-method',
        method: 'POST',
        body,
      }),
    }),
    addAdWalletCreditBalance: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/ad-wallet/add-credit-balance',
        method: 'POST',
        body,
      }),
    }),
    adWalletPrepayment: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'dsp/ad-wallet/prepayment',
        method: 'POST',
        body,
      }),
    }),
    stackadaptCustomSegments: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `dsp/stackadapt/custom-segments?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
        method: 'GET',
      }),
    }),
    createOrUpdateMarketPlan: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'planning/market-plans',
        method: 'POST',
        body,
      }),
    }),
    createOrUpdatePersona: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'planning/personas',
        method: 'POST',
        body,
      }),
    }),
    personas: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `planning/personas?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
        method: 'GET',
      }),
    }),
    marketPlans: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `planning/market-plans?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
        method: 'GET',
      }),
    }),
    updateWorkspace: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'planning/workspace',
        method: 'POST',
        body,
      }),
    }),
    securityConfig: builder.query<ApiResult, any>({
      query: (params) => ({
        url: `security/config?${new URLSearchParams(omitBy(params, isUndefined)).toString()}`,
        method: 'GET',
      }),
    }),
    updateSecurityConfig: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'security/config',
        method: 'POST',
        body,
      }),
    }),
    clearIpExclusion: builder.mutation<ApiResult, any>({
      query: (body) => ({
        url: 'security/clear-ip-exclusion',
        method: 'POST',
        body,
      }),
    }),
  }),
  // disable cache
  keepUnusedDataFor: 0,
});

import { Button, Input } from 'src/components/Form';
import { Spacing } from 'src/components/Layout';
import { PasswordFormItems, passwordIsSafe } from 'src/components/PasswordFormItems';
import { AuthTemplate } from 'src/components/Template';
import { useToast } from 'src/hooks';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { getApiErrorMessage } from 'src/utils';

const { useSignupMutation } = growthApi;

type SignupFormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

export const Signup = () => {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<SignupFormValues>();
  const [signup] = useSignupMutation();

  const values = watch();
  const isDisabled = !values.email || values.password !== values.confirmPassword || !passwordIsSafe(values.password);

  const onSubmit = async (values: SignupFormValues) => {
    try {
      const apiResult = await signup(values).unwrap();
      showSuccessToast(apiResult.message);
      navigate('/login');
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  return (
    <AuthTemplate title="Create Account">
      <Input {...register('email')} placeholder="Enter mail" icon="email" various="border" />
      <Spacing size="1.6rem" />
      <PasswordFormItems register={register} watch={watch} />
      <Spacing size="1.2rem" />
      <Button onClick={handleSubmit(onSubmit)} isLoading={isSubmitting} disabled={isDisabled}>
        Create Account
      </Button>
      <LoginContainer>
        <div>Already have an account?</div>
        <Login to="/login">Login</Login>
      </LoginContainer>
    </AuthTemplate>
  );
};

const LoginContainer = styled.div`
  display: flex;
  gap: 0.6rem;
  margin-top: 1.2rem;
  font-size: 1.2rem;
`;

const Login = styled(Link)`
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.primary};
  border-bottom: solid 0.2rem ${(props) => props.theme.color.primary};
  text-decoration: none;
`;

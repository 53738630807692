import { isCustomDomain } from 'src/utils';
import { Role, User } from 'src/types';
import { IconProps } from '../Icon';
import { ReactNode } from 'react';
import { GotoLegacyApp } from './GotoLegacyApp';

export type SubMenu = Omit<Menu, 'subMenus'>;

export type Menu = {
  title: string;
  icon: IconProps['type'];
  url?: string;
  subMenus?: SubMenu[];
  subUrls?: string[];
  addition?: () => ReactNode;
  when?: (user?: User) => boolean;
  isLocked?: boolean;
};

export const MENUS: Menu[] = [
  {
    title: 'Activate',
    icon: 'activate',
    subMenus: [
      {
        title: 'Overview',
        icon: 'overview',
        url: '/activate/overview',
      },
      {
        title: 'Reporting',
        icon: 'reporting',
        url: '/activate/reporting',
      },
      {
        title: 'Campaigns',
        icon: 'campaigns',
        url: '/activate/campaigns',
        subUrls: ['/activate/campaigns/new', '/activate/campaigns/edit'],
      },
      {
        title: 'Creatives',
        icon: 'theme',
        url: '/activate/creatives',
        subUrls: ['/activate/creatives/new', '/activate/creatives/edit'],
      },
      {
        title: 'Pixels',
        icon: 'pixels',
        url: '/activate/pixels',
        subUrls: ['/activate/pixels/new', '/activate/pixels/edit'],
      },
      {
        title: 'Audiences',
        icon: 'audiences',
        url: '/activate/audiences',
        subUrls: ['/activate/audiences/new', '/activate/audiences/edit'],
      },
      {
        title: 'Forecasts',
        icon: 'forecast',
        url: '/activate/forecasts',
        subUrls: ['/activate/forecasts/new', '/activate/forecasts/edit'],
      },
      {
        title: 'Insights',
        icon: 'reporting',
        url: '/activate/insights',
        subUrls: ['/activate/insights/view'],
      },
    ],
  },
  // {
  //   title: 'Planning',
  //   icon: 'plan',
  //   url: 'https://app.growthchannel.io',
  //   when: () => !isCustomDomain(),
  // },
  {
    title: 'Planning',
    icon: 'plan',
    when: () => !isCustomDomain(),
    subMenus: [
      {
        title: 'Overview',
        icon: 'overview',
        url: '/planning/overview',
      },
      {
        title: 'Personas',
        icon: 'users',
        url: '/planning/personas',
        subUrls: ['/planning/personas/edit'],
      },
      {
        title: 'Tactics',
        icon: 'users',
        isLocked: true,
      },
      {
        title: 'Keywords',
        icon: 'users',
        isLocked: true,
      },
      {
        title: 'Benchmarks',
        icon: 'users',
        isLocked: true,
      },
      {
        title: 'Publishing sites',
        icon: 'users',
        isLocked: true,
      },
      {
        title: 'Ad copy',
        icon: 'users',
        isLocked: true,
      },
    ],
    subUrls: ['/planning/start', '/planning/pricing'],
    addition: () => <GotoLegacyApp />,
  },
  {
    title: 'Library',
    icon: 'library',
    url: 'https://growthlibrary.pory.app/',
    when: () => !isCustomDomain(),
  },
  {
    title: 'Settings',
    icon: 'settings',
    subMenus: [
      {
        title: 'Ad Wallet',
        icon: 'transactions',
        url: '/settings/ad-wallet',
      },
      {
        title: 'Exclusion Rules',
        icon: 'error',
        url: '/settings/exclusion-rules',
      },
      {
        title: 'White Label',
        icon: 'theme',
        url: '/settings/white-label',
        when: (user?: User) => [Role.WhiteLabel].includes(user?.role),
      },
      {
        title: 'Security',
        icon: 'safe',
        url: '/settings/security',
        when: (user?: User) => [Role.Admin, Role.WhiteLabel].includes(user?.role),
      },
    ],
    when: (user?: User) => [Role.Admin, Role.WhiteLabel, Role.Agency, Role.Advertiser].includes(user?.role),
  },
  {
    title: 'Admin',
    icon: 'admin',
    subMenus: [
      {
        title: 'Agencies',
        icon: 'users',
        url: '/admin/agencies',
      },
      {
        title: 'Advertisers',
        icon: 'users',
        url: '/admin/advertisers',
      },
      {
        title: 'Users',
        icon: 'users',
        url: '/admin/users',
        when: (user?: User) => [Role.Admin, Role.WhiteLabel].includes(user?.role),
      },
      {
        title: 'Forecasts',
        icon: 'forecast',
        url: '/admin/forecasts',
        when: (user?: User) => [Role.Admin, Role.WhiteLabel].includes(user?.role),
      },
      {
        title: 'Custom Audiences',
        icon: 'reporting',
        url: '/admin/custom-audiences',
        subUrls: ['/admin/custom-audiences/view'],
        when: (user?: User) => [Role.Admin].includes(user?.role),
      },
      {
        title: 'Markup Rules',
        icon: 'markup',
        url: '/admin/markup-rules',
        when: (user?: User) => [Role.Admin].includes(user?.role),
      },
      {
        title: 'Transactions',
        icon: 'transactions',
        url: '/admin/transactions',
        when: (user?: User) => [Role.Admin, Role.WhiteLabel].includes(user?.role),
      },
    ],
    when: (user?: User) => [Role.Admin, Role.WhiteLabel, Role.Agency].includes(user?.role),
  },
];

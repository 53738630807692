import { Flex, FlexItem, Spacing } from 'src/components/Layout';
import { Table, Td, Th } from 'src/components/Table';
import { forwardRef, Fragment, ReactNode, useCallback, useImperativeHandle, useMemo } from 'react';
import { DataTableEmpty } from './DataTableEmpty';
import { DataTableError } from './DataTableError';
import { DataTableLoading } from './DataTableLoading';
import { DataTablePaginate } from './DataTablePaginate';
import { get } from 'lodash';
import { User } from 'src/types';
import { Icon } from '../Icon';
import { useAppSelector } from 'src/store';
import { DataTableExportType } from './DataTableExport';
import { format } from 'date-fns';
import { jsonToCSV } from 'react-papaparse';
import { exportCSV } from 'src/utils';
import { nanoid } from 'nanoid';
import styled, { css } from 'styled-components';

export type Column = {
  header?: ReactNode | (() => ReactNode);
  title?: string;
  accessor: string;
  render?: (value: any, row?: any, level?: number) => ReactNode;
  export?: ((value: any, row?: any) => any) | false;
  width?: string;
  sortable?: boolean;
  when?: (user?: User) => boolean;
};

export type Sort = {
  key?: string;
  direction?: 'asc' | 'desc';
};

export type DataTableRef = {
  export: (type: DataTableExportType, prefix: string) => void;
};

type DataTableProps = {
  idKey?: string;
  columns: Column[];
  visibleData?: any[];
  data?: any[];
  isLoading?: boolean;
  error?: string | string[];
  total?: number;
  page?: number;
  pageSize?: number;
  onPageChange?: (page: number) => void;
  sort?: Sort;
  onSortChange?: (sort: Sort) => void;
  expendIds?: any[];
  expendData?: Record<any, any>;
  scroll?: boolean;
};

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 20;
export const MAX_PAGE_SIZE = 9999;

export const DataTable = forwardRef<DataTableRef, DataTableProps>((props, ref) => {
  const user = useAppSelector((state) => state.user.user);
  const {
    idKey = 'id',
    columns,
    visibleData,
    data,
    isLoading,
    error,
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    onPageChange,
    sort,
    onSortChange,
    expendIds,
    expendData,
    scroll,
  } = props;

  const pageCount = Math.ceil(data.length / pageSize);

  const visibleColumns = useMemo(() => {
    return columns.filter((columns) => !columns.when || columns.when(user));
  }, [columns, user]);

  useImperativeHandle(ref, () => {
    return {
      export: (type: DataTableExportType, prefix: string) => {
        const exportColumns = visibleColumns.filter((column) => column.export !== false);
        const filename = `${prefix}-${format(new Date(), 'MM-dd-yyyy')}`;
        switch (type) {
          case DataTableExportType.Csv:
            const csv = jsonToCSV({
              fields: exportColumns.map((column) => column.header),
              data: data.map((row) => {
                const parsedRow = {};
                exportColumns.forEach((column) => {
                  const value = get(row, column.accessor);
                  parsedRow[column.header as any] = column.export
                    ? column.export(value, row)
                    : column.render
                    ? column.render(value, row)
                    : value;
                });
                return parsedRow;
              }),
            });
            exportCSV(`${filename}.csv`, csv);
            break;
        }
      },
    };
  });

  const triggerSort = useCallback(
    (key: string) => {
      // reset to page 1
      // setPage(1);
      const newSort: Sort = {};
      if (sort?.key === key) {
        newSort.key = sort.key;
        newSort.direction = sort.direction === 'asc' ? 'desc' : 'asc';
      } else {
        newSort.key = key;
        newSort.direction = 'desc';
      }
      onSortChange(newSort);
    },
    [onSortChange, sort],
  );

  const renderRow = (row: any, level: number = 1) => {
    const id = get(row, idKey) ?? nanoid();
    return (
      <Fragment key={id}>
        <tr>
          {visibleColumns.map((column) => (
            <Td key={column.accessor} width={column.width}>
              {column.render ? column.render(get(row, column.accessor), row, level) : get(row, column.accessor)}
            </Td>
          ))}
        </tr>
        {expendIds?.includes(id) && expendData?.[id]?.map((row: any) => renderRow(row, level + 1))}
      </Fragment>
    );
  };

  return (
    <>
      <TableContainer scroll={scroll}>
        <Table>
          <thead>
            <tr>
              {visibleColumns.map((column) => (
                <Th
                  key={column.accessor}
                  title={column.title}
                  width={column.width}
                  sortable={column.sortable}
                  onClick={column.sortable ? () => triggerSort(column.accessor) : undefined}
                >
                  <Flex gap="xs" align="center">
                    <FlexItem>{typeof column.header === 'function' ? column.header() : column.header}</FlexItem>
                    {column.sortable && (
                      <FlexItem shrink={0}>
                        <Icon
                          size="sm"
                          color={column.accessor === sort?.key ? 'text' : 'gray'}
                          type={
                            column.accessor === sort?.key
                              ? sort?.direction === 'asc'
                                ? 'arrowUp'
                                : 'arrowDown'
                              : 'arrowDownUp'
                          }
                        />
                      </FlexItem>
                    )}
                  </Flex>
                </Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <DataTableLoading />
            ) : error ? (
              <DataTableError error={error} />
            ) : visibleData.length > 0 ? (
              visibleData.map((row) => renderRow(row))
            ) : (
              <DataTableEmpty />
            )}
          </tbody>
        </Table>
      </TableContainer>
      {!isLoading && !error && pageCount > 1 && (
        <>
          <Spacing size="xl" />
          <Flex justify="center">
            <DataTablePaginate page={page} pageCount={pageCount} onPageChange={onPageChange} />
          </Flex>
        </>
      )}
    </>
  );
});

const TableContainer = styled.div<{ scroll?: boolean }>`
  ${(props) =>
    props.scroll &&
    css`
      max-width: 100%;
      overflow: auto;
      white-space: nowrap;
    `}
`;

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/store';

const WHITELISTS = [
  '/login',
  '/signup',
  '/confirm',
  '/forget-password',
  '/reset-password',
  '/v1/login',
  '/binding-credit-card',
];

export const useRedirectLogin = () => {
  const user = useAppSelector((state) => state.user.user);
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!user && !WHITELISTS.includes(location.pathname)) {
      navigate('/login');
    }
  }, [location.pathname, navigate, user]);
};

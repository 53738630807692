import { useState } from 'react';
import { Action, Button, Form, FormItem } from 'src/components/Form';
import { Flex, FlexItem, Spacing, Text } from 'src/components/Layout';
import { SetBillingInformationModal } from 'src/components/SetBillingInformationModal';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { formatAmount } from 'src/utils';
import { PageTemplate } from 'src/components/Template';
import { AddMoreFundsModal } from 'src/components/AddMoreFundsModal';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { useForm } from 'react-hook-form';
import { Icon } from 'src/components/Icon';
import { Loading } from 'src/components/Loading';
import { useRole, useToast, useWhiteLabelAgencyIds } from 'src/hooks';
import { PrepaymentModal } from 'src/components/PrepaymentModal';

const { useAdWalletQuery } = growthApi;

type AdWalletFormValues = {
  agencyId?: number;
  advertiserId?: number;
};

export const AdWallet = () => {
  const { agencyId, advertiserId, canAccessAgency, isAdmin, isWhiteLabel } = useRole();
  const { watch, control, setValue } = useForm<AdWalletFormValues>({
    defaultValues: { agencyId, advertiserId },
  });
  const values = watch();
  const { isLoading, whiteLabelAgencyIds } = useWhiteLabelAgencyIds();
  const isShowAdWallet = Boolean(values.advertiserId || whiteLabelAgencyIds.includes(values.agencyId));
  const { data, isFetching, refetch } = useAdWalletQuery(
    {
      agency_id: values.agencyId,
      advertiser_id: values.advertiserId,
      with_credit_balance: true,
    },
    { skip: !isShowAdWallet },
  );
  const { showSuccessToast } = useToast();

  const [setBillingInformationModalIsOpen, setSetBillingInformationModalIsOpen] = useState<boolean>(false);
  const [addMoreFundsModalIsOpen, setAddMoreFundsModalIsOpen] = useState<boolean>(false);
  const [prepaymentModalIsOpen, setPrepaymentModalIsOpen] = useState<boolean>(false);

  const hasCreditBalance = Boolean(data?.data?.credit_balance > 0);
  const hasPaymentMethod = Boolean(data?.data?.payment_method_id);

  const canPrepayment = isAdmin || (isWhiteLabel && values.advertiserId);

  const copyAdvertiserBindingCCLink = () => {
    const currentDomain = window.location.hostname;
    if (currentDomain === 'localhost') {
      navigator.clipboard.writeText(
        `http://localhost:3000/binding-credit-card?agency_id=${values.agencyId}&advertiser_id=${values.advertiserId}`,
      );
    } else {
      navigator.clipboard.writeText(
        `https://${currentDomain}/binding-credit-card?agency_id=${values.agencyId}&advertiser_id=${values.advertiserId}`,
      );
    }
    showSuccessToast('CC binding link has been copied to your clipboard');
  };

  return (
    <PageTemplate isLoading={isLoading}>
      <Text size="xxl" weight={700}>
        Ad Wallet
      </Text>
      <Spacing size="xl" />
      <Form width="70%">
        {canAccessAgency && (
          <>
            <FormItem label="Agency" required>
              <AgencySelect
                name="agencyId"
                control={control}
                onValueChange={() => {
                  setValue('advertiserId', undefined);
                }}
              />
            </FormItem>
            {values.agencyId && (
              <FormItem label="Advertiser">
                <Flex gap="md" align="center" width="100%">
                  <FlexItem grow={1}>
                    <AdvertiserSelect agencyId={values.agencyId} name="advertiserId" control={control} />
                  </FlexItem>
                  <Button width="12rem" various="secondary" onClick={() => setValue('advertiserId', undefined)}>
                    CLEAR
                  </Button>
                </Flex>
              </FormItem>
            )}
          </>
        )}
        {isShowAdWallet && (
          <>
            {isFetching ? (
              <Loading height="20rem" />
            ) : (
              <FormItem label="Payment">
                <Spacing size="md" />
                <Flex gap="xxl">
                  <PaymentItem>
                    <Flex direction="column" gap="lg" align="center">
                      <Text weight={600} size="lg">
                        Credit Balance
                      </Text>
                      <Icon
                        type={hasCreditBalance ? 'on' : 'off'}
                        color={hasCreditBalance ? 'success' : 'error'}
                        size="xxxl"
                      />
                      <Text size="sm" weight={500}>
                        {formatAmount(data?.data?.credit_balance)}
                      </Text>
                      <Flex gap="md" width="100%">
                        <Button disabled={!hasPaymentMethod} onClick={() => setAddMoreFundsModalIsOpen(true)} shadow>
                          ADD CREDIT
                        </Button>
                        {canPrepayment && (
                          <Button various="secondary" onClick={() => setPrepaymentModalIsOpen(true)}>
                            PREPAYMENT
                          </Button>
                        )}
                      </Flex>
                    </Flex>
                  </PaymentItem>
                  <PaymentItem>
                    <Flex direction="column" gap="lg" align="center">
                      <Text weight={600} size="lg">
                        Payment Method
                      </Text>
                      <Icon
                        type={hasPaymentMethod ? 'on' : 'off'}
                        color={hasPaymentMethod ? 'success' : 'error'}
                        size="xxxl"
                      />
                      <Text size="sm" weight={500}>
                        {hasPaymentMethod ? 'Added' : 'None Added'}
                      </Text>
                      <Button onClick={() => setSetBillingInformationModalIsOpen(true)} shadow>
                        SET PAYMENT METHOD
                      </Button>
                      {values.advertiserId && (
                        <Action onClick={copyAdvertiserBindingCCLink}>
                          <Spacing size="sm" />
                          <Text size="sm">Copy CC binding link for this advertiser</Text>
                        </Action>
                      )}
                    </Flex>
                  </PaymentItem>
                </Flex>
              </FormItem>
            )}
          </>
        )}
      </Form>
      <SetBillingInformationModal
        isOpen={setBillingInformationModalIsOpen}
        agencyId={values.agencyId}
        advertiserId={values.advertiserId}
        onClose={() => setSetBillingInformationModalIsOpen(false)}
        onSuccess={() => {
          setSetBillingInformationModalIsOpen(false);
          refetch();
        }}
      />
      <AddMoreFundsModal
        isOpen={addMoreFundsModalIsOpen}
        agencyId={values.agencyId}
        advertiserId={values.advertiserId}
        onClose={() => setAddMoreFundsModalIsOpen(false)}
        onSuccess={() => {
          setAddMoreFundsModalIsOpen(false);
          refetch();
        }}
      />
      <PrepaymentModal
        isOpen={prepaymentModalIsOpen}
        agencyId={values.agencyId}
        advertiserId={values.advertiserId}
        onClose={() => setPrepaymentModalIsOpen(false)}
        onSuccess={() => {
          setPrepaymentModalIsOpen(false);
          refetch();
        }}
      />
    </PageTemplate>
  );
};

const PaymentItem = styled.div`
  background: #ffffff;
  padding: 3.6rem;
  border-radius: 1.6rem;
  width: 36rem;
`;
